// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TS49H_R3H0GUhT83FA7P {\n  display: flex;\n  align-items: flex-start;\n}\n.TS49H_R3H0GUhT83FA7P .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.TS49H_R3H0GUhT83FA7P .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n  margin-right: 1.5rem;\n}\n.TS49H_R3H0GUhT83FA7P .DUSnKJ_E9qZRQhXLPEVD {\n  width: 400px;\n}\n.TS49H_R3H0GUhT83FA7P .dhvbxFxQJwg7C3yXiEBs {\n  display: inline-block;\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RolesOverview/SearchForm/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAHA;EAMM,4BAAA;AAAN;AANA;EAUM,qBAAA;EACA,gBAAA;EACA,oBAAA;AADN;AAXA;EAiBI,YAAA;AAHJ;AAdA;EAqBI,qBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".form {\n  display: flex;\n  align-items: flex-start;\n\n  :global {\n    .antd-4-21-7-form-item-label {\n      padding-bottom: 0 !important;\n    }\n\n    .antd-4-21-7-form-item {\n      display: inline-block;\n      margin-bottom: 0;\n      margin-right: 1.5rem;\n    }\n  }\n\n  .name {\n    width: 400px;\n  }\n\n  .submit {\n    display: inline-block;\n    margin-top: 32px;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "TS49H_R3H0GUhT83FA7P",
	"name": "DUSnKJ_E9qZRQhXLPEVD",
	"submit": "dhvbxFxQJwg7C3yXiEBs"
};
export default ___CSS_LOADER_EXPORT___;
