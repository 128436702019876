// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kW6ZK02pW27Hhmom92Gx {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.KtNHgh9TjxK3syuty7P4 {\n  display: flex;\n  grid-gap: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/AssignUsers/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;EACA,cAAA;AAAF","sourcesContent":[".innerWrap {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.columnWrap {\n  display: flex;\n  grid-gap: 20px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerWrap": "kW6ZK02pW27Hhmom92Gx",
	"columnWrap": "KtNHgh9TjxK3syuty7P4"
};
export default ___CSS_LOADER_EXPORT___;
