import { useCallback, useEffect } from 'react';

import {
  GroupCreateProjectionInput,
  GroupUpdateProjectionInput
} from '~/apollo/gql-types';

export interface IUseSyncFormValuesProps {
  initialValues: Partial<
    GroupCreateProjectionInput | GroupUpdateProjectionInput
  >;
  optionsLoaded: boolean;
}

export const useSyncFormValues = (
  { initialValues, optionsLoaded }: IUseSyncFormValuesProps,
  reset
) => {
  const setInitialValues = useCallback(
    () => reset(initialValues),
    [initialValues, reset]
  );

  useEffect(() => {
    // Set initial values after all options loaded
    if (optionsLoaded) {
      setInitialValues();
    }
  }, [setInitialValues, optionsLoaded]);
};
