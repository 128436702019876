import React, { useMemo } from 'react';
import cns from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { filter, map, flow, join } from 'lodash/fp';
import { ADMIN_USER } from 'routes';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

type TAccessParam = {
  key: string;
  value: string;
};

export interface IItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  country: string;
  isNew: boolean;
  accessParameters: Array<TAccessParam>;
}

export interface IItemProps {
  item: IItem;
  provided: any;
  style?: object;
  isDragging?: boolean;
}

const Item = ({ provided, item, style, isDragging }: IItemProps) => {
  const { t } = useTranslation();
  const { accessParameters } = item as IItem;
  const { language } = useParams();
  const accessCountries = useMemo(
    () =>
      flow(
        filter(({ key, value }) => key === 'country'),
        map(({ value }) =>
          value === '*' ? t('bo.admin.groupsForm.assignUsers.item.all') : value
        ),
        join(', ')
      )(accessParameters as IItem['accessParameters']),
    [accessParameters, t]
  );

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={{ ...style, ...provided.draggableProps.style }}
      className={cns(cn.item, {
        [cn.isDragging]: isDragging,
        [cn.disabled]: !item.enabled,
        [cn.isNew]: item.isNew
      })}
    >
      <div className={cn.title}>
        <Link
          className={cn.link}
          to={ADMIN_USER.LINK({ language, id: item.id })}
        >
          {item.firstName} {item.lastName}
        </Link>
      </div>
      <div className={cn.infoRow}>
        <span className={cn.label}>{`${t(
          'bo.admin.groupsForm.assignUsers.item.email'
        )}: `}</span>
        <span>{item.email}</span>
      </div>
      <div className={cn.infoRow}>
        <span className={cn.label}>{`${t(
          'bo.admin.groupsForm.assignUsers.item.accessCountry'
        )}: `}</span>
        <span>
          {accessCountries.length > 0
            ? accessCountries
            : t('bo.admin.groupsForm.assignUsers.item.none')}
        </span>
      </div>
    </div>
  );
};

export default Item;
