import { find, flow, getOr, map, join } from 'lodash/fp';

export default (entities, groupName) => {
  const tableHeader =
    'Group Name,First Name,Last Name,Email Address,User Country,Country Access\n';
  const tableRows = flow(
    map(({ firstName, lastName, email, country, accessParameters }) => {
      const countryAccess = flow(
        find({ key: 'country' }),
        getOr('-', ['value'])
      )(accessParameters);

      return `${groupName},${firstName},${lastName},${email},${country},${countryAccess}`;
    }),
    join('\n')
  )(entities);

  return tableHeader + tableRows;
};
