import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RetailUserDto } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';

import { Actions } from '../Actions';

export function useOverviewColumns() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.admin.usersOverview.table.id'),
          dataIndex: 'id',
          render: (id) => <TableCellWrap value={id} qaSelector="id" />
        },
        {
          title: t('bo.admin.usersOverview.table.email'),
          dataIndex: 'email',
          render: (name) => <TableCellWrap value={name} qaSelector="email" />
        },
        {
          title: t('bo.admin.usersOverview.table.name'),
          dataIndex: 'name',
          render: (name) => <TableCellWrap value={name} qaSelector="name" />
        },
        {
          title: t('bo.admin.usersOverview.table.createdOn'),
          dataIndex: 'createdOn',
          align: 'center',
          render: (createdOn) => (
            <TableCellWrap value={createdOn} qaSelector="createdOn" />
          )
        },
        {
          title: t('bo.admin.usersOverview.table.countryAccess'),
          dataIndex: 'countryAccess',
          align: 'center',
          render: (countryAccess) => (
            <TableCellWrap value={countryAccess} qaSelector="countryAccess" />
          )
        },
        {
          title: t('bo.admin.usersOverview.table.loginTime'),
          dataIndex: 'loginTime',
          align: 'center',
          render: (loginTime) => (
            <TableCellWrap value={loginTime} qaSelector="loginTime" />
          )
        },
        {
          title: t('bo.admin.usersOverview.table.enabled'),
          dataIndex: 'enabled',
          align: 'center',
          render: (enabled) =>
            enabled ? (
              <CheckOutlined style={{ color: '#3fb618' }} />
            ) : (
              <MinusOutlined />
            )
        },
        {
          title: t('bo.admin.usersOverview.table.actions'),
          dataIndex: 'id',
          align: 'center',
          render: (id) => <Actions id={id} />
        }
      ] as ColumnsType<Partial<RetailUserDto>>,
    [t]
  );
}
