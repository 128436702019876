import { ReactElement, ReactNode, useCallback } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export interface IUseConfirmModalCallbackParams {
  title: ReactNode;
  content: ReactNode;
  icon?: ReactElement;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const useConfirmModal = () => {
  return useCallback(
    ({
      content,
      icon,
      onConfirm,
      onCancel
    }: IUseConfirmModalCallbackParams) => {
      const config = {
        icon: icon || <ExclamationCircleOutlined />,
        content,
        onOk() {
          onConfirm?.();
          Modal.destroyAll();
        },
        onCancel() {
          onCancel?.();
          Modal.destroyAll();
        }
      };

      return confirm(config);
    },
    []
  );
};
