import { useParams } from 'react-router-dom';

import { useSearchGroupDataQuery } from '~/apollo/gql-types';

import { GROUPS_FORM_BASE_OPTIONS } from '../utils';

export function useGroupData() {
  const { id: groupId } = useParams();

  const { data, loading } = useSearchGroupDataQuery({
    ...GROUPS_FORM_BASE_OPTIONS,
    skip: !groupId,
    variables: {
      groupId
    }
  });

  return {
    data: data?.group,
    isLoading: loading
  };
}
