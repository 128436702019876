import { EditOutlined } from '@ant-design/icons';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { LinkButton } from '@retail/backoffice-urls';
import { useParams } from 'react-router-dom';
import { ADMIN_USER } from 'routes';

import { RetailUserDto } from '~/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import cn from './styles.less';

interface Props {
  id: RetailUserDto['id'];
}

export function Actions({ id }: Props) {
  const { language } = useParams();

  return (
    <PermissionChecker
      domain={DOMAINS.USER_MANAGEMENT}
      allow={PERMISSIONS.QUERY_USER}
    >
      <LinkButton
        type="primary"
        data-qa-selector-name="editUser"
        className={cn.editLinkButton}
        to={ADMIN_USER.LINK({ language, id })}
      >
        {<EditOutlined />}
      </LinkButton>
    </PermissionChecker>
  );
}
