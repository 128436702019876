import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADMIN_GROUP } from 'routes';

import {
  AntOptionProps,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';
import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useOptionToUnselectOther } from '~/hooks/useOptionToUnselectOther';

const FIELD_NAME = 'groupAccess';

interface Props {
  options: Array<AntOptionProps>;
  loading: boolean;
}

export function AssignGroupsAccess({ options, loading }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { language } = useParams();
  const onChange = useOptionToUnselectOther({
    name: FIELD_NAME,
    selectedValues: [
      AUX_USER_ACCESS_PARAM_VALUE.ALL,
      AUX_USER_ACCESS_PARAM_VALUE.NONE
    ]
  });

  return (
    <PermissionChecker
      allow={PERMISSIONS.MUTATION_ASSIGN_USER_GROUP_ACCESS_PARAMS}
      domain={DOMAINS.USER_MANAGEMENT}
    >
      <Card
        title={t('bo.admin.usersForm.assignAccessGroups.title')}
        loading={loading}
      >
        <Row gutter={24}>
          <Col sm={24}>
            <SelectControlled
              allowClear
              required
              mode="multiple"
              onChange={onChange}
              options={options}
              tagRender={(props) =>
                Tag({
                  ...props,
                  url: ADMIN_GROUP.LINK({ language, id: props.value })
                })
              }
              qaSelector="user-groupAccess"
              controllerProps={{
                control,
                name: FIELD_NAME
              }}
            />
          </Col>
        </Row>
      </Card>
    </PermissionChecker>
  );
}
