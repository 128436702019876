import React from 'react';

import Search from './Search';
import ItemList, { IItemListProps } from './ItemList';
import cn from './styles.less';

export type IColumnProps = IItemListProps;

const Column = ({ column, onLoad, loaderRef, qaSelector }: IColumnProps) => (
  <div className={cn.root} data-qa-selector={qaSelector}>
    <h3 className={cn.title}>{column.title}</h3>
    <Search name={`${column.id}UserSearch`} onSubmit={onLoad} />
    <ItemList
      column={column}
      onLoad={onLoad}
      loaderRef={loaderRef}
      qaSelector={`${qaSelector}List`}
    />
  </div>
);

export default React.memo(Column);
