import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AntOptionProps } from '~/components/Form/SelectControlled';

import { ACTIVE, DISABLED } from './values';

export const useStatusOptions = (): Array<AntOptionProps> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: ACTIVE,
        label: t('bo.admin.options.status.active')
      },
      {
        value: DISABLED,
        label: t('bo.admin.options.status.disabled')
      }
    ],
    [t]
  );
};
