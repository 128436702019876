import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function useRequiredRule(
  controllerProps: UseControllerProps,
  required: boolean
): UseControllerProps {
  const { t } = useTranslation();

  if (controllerProps.rules?.required || !required) {
    return controllerProps;
  }

  return {
    ...controllerProps,
    rules: {
      ...controllerProps.rules,
      required: {
        value: true,
        message: t('bo.orderClaims.form.validation.canNotBeEmpty')
      }
    }
  };
}
