import { withPermissions } from '@retail/backoffice-ui/src/Permission';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { LOCALES } from '@retail/i18n/constants';
import { Button, Col, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserProjection } from '~/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { boLocaleOptions } from '~/helpers/fieldOptions/locale';

import { UserForm } from '../UserForm';
import { formFromData } from '../UserForm/utils';

import { useFormCreate } from './useFormCreate';

const DEFAULT_USER = {
  locale: boLocaleOptions.find((x) => x.originValue === LOCALES.EN)?.value
} as UserProjection;

export const UserCreation = withPermissions({
  allow: PERMISSIONS.MUTATION_CREATE_USER,
  domain: DOMAINS.USER_MANAGEMENT
})(() => {
  const { t } = useTranslation();
  const onSubmit = useFormCreate();

  const form = useForm({ defaultValues: formFromData(DEFAULT_USER) });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty }
  } = form;

  return (
    <FormProvider {...form}>
      <UserForm
        title={t('bo.admin.usersForm.create.title')}
        onFinish={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={isSubmitting}
        footer={
          <Row justify="end" gutter={24}>
            <PermissionChecker
              allow={PERMISSIONS.MUTATION_CREATE_USER}
              domain={DOMAINS.USER_MANAGEMENT}
            >
              <Col>
                <Button
                  type="ghost"
                  disabled={!isDirty}
                  data-qa-selector-name="reset"
                  onClick={() => reset()}
                >
                  {t('bo.admin.buttons.reset')}
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  data-qa-selector-name="submit"
                  loading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('bo.admin.buttons.save')}
                </Button>
              </Col>
            </PermissionChecker>
          </Row>
        }
      />
    </FormProvider>
  );
});
