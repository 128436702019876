import countryOptions from '~/helpers/fieldOptions/country/options';
import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';

export const options = [
  {
    value: AUX_USER_ACCESS_PARAM_VALUE.ALL,
    label: 'ALL'
  },
  ...countryOptions,
  {
    value: AUX_USER_ACCESS_PARAM_VALUE.NONE,
    label: 'No country'
  }
];
