import { splitLocales } from '@retail/env';
import { COUNTRY_LANGUAGES } from '@retail/i18n/constants';
import { formatLocale } from '@retail/i18n/utils';
import { mapValues } from 'lodash';
import { compact, find, flow, map } from 'lodash/fp';

import options from './options';

const ENABLED_LOCALES = splitLocales(process.env.ENABLED_LOCALES);
const BO_LOCALES = splitLocales(process.env.BO_LOCALES);

export const localeOptions = options.filter((option) =>
  ENABLED_LOCALES.includes(option.value)
);

export const localeOptionsByCountries = mapValues(
  COUNTRY_LANGUAGES,
  (languages, country) =>
    flow(
      map((language) =>
        find({
          value: formatLocale(language, country)
        })(localeOptions)
      ),
      compact
    )(languages)
);

export const boLocaleOptions = options
  .filter((option) => BO_LOCALES.includes(option.value))
  .map((x) => ({
    ...x,
    originValue: x.value,
    /**
     * @description Server replaces dashes with underscores (why it does this no one knows).
     * To avoid issue with empty field we have to do the same.
     * */
    value: x.value?.replace('-', '_')
  }));
