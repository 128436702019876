import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxControlled } from '~/components/Form/CheckboxControlled';
import { InputControlled } from '~/components/Form/InputControlled';
import {
  NATTERBOX_SIP_ID,
  TNG_USER,
  TWILIO_ENABLED
} from '~/constants/auxUser/properties';

export const VoIP = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Card title={t('bo.admin.usersForm.voIP.title')}>
      <Row gutter={24}>
        <Col sm={12}>
          <InputControlled
            qaSelector="user-tngUser"
            label={t('bo.admin.usersForm.voIP.label.tngUser')}
            controllerProps={{
              name: TNG_USER,
              control
            }}
          />
        </Col>
        <Col sm={12}>
          <InputControlled
            qaSelector="user-natterbox"
            label={t('bo.admin.usersForm.voIP.label.natterbox')}
            controllerProps={{
              name: NATTERBOX_SIP_ID,
              control
            }}
          />
        </Col>
        <Col sm={12}>
          <CheckboxControlled
            qaSelector="user-twilioEnabled"
            label={t('bo.admin.usersForm.voIP.label.twilioEnabled')}
            controllerProps={{
              name: TWILIO_ENABLED,
              control
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};
