import { Input } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

const { Search } = Input;

type onSubmitParams = {
  searchText: string;
  page: number;
};

export interface ISearchProps {
  name: string;
  onSubmit: (params: onSubmitParams) => void;
}

const SearchField = ({ onSubmit, name }: ISearchProps) => {
  const { t } = useTranslation();
  const handleSearch = useCallback(
    (searchText, e) => {
      e.preventDefault();
      onSubmit({ searchText, page: 0 });
    },
    [onSubmit]
  );
  return (
    <Search
      allowClear
      enterButton
      placeholder={t('bo.admin.groupsForm.assignUsers.searchPlaceholder')}
      name={name}
      onSearch={handleSearch}
      className={cn.search}
    />
  );
};

export default SearchField;
