import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AntOptionProps } from '~/components/Form/SelectControlled';

import { ALL, NO_GROUP } from './values';

export const useGroupAccessDefaultOptions = (): Array<AntOptionProps> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: ALL,
        label: t('bo.admin.options.all')
      },
      {
        value: NO_GROUP,
        label: t('bo.admin.options.noGroup')
      }
    ],
    [t]
  );
};
