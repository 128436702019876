import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { concat, flow, map } from 'lodash/fp';
import { useMemo } from 'react';

import { useSearchGroupsOptionsQuery } from '~/apollo/gql-types';
import { AUX_USER_ACCESS_PARAM } from '~/constants/auxUser/accessParams';
import { useGroupAccessDefaultOptions } from '~/helpers/fieldOptions/admin/users/groupAccess/hook';
import { IUserContext } from '~/providers/UserProvider';

import { groupsSearchParams, USER_FORM_BASE_OPTIONS } from '../utils';

import { getUserAccess, hasAccess } from './utils';

export const useGroupsOptions = () => {
  const { accessParameters } = useUserContext<IUserContext>();
  const groupAccessDefaultOptions = useGroupAccessDefaultOptions();

  const { data, loading } = useSearchGroupsOptionsQuery({
    ...USER_FORM_BASE_OPTIONS,
    variables: groupsSearchParams
  });

  const entities = data?.data?.entities;

  const groupOptions = useMemo(() => {
    const access = getUserAccess(AUX_USER_ACCESS_PARAM.GROUP)(accessParameters);

    return map(({ id, name }) => ({
      value: id,
      label: name,
      disabled: !hasAccess(id, access)
    }))(entities);
  }, [accessParameters, entities]);

  const groupAccessOptions = useMemo(() => {
    return flow(
      map(({ id, name }) => ({
        value: id,
        label: name
      })),
      concat(groupAccessDefaultOptions)
    )(entities);
  }, [entities, groupAccessDefaultOptions]);

  return useMemo(
    () => ({
      groupOptions,
      groupAccessOptions,
      loading
    }),
    [groupAccessOptions, groupOptions, loading]
  );
};
