import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { InputControlled } from '~/components/Form/InputControlled';
import {
  AntOptionProps,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { useStatusOptions } from '~/helpers/fieldOptions/admin/users/status/hook';
import countryOptions from '~/helpers/fieldOptions/country/options';
import { boLocaleOptions } from '~/helpers/fieldOptions/locale';
import { validateEmail } from '~/helpers/validation';

interface Props {
  loading: boolean;
}

export const UserDetails = ({ loading }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = id;
  const { control } = useFormContext();
  const statusOptions = useStatusOptions();

  return (
    <Card title={t('bo.admin.usersForm.userDetails.title')} loading={loading}>
      <Row gutter={24}>
        {isEdit && (
          <>
            <Col sm={12}>
              <InputControlled
                disabled
                qaSelector="user-id"
                label={t('bo.admin.usersForm.userDetails.label.uuid')}
                controllerProps={{
                  name: 'id',
                  control
                }}
              />
            </Col>
            <Col sm={12}>
              <InputControlled
                disabled
                qaSelector="user-createdBy"
                label={t('bo.admin.usersForm.userDetails.label.createdBy')}
                controllerProps={{
                  name: 'createdBy',
                  control
                }}
              />
            </Col>
          </>
        )}

        <Col sm={12}>
          <InputControlled
            type="email"
            required
            qaSelector="user-email"
            label={t('bo.admin.usersForm.userDetails.label.email')}
            controllerProps={{
              name: 'email',
              control,
              rules: {
                validate: validateEmail
              }
            }}
          />
        </Col>

        <Col sm={12}>
          <SelectControlled
            allowClear
            required
            showSearch
            options={countryOptions as unknown as Array<AntOptionProps>}
            qaSelector="user-country"
            label={t('bo.admin.usersForm.userDetails.label.country')}
            controllerProps={{
              control,
              name: 'country'
            }}
          />
        </Col>

        <Col sm={12}>
          <InputControlled
            required
            qaSelector="user-firstName"
            label={t('bo.admin.usersForm.userDetails.label.firstName')}
            controllerProps={{
              name: 'firstName',
              control
            }}
          />
        </Col>

        <Col sm={12}>
          <InputControlled
            required
            qaSelector="user-lastName"
            label={t('bo.admin.usersForm.userDetails.label.lastName')}
            controllerProps={{
              name: 'lastName',
              control
            }}
          />
        </Col>

        {isEdit && (
          <>
            <Col sm={12}>
              <InputControlled
                disabled
                qaSelector="user-createdOn"
                label={t('bo.admin.usersForm.userDetails.label.createdOn')}
                controllerProps={{
                  name: 'createdOn',
                  control
                }}
              />
            </Col>

            <Col sm={12}>
              <InputControlled
                disabled
                qaSelector="user-loginTime"
                label={t('bo.admin.usersForm.userDetails.label.loginTime')}
                controllerProps={{
                  name: 'loginTime',
                  control
                }}
              />
            </Col>
          </>
        )}

        <Col sm={12}>
          <SelectControlled
            required
            options={boLocaleOptions}
            qaSelector="user-locale"
            label={t('bo.admin.usersForm.userDetails.label.locale')}
            controllerProps={{
              control,
              name: 'locale'
            }}
          />
        </Col>

        {isEdit && (
          <Col sm={12}>
            <SelectControlled
              allowClear={false}
              options={statusOptions}
              qaSelector="user-status"
              label={t('bo.admin.usersForm.userDetails.label.status')}
              controllerProps={{
                control,
                name: 'enabled'
              }}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};
