import { Card, Col, Row } from 'antd';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputControlled } from '~/components/Form/InputControlled';
import { TextareaControlled } from '~/components/Form/TextareaControlled';
import { CONTROL_GUTTER } from '~/constants/form';

import { RoleProjectionFormModel } from './models';

interface Props {
  control: Control<RoleProjectionFormModel>;
  loading: boolean;
}

export function RoleDetailsCard({ control, loading }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      data-qa-selector-name="roleDetailsSection"
      title={t('bo.admin.roleItem.form.roleDetails')}
      loading={loading}
    >
      <Row gutter={CONTROL_GUTTER}>
        <Col sm={12} xs={24}>
          <InputControlled
            disabled
            qaSelector="name"
            label={t('bo.admin.rolesOverview.table.name')}
            controllerProps={{
              name: 'name',
              control
            }}
          />
        </Col>
        <Col sm={12} xs={24}>
          <InputControlled
            disabled
            qaSelector="id"
            label={t('bo.admin.roleItem.form.uuid')}
            controllerProps={{
              name: 'id',
              control
            }}
          />
        </Col>
      </Row>
      <Row gutter={CONTROL_GUTTER}>
        <Col sm={12} xs={24}>
          <InputControlled
            disabled
            qaSelector="endpoint"
            label={t('bo.admin.rolesOverview.table.endpoint')}
            controllerProps={{
              name: 'endpoint',
              control
            }}
          />
        </Col>
        <Col sm={12} xs={24}>
          <InputControlled
            disabled
            qaSelector="createdOn"
            label={t('bo.admin.rolesOverview.table.createdOn')}
            controllerProps={{
              name: 'createdOn',
              control
            }}
          />
        </Col>
      </Row>
      <Row gutter={CONTROL_GUTTER}>
        <Col sm={12} xs={24}>
          <InputControlled
            qaSelector="page"
            required
            label={t('bo.admin.roleItem.form.featureAndPage')}
            controllerProps={{
              name: 'page',
              control
            }}
          />
        </Col>
        <Col sm={12} xs={24}>
          <TextareaControlled
            qaSelector="description"
            label={t('bo.admin.rolesOverview.table.description')}
            controllerProps={{
              name: 'description',
              control
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
