import { flow, sortBy } from 'lodash/fp';

import prepareFormattedOptions from '../prepareFormattedOptions';

import { toLocalizedCountry } from './toLocalizedCountry';

export default flow(
  prepareFormattedOptions(toLocalizedCountry),
  sortBy('label')
);
