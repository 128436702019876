import { LOCALES } from '@retail/i18n/constants';

export default [
  {
    value: LOCALES.EN,
    label: 'English'
  },
  {
    value: LOCALES.DE,
    label: 'German'
  },
  {
    value: LOCALES.DE_AT,
    label: 'German (Austria)'
  },
  {
    value: LOCALES.ES,
    label: 'Spanish'
  },
  {
    value: LOCALES.FR,
    label: 'French'
  },
  {
    value: LOCALES.FR_BE,
    label: 'French (Belgium)'
  },
  {
    value: LOCALES.IT,
    label: 'Italian'
  },
  {
    value: LOCALES.NL,
    label: 'Dutch (Netherlands)'
  },
  {
    value: LOCALES.NL_BE,
    label: 'Dutch (Belgium)'
  },
  {
    value: LOCALES.PL,
    label: 'Polish'
  },
  {
    value: LOCALES.SV_SE,
    label: 'Swedish'
  }
];
