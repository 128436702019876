// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kQWEmcnjmJSbO274yjB5 {\n  width: 100%;\n  max-width: 920px;\n  margin: 0 auto 200px;\n}\n.n0OF4wOrU0ZIAz4CoB_B {\n  margin-bottom: 15px;\n}\n.q3qT2Dsmp6j7D3se2pgk {\n  font-weight: 400;\n  text-transform: none;\n}\n.q3qT2Dsmp6j7D3se2pgk b {\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,oBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,gBAAA;EACA,oBAAA;AADF;AADA;EAKI,yBAAA;AADJ","sourcesContent":[".pageContainer {\n  width: 100%;\n  max-width: 920px;\n  margin: 0 auto 200px;\n}\n\n.titleContainer {\n  margin-bottom: 15px;\n}\n\n.editTitle {\n  font-weight: 400;\n  text-transform: none;\n\n  b {\n    text-transform: uppercase;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "kQWEmcnjmJSbO274yjB5",
	"titleContainer": "n0OF4wOrU0ZIAz4CoB_B",
	"editTitle": "q3qT2Dsmp6j7D3se2pgk"
};
export default ___CSS_LOADER_EXPORT___;
