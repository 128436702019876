// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ap7A_LlmJtO8S1fTptkq {\n  margin-right: 5px;\n}\n.Ap7A_LlmJtO8S1fTptkq:not(:last-child):after {\n  content: ',';\n}\n.Ap7A_LlmJtO8S1fTptkq:not(:first-child):before {\n  content: '\\a';\n  white-space: pre;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RolesOverview/Groups/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEI;EACE,YAAA;AAAN;AAKI;EACE,aAAA;EACA,gBAAA;AAHN","sourcesContent":[".group {\n  margin-right: 5px;\n\n  &:not(:last-child) {\n    &:after {\n      content: ',';\n    }\n  }\n\n  &:not(:first-child) {\n    &:before {\n      content: '\\a';\n      white-space: pre;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "Ap7A_LlmJtO8S1fTptkq"
};
export default ___CSS_LOADER_EXPORT___;
