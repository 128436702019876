import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import countryOptions from '~/helpers/fieldOptions/country/options';
import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';
import { AntOptionProps } from '~/components/Form/SelectControlled';

export const useCountryAccessOptions = (): Array<AntOptionProps> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: AUX_USER_ACCESS_PARAM_VALUE.ALL,
        label: t('bo.admin.options.all')
      },
      ...countryOptions,
      {
        value: AUX_USER_ACCESS_PARAM_VALUE.NONE,
        label: t('bo.admin.options.noCountry')
      }
    ],
    [t]
  ) as unknown as Array<AntOptionProps>;
};
