import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProjectionModel } from 'types/UserProjectionModel';

import {
  useAssignTestDataAccessParamMutation,
  UserProjection,
  useUpdateUserMutation
} from '~/apollo/gql-types';
import { USER_TEST_DATA_ACCESS } from '~/constants/auxUser/properties';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import {
  formToData,
  getTestDataAccessPerm,
  USER_FORM_BASE_OPTIONS
} from '../UserForm/utils';

export function useFormUpdate(userData: UserProjection) {
  const { t } = useTranslation();
  const [assignTestDataAccessParam] = useAssignTestDataAccessParamMutation(
    USER_FORM_BASE_OPTIONS
  );
  const [updateUserMutation] = useUpdateUserMutation({
    ...USER_FORM_BASE_OPTIONS,
    onCompleted() {
      notification.success({
        message: t('bo.admin.usersForm.notifications.update.success')
      });
    }
  });

  const { isAllowed: hasGroupAccessPerms } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ASSIGN_USER_GROUP_ACCESS_PARAMS,
    domain: DOMAINS.USER_MANAGEMENT
  });

  const { isAllowed: hasTestDataAccessPerms } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ASSIGN_TEST_DATA_ACCESS_PARAM,
    domain: DOMAINS.USER_MANAGEMENT
  });

  const update = useCallback(
    async (params: UserProjectionModel) => {
      const { [USER_TEST_DATA_ACCESS]: userTestDataEnabled, ...formValues } =
        params;
      const data = formToData({}, userData, hasGroupAccessPerms)(formValues);

      if (
        hasTestDataAccessPerms &&
        getTestDataAccessPerm(userData.accessParameters) !== userTestDataEnabled
      ) {
        await assignTestDataAccessParam({
          variables: { enabled: userTestDataEnabled, id: data.id }
        });
      }

      await updateUserMutation({
        variables: data,
        refetchQueries: ['SearchUserData']
      });
    },
    [
      assignTestDataAccessParam,
      hasGroupAccessPerms,
      hasTestDataAccessPerms,
      updateUserMutation,
      userData
    ]
  );

  return update;
}
