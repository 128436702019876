import { useMemo } from 'react';

import { PERMISSIONS } from '~/constants/permissions';

export const usePermissions = (isEdit: boolean) =>
  useMemo(
    () => ({
      pagePermissions: isEdit
        ? PERMISSIONS.QUERY_GROUP
        : PERMISSIONS.MUTATION_CREATE_GROUP,
      submitPermissions: isEdit
        ? PERMISSIONS.MUTATION_UPDATE_GROUP
        : PERMISSIONS.MUTATION_CREATE_GROUP
    }),
    [isEdit]
  );
