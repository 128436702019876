import { assign, flow, get, map, omit } from 'lodash/fp';
import { createFilterPayload, SortType } from '@retail/gql-utils';

import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';
import { formatUser } from '~/helpers/user';
import { formatDateTime } from '~/helpers/date';

import {
  FORM_FIELD_ASSIGN_USERS,
  FORM_FIELD_UNASSIGN_USERS
} from '../constants';

export const GROUPS_FORM_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.GROUP_USER_MANAGEMENT }
  }
};

export const rolesSearchParams = {
  search: createFilterPayload({
    sorts: [
      {
        property: 'name',
        direction: SortType.ASC
      }
    ]
  })
};

export const formToData = (defaultValues) =>
  flow(
    assign(defaultValues),
    omit([
      '__typename',
      'createdOn',
      'createdBy',
      'unassignedUserSearch',
      'assignedUserSearch'
    ])
  )(defaultValues);

export const formFromData = (defaultValues) =>
  flow(
    assign(defaultValues),
    ({ createdOn, createdBy, roles, users, ...fields }) => ({
      ...fields,
      createdBy: formatUser(createdBy),
      createdOn: createdOn && formatDateTime(createdOn),
      roleIds: map(get(['id']), roles),
      [FORM_FIELD_ASSIGN_USERS]: {},
      [FORM_FIELD_UNASSIGN_USERS]: {}
    })
  )(defaultValues);
