// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NCIQjOP2CERuaxVbKGWd {\n  margin-top: 15px;\n  text-align: center;\n  color: #000000;\n  font-size: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/AssignUsers/Column/ItemList/Row/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EAEA,kBAAA;EAEA,cAAA;EAEA,eAAA;AAJF","sourcesContent":["@import '../../../variables.less';\n\n.loading {\n  margin-top: 15px;\n\n  text-align: center;\n\n  color: @base-text-color;\n\n  font-size: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "NCIQjOP2CERuaxVbKGWd"
};
export default ___CSS_LOADER_EXPORT___;
