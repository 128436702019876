import React from 'react';
import { areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import Item from '../Item';

import cn from './styles.less';

export interface IRowProps {
  data: Array<any>;
  index: number;
  style: object;
}

const Row = ({ data: items, index, style }: IRowProps) => {
  const item = items[index];
  const { t } = useTranslation();

  // Rendering an extra item for the loader
  if (!item) {
    return (
      <div className={cn.loading} style={style}>
        {t('bo.admin.loading')}
      </div>
    );
  }

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided) => <Item provided={provided} item={item} style={style} />}
    </Draggable>
  );
};

export default React.memo(Row, areEqual);
