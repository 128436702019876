// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QWXiwEkGkWNXnDcI2oxg {\n  /* this has no visible impact, but is in line with the absolute position spacing.\n  doing this ensures that dropping into an empty list will be in the correct visual position */\n  padding-right: 8px;\n  padding-left: 8px;\n  border-top: 1px solid #dddddd;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/AssignUsers/Column/ItemList/styles.less"],"names":[],"mappings":"AAEA;EADE;8FAC4F;EAI5F,kBAAA;EAEA,iBAAA;EAEA,6BAAA;AAJF","sourcesContent":["@import '../../variables.less';\n\n.taskList {\n  /* this has no visible impact, but is in line with the absolute position spacing.\n  doing this ensures that dropping into an empty list will be in the correct visual position */\n\n  padding-right: 8px;\n\n  padding-left: 8px;\n\n  border-top: 1px solid @border-color;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskList": "QWXiwEkGkWNXnDcI2oxg"
};
export default ___CSS_LOADER_EXPORT___;
