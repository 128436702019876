export enum PERMISSIONS {
  QUERY_SEARCH_ROLES = 'query/searchroles',
  QUERY_ROLE = 'query/role',
  QUERY_GROUP = 'query/group',
  QUERY_SEARCH_GROUPS = 'query/searchgroups',
  QUERY_USER = 'query/user',
  QUERY_SEARCH_USERS = 'query/searchusers',
  MUTATION_UPDATE_ROLE = 'mutation/updaterole',
  MUTATION_UPDATE_GROUP = 'mutation/updategroup',
  MUTATION_CREATE_GROUP = 'mutation/creategroup',
  MUTATION_CREATE_USER = 'mutation/createuser',
  MUTATION_UPDATE_USER = 'mutation/updateuser',
  MUTATION_ASSIGN_USER_GROUP_ACCESS_PARAMS = 'mutation/assignusergroupaccessparams',
  MUTATION_RESET_USER_PASSWORD = 'mutation/resetuserpassword',
  MUTATION_DELETE_AH_USER = 'mutation/userobfuscation',
  MUTATION_ASSIGN_TEST_DATA_ACCESS_PARAM = 'mutation/assigntestdataaccessparam'
}
