import { useParams } from 'react-router-dom';

import { UserProjection, useSearchUserDataQuery } from '~/apollo/gql-types';

import { USER_FORM_BASE_OPTIONS } from '../UserForm/utils';

export function useUserData() {
  const { id: userId } = useParams();
  const { data, loading, error } = useSearchUserDataQuery({
    ...USER_FORM_BASE_OPTIONS,
    skip: !userId,
    variables: { userId }
  });

  return {
    user: data?.user as UserProjection,
    loading,
    error
  };
}
