// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZIzJsZGmgLL74Sp6ROwn {\n  width: 100%;\n  max-width: 920px;\n  margin: 0 auto;\n}\n.hlNTO9jfP26_ogvDkVH3 {\n  display: flex;\n  align-items: center;\n  grid-gap: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RoleItem/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAAF","sourcesContent":[".wrapper {\n  width: 100%;\n  max-width: 920px;\n  margin: 0 auto;\n}\n\n.buttonHolder {\n  display: flex;\n  align-items: center;\n  grid-gap: 1.5rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ZIzJsZGmgLL74Sp6ROwn",
	"buttonHolder": "hlNTO9jfP26_ogvDkVH3"
};
export default ___CSS_LOADER_EXPORT___;
