// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zszgt36w0dUF3hPu2pX9 {\n  width: 100%;\n}\n.IE6Xa_gGGaTma5DIjjsx {\n  height: 24px !important;\n  width: 24px !important;\n  padding: 0 !important;\n  line-height: 22px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UsersOverview/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EACE,uBAAA;EACA,sBAAA;EACA,qBAAA;EACA,4BAAA;AAAF","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n.editLinkButton {\n  height: 24px !important;\n  width: 24px !important;\n  padding: 0 !important;\n  line-height: 22px !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "zszgt36w0dUF3hPu2pX9",
	"editLinkButton": "IE6Xa_gGGaTma5DIjjsx"
};
export default ___CSS_LOADER_EXPORT___;
