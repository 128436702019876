import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { Button, Form, Row } from 'antd';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GroupProjection,
  RoleProjection,
  useUpdateRoleMutation
} from '~/apollo/gql-types';
import { WideSpace } from '~/components/WideSpace';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { useDefaultValues } from '../hooks/useDefaultValues';
import { ROLE_ITEM_BASE_OPTIONS } from '../utils';

import { AssignGroupsCard } from './AssignGroupsCard';
import { RoleProjectionFormModel } from './models';
import { RoleDetailsCard } from './RoleDetailsCard';

interface Props {
  item: RoleProjection;
  groups: GroupProjection[];
  loading: boolean;
}

export function RoleItemForm({ item, groups, loading }: Props) {
  const { t } = useTranslation();
  const defaultValues = useDefaultValues(item);
  const form = useForm<RoleProjectionFormModel>({});
  const { control, reset, handleSubmit } = form;
  const [updateRole, { loading: updateLoading }] = useUpdateRoleMutation({
    ...ROLE_ITEM_BASE_OPTIONS,
    refetchQueries: ['GetRoleInfo']
  });

  function onSubmit({
    id,
    groupIds,
    description,
    page
  }: RoleProjectionFormModel) {
    updateRole({ variables: { id, groupIds, role: { description, page } } });
  }

  useEffect(() => reset(defaultValues), [reset, defaultValues]);

  return (
    <FormProvider {...form}>
      <Form
        data-qa-selector-name="roleItemForm"
        noValidate
        onFinish={handleSubmit(onSubmit)}
      >
        <WideSpace direction="vertical" size="large">
          <RoleDetailsCard control={control} loading={loading} />
          <AssignGroupsCard
            control={control}
            groups={groups}
            loading={loading}
          />
          <PermissionChecker
            allow={PERMISSIONS.MUTATION_UPDATE_ROLE}
            domain={DOMAINS.ROLE_USER_MANAGEMENT}
          >
            <Row data-qa-selector-name="footer" justify="end">
              <Button
                data-qa-selector-name="reset"
                type="link"
                onClick={() => reset()}
              >
                {t('bo.admin.buttons.reset')}
              </Button>
              <Button
                data-qa-selector-name="submit"
                htmlType="submit"
                type="primary"
                danger
                loading={updateLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('bo.admin.buttons.save')}
              </Button>
            </Row>
          </PermissionChecker>
        </WideSpace>
      </Form>
    </FormProvider>
  );
}
