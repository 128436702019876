// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PJxv926_pPxqRU7vkpz4 {\n  margin-bottom: 15px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/AssignUsers/Column/Search/styles.less"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF","sourcesContent":[".search {\n  margin-bottom: 15px !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "PJxv926_pPxqRU7vkpz4"
};
export default ___CSS_LOADER_EXPORT___;
