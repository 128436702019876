import { UserContextProvider } from '@retail/backoffice-ui/src/UserContext';
import { PropsWithChildren } from 'react';

import { RetailAccessParamDto, useUserQuery } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export interface IUserContext {
  isLoaded: boolean;
  permissions: Array<string> | null;
  accessParameters: Array<RetailAccessParamDto> | null;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data } = useUserQuery(REQUEST_BASE_OPTIONS);
  const user = data?.me;
  const isLoaded = user?.id ?? false;

  if (!isLoaded) {
    return null;
  }

  return (
    <UserContextProvider<IUserContext>
      value={{
        isLoaded,
        permissions: user?.effectivePermissions,
        accessParameters: user?.accessParameters
      }}
    >
      {children}
    </UserContextProvider>
  );
};
