import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FORM_FIELD_ASSIGN_USERS,
  FORM_FIELD_UNASSIGN_USERS
} from '~/pages/GroupForm/constants';

import { ASSIGNED, UNASSIGNED } from '../utils';

export const useInitialState = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      columns: {
        assigned: {
          id: ASSIGNED,
          field: FORM_FIELD_ASSIGN_USERS,
          title: t('bo.admin.groupsForm.assignUsers.groupTitle.assigned'),
          items: [],
          page: -1,
          hasNextPage: true,
          loading: false
        },
        unassigned: {
          id: UNASSIGNED,
          field: FORM_FIELD_UNASSIGN_USERS,
          title: t('bo.admin.groupsForm.assignUsers.groupTitle.unassigned'),
          items: [],
          page: -1,
          hasNextPage: true,
          loading: false
        }
      }
    }),
    [t]
  );
};
