import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupProjection } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';

export function useGroupsOverviewColumns() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.admin.groupsOverview.table.id'),
          dataIndex: 'id',
          render: (id) => <TableCellWrap value={id} qaSelector="id" />
        },
        {
          title: t('bo.admin.groupsOverview.table.name'),
          dataIndex: 'name',
          render: (name) => <TableCellWrap value={name} qaSelector="name" />
        },
        {
          title: t('bo.admin.groupsOverview.table.description'),
          dataIndex: 'description',
          render: (description) => (
            <TableCellWrap value={description} qaSelector="description" />
          )
        },
        {
          title: t('bo.admin.groupsOverview.table.createdOn'),
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: t('bo.admin.groupsOverview.table.createdBy'),
          dataIndex: 'createdBy',
          render: ({ firstName, lastName }) => `${firstName} ${lastName}`
        },
        {
          title: t('bo.admin.groupsOverview.table.actions'),
          dataIndex: 'id',
          align: 'center',
          render: (id) => <Actions id={id} />
        }
      ] as ColumnsType<GroupProjection>,
    [t]
  );
}
