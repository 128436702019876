import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectControlled } from '~/components/Form/SelectControlled';
import { useCountryAccessOptions } from '~/helpers/fieldOptions/admin/users/countryAccess/hook';
import {
  ALL,
  NO_GROUP
} from '~/helpers/fieldOptions/admin/users/groupAccess/values';
import { useOptionToUnselectOther } from '~/hooks/useOptionToUnselectOther';

const FIELD_NAME = 'countryAccess';

interface Props {
  loading: boolean;
}

export const AssignCountryAccess = ({ loading }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const countryAccessOptions = useCountryAccessOptions();
  const onChange = useOptionToUnselectOther({
    name: FIELD_NAME,
    selectedValues: [ALL, NO_GROUP]
  });

  return (
    <Card title={t('bo.admin.usersForm.assignCountry.title')} loading={loading}>
      <Row gutter={24}>
        <Col sm={24}>
          <SelectControlled
            allowClear
            required
            mode="multiple"
            options={countryAccessOptions}
            onChange={onChange}
            qaSelector="user-countryAccess"
            controllerProps={{
              control,
              name: FIELD_NAME
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};
