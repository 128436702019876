import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { InputControlled } from '~/components/Form/InputControlled';
import { TextareaControlled } from '~/components/Form/TextareaControlled';

interface Props {
  loading: boolean;
}

export function GroupDetails({ loading }: Props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = id;
  const { control } = useFormContext();

  return (
    <Card title={t('bo.admin.groupsForm.groupDetails.title')} loading={loading}>
      <Row gutter={24}>
        <Col sm={isEdit ? 12 : 24}>
          <InputControlled
            required
            qaSelector="group-name"
            label={t('bo.admin.groupsForm.groupDetails.label.name')}
            controllerProps={{
              name: 'name',
              control
            }}
          />
        </Col>
        {isEdit ? (
          <>
            <Col sm={12}>
              <InputControlled
                disabled
                required
                qaSelector="group-id"
                label={t('bo.admin.groupsForm.groupDetails.label.uuid')}
                controllerProps={{
                  name: 'id',
                  control
                }}
              />
            </Col>
            <Col sm={12}>
              <InputControlled
                disabled
                required
                qaSelector="group-createdOn"
                label={t('bo.admin.groupsForm.groupDetails.label.creationDate')}
                controllerProps={{
                  name: 'createdOn',
                  control
                }}
              />
            </Col>
            <Col sm={12}>
              <InputControlled
                disabled
                required
                qaSelector="group-createdBy"
                label={t('bo.admin.groupsForm.groupDetails.label.createdBy')}
                controllerProps={{
                  name: 'createdBy',
                  control
                }}
              />
            </Col>
          </>
        ) : null}

        <Col sm={24}>
          <TextareaControlled
            required
            label={t('bo.admin.groupsForm.groupDetails.label.description')}
            rows={5}
            controllerProps={{
              name: 'description',
              control
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
