import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  selectedValues: string[];
  name: string;
}

export function useOptionToUnselectOther({ name, selectedValues }: Props) {
  const { setValue } = useFormContext();
  return useCallback(
    (value: string[]) => {
      const lastValue = value[value.length - 1];

      setValue(
        name,
        selectedValues.includes(lastValue)
          ? [lastValue]
          : value.filter((x) => !selectedValues.includes(x))
      );
    },
    [name, setValue, selectedValues]
  );
}
