import { PathRouteProps } from 'react-router-dom';

import { GroupForm } from './pages/GroupForm';
import { GroupsOverview } from './pages/GroupsOverview';
import { RoleItem } from './pages/RoleItem';
import { RolesOverview } from './pages/RolesOverview';
import { UserCreation } from './pages/UserCreation';
import { UserEditing } from './pages/UserEditing';
import { UsersOverview } from './pages/UsersOverview';

export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }) => `/${language}`
};

const ADMIN = {
  ROUTE: `/admin`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/admin`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/admin`
};

export const ADMIN_USERS = {
  ROUTE: '/users',
  ROUTE_RELATIVE: `${ADMIN.ROUTE_RELATIVE}/users`,
  LINK: ({ language }) => `${ADMIN.LINK({ language })}/users`
};

export const ADMIN_USER = {
  ROUTE: ':id',
  ROUTE_RELATIVE: `${ADMIN_USERS.ROUTE_RELATIVE}/:id`,
  LINK: ({ language, id }) => `${ADMIN_USERS.LINK({ language })}/${id}`
};

export const ADMIN_USER_NEW = {
  ROUTE: '/new',
  ROUTE_RELATIVE: `${ADMIN_USERS.ROUTE_RELATIVE}/new`,
  LINK: ({ language }) => `${ADMIN_USERS.LINK({ language })}/new`
};

export const ADMIN_ROLES = {
  ROUTE: '/roles',
  ROUTE_RELATIVE: `${ADMIN.ROUTE_RELATIVE}/roles`,
  LINK: ({ language }) => `${ADMIN.LINK({ language })}/roles`
};

export const ADMIN_ROLE = {
  ROUTE: ':id',
  ROUTE_RELATIVE: `${ADMIN_ROLES.ROUTE_RELATIVE}/:id`,
  LINK: ({ language, id }) => `${ADMIN_ROLES.LINK({ language })}/${id}`
};

export const ADMIN_GROUPS = {
  ROUTE: '/groups',
  ROUTE_RELATIVE: `${ADMIN.ROUTE_RELATIVE}/groups`,
  LINK: ({ language }) => `${ADMIN.LINK({ language })}/groups`
};

export const ADMIN_GROUP = {
  ROUTE: ':id',
  ROUTE_RELATIVE: `${ADMIN_GROUPS.ROUTE_RELATIVE}/:id`,
  LINK: ({ language, id }) => `${ADMIN_GROUPS.LINK({ language })}/${id}`
};

export const ADMIN_GROUP_NEW = {
  ROUTE: '/new',
  ROUTE_RELATIVE: `${ADMIN_GROUPS.ROUTE_RELATIVE}/new`,
  LINK: ({ language }) => `${ADMIN_GROUPS.LINK({ language })}/new`
};

export const ROUTES: PathRouteProps[] = [
  {
    path: ADMIN_USERS.ROUTE_RELATIVE,
    element: <UsersOverview />
  },
  {
    path: ADMIN_USER.ROUTE_RELATIVE,
    element: <UserEditing />
  },
  {
    path: ADMIN_USER_NEW.ROUTE_RELATIVE,
    element: <UserCreation />
  },
  {
    path: ADMIN_ROLES.ROUTE_RELATIVE,
    element: <RolesOverview />
  },
  {
    path: ADMIN_ROLE.ROUTE_RELATIVE,
    element: <RoleItem />
  },
  {
    path: ADMIN_GROUPS.ROUTE_RELATIVE,
    element: <GroupsOverview />
  },
  {
    path: ADMIN_GROUP.ROUTE_RELATIVE,
    element: <GroupForm />
  },
  {
    path: ADMIN_GROUP_NEW.ROUTE_RELATIVE,
    element: <GroupForm />
  }
];
