// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HiKXfv9lH8NuHVDPJnHS {\n  margin: 0;\n  padding: 15px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: #dddddd;\n  background-color: #ffffff;\n  -webkit-box-shadow: none;\n  box-shadow: none;\n}\n.CuDhGJ9YJqzgLgmaLhdM {\n  margin-top: 0;\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  color: #959595;\n  font-size: 16px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupForm/AssignUsers/Column/styles.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,aAAA;EAEA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBAAA;EACA,wBAAA;EACA,gBAAA;AAFF;AAKA;EACE,aAAA;EAEA,mBAAA;EACA,yBAAA;EAEA,cAAA;EAEA,eAAA;EACA,gBAAA;AANF","sourcesContent":["@import '../variables.less';\n\n.root {\n  margin: 0;\n  padding: 15px;\n\n  border-width: 1px;\n  border-style: solid;\n  border-color: @border-color;\n  background-color: @bg-color;\n  -webkit-box-shadow: none;\n  box-shadow: none;\n}\n\n.title {\n  margin-top: 0;\n\n  letter-spacing: 1px;\n  text-transform: uppercase;\n\n  color: @base-title-color;\n\n  font-size: 16px;\n  font-weight: 500;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "HiKXfv9lH8NuHVDPJnHS",
	"title": "CuDhGJ9YJqzgLgmaLhdM"
};
export default ___CSS_LOADER_EXPORT___;
