import { filter, flow, get, includes, map } from 'lodash/fp';

import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';

export const hasAccess = (value, userAccess): boolean => {
  if (includes(AUX_USER_ACCESS_PARAM_VALUE.ALL, userAccess)) {
    return true;
  }

  if (includes(AUX_USER_ACCESS_PARAM_VALUE.NONE, userAccess)) {
    return false;
  }

  return includes(value, userAccess) as unknown as boolean; //Todo: check typing for better solution
};

export const getUserAccess = (key) => {
  return flow(filter({ key }), map(get(['value'])));
};
