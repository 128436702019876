import { useParams } from 'react-router-dom';
import { ADMIN_GROUP } from 'routes';

import { GroupProjection } from '~/apollo/gql-types';
import { Link } from '~/components/Link';

import cn from './styles.less';

interface Props {
  items: GroupProjection[];
}

export function Groups({ items }: Props) {
  const { language } = useParams();

  return (
    <div data-qa-selector-name="groups">
      {items.map(({ id, name }) => (
        <span
          key={id}
          data-qa-selector-name={`groups-item-${id}`}
          className={cn.group}
        >
          <Link to={ADMIN_GROUP.LINK({ language, id })}>{name}</Link>
        </span>
      ))}
    </div>
  );
}
