import {
  createFilter,
  createFilterPayload,
  Filter,
  FilterType,
  SortType
} from '@retail/gql-utils';
import { isBoolean } from 'lodash/fp';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import {
  AUX_USER_ACCESS_PARAM,
  AUX_USER_ACCESS_PARAM_VALUE
} from '~/constants/auxUser/accessParams';
import { DOMAINS } from '~/constants/permissions';
import { registeredFromBackofficeFilter } from '~/constants/common';

import { UsersOverviewModel } from './SearchForm';

export const USERS_OVERVIEW_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.USER_MANAGEMENT }
  }
};

const sorts = [
  {
    property: 'firstName',
    direction: SortType.ASC
  },
  {
    property: 'lastName',
    direction: SortType.ASC
  }
];

export interface UsersOverviewParamsModel
  extends Partial<UsersOverviewModel>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export function getUsersOverviewParams({
  page: rawPage,
  pageSize,
  text,
  countryAccess,
  enabled
}: UsersOverviewParamsModel) {
  const page = Number(rawPage) - 1 || 0;

  //Todo: check for better type
  const filter = createFilter<Filter[]>(null, FilterType.AND, [
    registeredFromBackofficeFilter
  ]);

  if (text) {
    filter.value.push(
      createFilter(null, FilterType.OR, [
        createFilter('firstName', FilterType.LIKE, text),
        createFilter('lastName', FilterType.LIKE, text),
        createFilter('email', FilterType.LIKE, text)
      ])
    );
  }

  if (countryAccess?.length) {
    if (countryAccess.includes(AUX_USER_ACCESS_PARAM_VALUE.NONE)) {
      filter.value.push(
        createFilter(null, FilterType.OR, [
          createFilter(null, FilterType.AND, [
            createFilter(
              'accessParams.key',
              FilterType.EQUAL,
              AUX_USER_ACCESS_PARAM.COUNTRY
            ),
            createFilter('accessParams.value', FilterType.IN, countryAccess)
          ]),
          createFilter('accessParams.key', FilterType.IS_NULL, null)
        ])
      );
    } else {
      filter.value.push(
        createFilter(null, FilterType.AND, [
          createFilter(
            'accessParams.key',
            FilterType.EQUAL,
            AUX_USER_ACCESS_PARAM.COUNTRY
          ),
          createFilter('accessParams.value', FilterType.IN, countryAccess)
        ])
      );
    }
  }

  if (isBoolean(enabled)) {
    filter.value.push(createFilter('enabled', FilterType.EQUAL, enabled));
  }

  return createFilterPayload({ filter, sorts, page, pageSize });
}

export const MIN_SEARCH_LENGTH = 2;
