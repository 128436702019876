import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleProjection } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';
import { Groups } from '../Groups';

export function useRolesOverviewColumns() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.admin.rolesOverview.table.id'),
          dataIndex: 'id',
          render: (id) => <TableCellWrap value={id} qaSelector="id" />
        },
        {
          title: t('bo.admin.rolesOverview.table.name'),
          dataIndex: 'name',
          render: (name) => <TableCellWrap value={name} qaSelector="name" />
        },
        {
          title: t('bo.admin.rolesOverview.table.description'),
          dataIndex: 'description',
          render: (description) => (
            <TableCellWrap value={description} qaSelector="description" />
          )
        },
        {
          title: t('bo.admin.rolesOverview.table.endpoint'),
          dataIndex: 'endpoint',
          render: (endpoint) => (
            <TableCellWrap value={endpoint} qaSelector="endpoint" />
          )
        },
        {
          title: t('bo.admin.rolesOverview.table.createdOn'),
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: t('bo.admin.rolesOverview.table.groups'),
          dataIndex: 'groups',
          render: (groups) => <Groups items={groups} />
        },
        {
          title: t('bo.admin.rolesOverview.table.actions'),
          dataIndex: 'id',
          align: 'center',
          render: (id) => <Actions id={id} />
        }
      ] as ColumnsType<RoleProjection>,
    [t]
  );
}
