import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxControlled } from '~/components/Form/CheckboxControlled';
import { USER_TEST_DATA_ACCESS } from '~/constants/auxUser/properties';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

interface Props {
  loading: boolean;
}

export function AssignTestDataAccess({ loading }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <PermissionChecker
      allow={PERMISSIONS.MUTATION_ASSIGN_TEST_DATA_ACCESS_PARAM}
      domain={DOMAINS.USER_MANAGEMENT}
    >
      <Card
        title={t('bo.admin.usersForm.assignTestData.title')}
        loading={loading}
      >
        <Row gutter={24}>
          <Col sm={24}>
            <CheckboxControlled
              qaSelector={USER_TEST_DATA_ACCESS}
              label={t('bo.admin.usersForm.assignTestData.label')}
              controllerProps={{
                control,
                name: USER_TEST_DATA_ACCESS
              }}
            />
          </Col>
        </Row>
      </Card>
    </PermissionChecker>
  );
}
