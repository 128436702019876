// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OMVZWKaulqwdyD_NB5fh {\n  color: #007eff !important;\n}\n.OMVZWKaulqwdyD_NB5fh:hover,\n.OMVZWKaulqwdyD_NB5fh:focus,\n.OMVZWKaulqwdyD_NB5fh:active {\n  text-decoration: underline !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Link/styles.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;;;EAGE,qCAAA;AACJ","sourcesContent":[".link {\n  color: #007eff !important;\n\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: underline !important;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "OMVZWKaulqwdyD_NB5fh"
};
export default ___CSS_LOADER_EXPORT___;
