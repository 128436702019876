import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { Button, Col, notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useResetUserPasswordMutation } from '~/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useConfirmModal } from '~/hooks/useConfirmModal';

import cn from './styles.less';

export interface IResetPasswordProps {
  email?: string;
}

export const ResetPassword = ({ email }: IResetPasswordProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const openConfirmModal = useConfirmModal();

  const [resetPassword] = useResetUserPasswordMutation({
    onCompleted() {
      notification.success({
        message: t('bo.admin.usersForm.resetPassword.success')
      });
    }
  });

  const handleResetPassword = useCallback(() => {
    openConfirmModal({
      title: t('bo.admin.usersForm.resetPassword.confirm.title'),
      content: t('bo.admin.usersForm.resetPassword.confirm.description', {
        user: email
      }),
      onConfirm: () => resetPassword({ variables: { id } })
    });
  }, [email, id, openConfirmModal, resetPassword, t]);

  return (
    <PermissionChecker
      domain={DOMAINS.USER_MANAGEMENT}
      allow={PERMISSIONS.MUTATION_RESET_USER_PASSWORD}
    >
      <Col className={cn.reset}>
        <Button
          type="ghost"
          data-qa-selector-name="resetPassword"
          onClick={handleResetPassword}
        >
          {t('bo.admin.usersForm.buttons.resetPassword')}
        </Button>
      </Col>
    </PermissionChecker>
  );
};
