import { assign, flow, get, map } from 'lodash/fp';
import { useMemo } from 'react';

import { RoleProjection } from '~/apollo/gql-types';
import { formatDateTime } from '~/helpers/date';

export function useDefaultValues(defaultValues: RoleProjection) {
  return useMemo(
    () =>
      flow(assign(defaultValues), ({ createdOn, groups, ...items }) => ({
        ...items,
        groupIds: map(get(['id']), groups),
        createdOn: formatDateTime(createdOn)
      }))(defaultValues),
    [defaultValues]
  );
}
