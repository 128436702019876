// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DL5HIeDGwhRPoBpkhUaa {\n  margin-bottom: 20px;\n}\n.kXUXrsKxhfB7QAI0KFCe {\n  display: flex;\n}\n.kgukFi4DwbTUXYhIAhP8 {\n  display: flex;\n  align-items: center;\n  background-color: #eee;\n  border-top: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  border-left: 1px solid #ccc;\n  padding: 0 10px;\n}\n.OKz1Q7FLIDm2Lx3eYlVC {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Form/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,0BAAA;EACA,6BAAA;EACA,2BAAA;EACA,eAAA;AADF;AAIA;EACE,kBAAA;AAFF","sourcesContent":[".offset {\n  margin-bottom: 20px;\n}\n\n.inputWrapper {\n  display: flex;\n}\n\n.addonBefore {\n  display: flex;\n  align-items: center;\n  background-color: #eee;\n  border-top: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  border-left: 1px solid #ccc;\n  padding: 0 10px;\n}\n\n.label {\n  margin-bottom: 8px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "DL5HIeDGwhRPoBpkhUaa",
	"inputWrapper": "kXUXrsKxhfB7QAI0KFCe",
	"addonBefore": "kgukFi4DwbTUXYhIAhP8",
	"label": "OKz1Q7FLIDm2Lx3eYlVC"
};
export default ___CSS_LOADER_EXPORT___;
