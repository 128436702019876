import { Tag as AntdTag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from 'react';

import { Link } from '../../Link';

import cn from './styles.less';

function onMouseDown(e: React.MouseEvent<HTMLSpanElement>) {
  e.preventDefault();
  e.stopPropagation();
}

export interface TagProps extends CustomTagProps {
  url: string;
}

export function Tag(props: TagProps) {
  const { label, url, closable, onClose } = props;

  return (
    <AntdTag
      onMouseDown={onMouseDown}
      closable={closable}
      onClose={onClose}
      className={cn.tag}
    >
      <Link to={url}>{label}</Link>
    </AntdTag>
  );
}
