import { Card } from 'antd';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADMIN_GROUP } from 'routes';

import { GroupProjection } from '~/apollo/gql-types';
import {
  AntOptionProps,
  DEFAULT_SELECT_ALL_OPTION,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';

import { RoleProjectionFormModel } from '../models';

const FIELD_NAME = 'groupIds';

interface Props {
  control: Control<RoleProjectionFormModel>;
  groups: GroupProjection[];
  loading: boolean;
}

export function AssignGroupsCard({ control, groups, loading }: Props) {
  const { t } = useTranslation();
  const { language } = useParams();

  const options = useMemo(
    () =>
      groups?.map(
        ({ name: label, id: value }): AntOptionProps => ({
          label,
          value
        })
      ) ?? [],
    [groups]
  );

  return (
    <Card
      data-qa-selector-name="assignGroupsSection"
      title={t('bo.admin.roleItem.form.assignGroups.title')}
      loading={loading}
    >
      <SelectControlled
        qaSelector="groupIds"
        options={options}
        required
        allowClear
        allowSelectAll
        selectAllOptionProps={{
          ...DEFAULT_SELECT_ALL_OPTION,
          label: t('bo.admin.select.selectAll')
        }}
        mode="multiple"
        controllerProps={{
          name: FIELD_NAME,
          control
        }}
        tagRender={(props) =>
          Tag({
            ...props,
            url: ADMIN_GROUP.LINK({ language, id: props.value })
          })
        }
      />
    </Card>
  );
}
