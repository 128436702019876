import React, { LegacyRef } from 'react';
import { FixedSizeList } from 'react-window';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteLoader from 'react-window-infinite-loader';
import { noop } from 'lodash/fp';

import Row from './Row';
import Item from './Item';
import cn from './styles.less';

export type TColumn = {
  id: string;
  title: string;
  items: Array<any>;
  loading: boolean;
  hasNextPage: boolean;
};

export interface IItemListProps {
  onLoad: (TSearchParams) => Promise<void>;
  loaderRef: object;
  column: TColumn;
  qaSelector: string;
}

const ItemList = ({
  column,
  onLoad,
  loaderRef,
  qaSelector
}: IItemListProps) => {
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = column.loading ? noop : onLoad;
  const isItemLoaded = (idx) =>
    !column.hasNextPage || idx < column.items.length;
  const innerElementType = React.forwardRef((props, ref) => (
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      data-qa-selector={qaSelector}
      {...props}
    />
  ));

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
        />
      )}
    >
      {(provided) => {
        // add an extra space for loader
        const itemCount = column.hasNextPage
          ? column.items.length + 1
          : column.items.length;

        return (
          <InfiniteLoader
            ref={loaderRef}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={500}
                itemCount={itemCount}
                itemSize={90}
                width={400}
                outerRef={provided.innerRef}
                itemData={column.items}
                onItemsRendered={onItemsRendered}
                className={cn.taskList}
                ref={ref}
                innerElementType={innerElementType}
              >
                {Row}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        );
      }}
    </Droppable>
  );
};

export default React.memo(ItemList);
