import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputControlled } from '~/components/Form/InputControlled';

import cn from './style.less';

export interface GroupsOverviewModel {
  name?: string;
}

interface Props extends GroupsOverviewModel {
  onSubmit: Dispatch<GroupsOverviewModel>;
  isLoading: boolean;
}

export const SearchForm = ({ name, isLoading, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<GroupsOverviewModel>({
    defaultValues: {
      name
    }
  });

  return (
    <Card title={t('bo.admin.groupsOverview.searchForm.title')}>
      <Form
        disabled={isLoading}
        onFinish={handleSubmit(onSubmit)}
        data-qa-selector-name="searchForm"
        className={cn.form}
      >
        <InputControlled
          type="string"
          allowClear
          labelCol={{ span: 24 }}
          className={cn.name}
          qaSelector="searchForm-name"
          disabled={isLoading}
          label={t('bo.admin.groupsOverview.searchForm.search.label')}
          placeholder={t(
            'bo.admin.groupsOverview.searchForm.search.placeholder'
          )}
          controllerProps={{
            name: 'name',
            control
          }}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={isLoading}
          data-qa-selector-name="searchForm-searchButton"
          className={cn.submit}
          icon={<SearchOutlined />}
        />
      </Form>
    </Card>
  );
};
