import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Json: any;
  LocalDate: any;
  LocalDateTime: any;
  Long: any;
  Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar: any;
  Map_RetailNotificationEntityType_IntegerScalar: any;
  Map_String_List_WheelScalar: any;
  Map_String_Map_String_OptionProjectionScalar: any;
  Map_String_ObjectScalar: any;
  Map_String_OpeningHoursDTOScalar: any;
  Map_String_PriceModifierPayloadScalar: any;
  Map_String_RetailOutboundPaymentOptionProjectionScalar: any;
  Map_String_StringScalar: any;
  Map_UUID_LongScalar: any;
  Map_UUID_URLScalar: any;
  Map_WheelPlacementType_RimDetailsProjectionScalar: any;
  Map_WheelPlacementType_RimDetailsScalar: any;
  Map_WheelPlacementType_TireDetailsProjectionScalar: any;
  Map_WheelPlacementType_TireDetailsScalar: any;
  ObjectScalar: any;
  UNREPRESENTABLE: any;
  UUID: any;
};

export type AbTestingExperimentInputInput = {
  condition?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: InputMaybe<Scalars['String']>;
  expireAt?: InputMaybe<Scalars['String']>;
  gaExperimentId?: InputMaybe<Scalars['String']>;
  multiStep: Scalars['Boolean'];
  name: Scalars['String'];
  nextStepVariants?: InputMaybe<Array<InputMaybe<RetailAbTestingNextStepVariantProjectionInput>>>;
  startAt: Scalars['String'];
  trafficPercents: Scalars['Int'];
  variants?: InputMaybe<Array<InputMaybe<RetailAbTestingVariantProjectionInput>>>;
  viewNames: Array<InputMaybe<Scalars['String']>>;
};

export type AccountDataDto = {
  __typename?: 'AccountDataDTO';
  accountDetails?: Maybe<AccountDetailsDto>;
  createdBy?: Maybe<Scalars['Long']>;
  createdOn?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type AccountDetailsDto = {
  __typename?: 'AccountDetailsDTO';
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  blz?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type AccountDetailsDtoInput = {
  bankName?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  blz?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type AccountingDatePendingTaskProjection = {
  __typename?: 'AccountingDatePendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type AdDetailsProjection = {
  __typename?: 'AdDetailsProjection';
  ad?: Maybe<AdProjection>;
  damages?: Maybe<DamagesProjection>;
  estimatedCosts?: Maybe<Array<Maybe<RetailPricingCostDto>>>;
  retailAd?: Maybe<RetailAdProjection>;
  serviceBookImages?: Maybe<Array<Maybe<ImageProjection>>>;
  technicalInspectionCertificateImages?: Maybe<Array<Maybe<ImageProjection>>>;
  vehicleRegistration?: Maybe<ImageProjection>;
};

export type AdImageProjectionInput = {
  composites?: InputMaybe<Array<InputMaybe<CompositeImageProjectionInput>>>;
};

export type AdItemProjection = {
  __typename?: 'AdItemProjection';
  ad?: Maybe<AdProjection>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  retailAd?: Maybe<RetailAdProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type AdManagementDictionaryProjection = {
  __typename?: 'AdManagementDictionaryProjection';
  dealers?: Maybe<Array<Maybe<DealerDto>>>;
  retailPublishingBlockerReasons?: Maybe<Array<Maybe<PublishingBlockerReasonDto>>>;
};

export type AdProjection = {
  __typename?: 'AdProjection';
  adType?: Maybe<AdType>;
  autoimported: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  composites?: Maybe<Array<Maybe<CompositeImageProjection>>>;
  contact?: Maybe<SellerContactDto>;
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentInventoryItems?: Maybe<DocumentInventoryItemConnection_DocumentInventoryItem>;
  exclusive?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  highlights?: Maybe<Array<Maybe<HighlightDto>>>;
  id?: Maybe<Scalars['UUID']>;
  inShowroom?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  location?: Maybe<SellerAddressDto>;
  longitude?: Maybe<Scalars['Float']>;
  manualImportDate?: Maybe<Scalars['String']>;
  pathTitle?: Maybe<Scalars['String']>;
  pdfs?: Maybe<Array<Maybe<PdfDto>>>;
  price?: Maybe<Price>;
  priceType?: Maybe<PriceType>;
  published?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  seller?: Maybe<SellerDto>;
  sellerId?: Maybe<Scalars['UUID']>;
  source?: Maybe<Source>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  unpublishedAt?: Maybe<Scalars['String']>;
  unpublishedReason?: Maybe<UnpublishedReason>;
  updated?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<VehicleProjection>;
  version?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<AdVideoDto>>>;
  zipcode?: Maybe<Scalars['String']>;
};

export enum AdType {
  Merchant = 'MERCHANT',
  PrivateListing = 'PRIVATE_LISTING',
  PrivateSales = 'PRIVATE_SALES',
  Retail = 'RETAIL'
}

export type AdUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  inShowroom?: InputMaybe<Scalars['Boolean']>;
  pdfs?: InputMaybe<Array<InputMaybe<PdfDtoInput>>>;
  price?: InputMaybe<PriceInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<InputMaybe<AdVideoDtoInput>>>;
};

export type AdVehicleProjectionInput = {
  bodyType?: InputMaybe<Scalars['Int']>;
  builtYear?: InputMaybe<Scalars['Int']>;
  carAttrAccidentBool?: InputMaybe<Scalars['Boolean']>;
  carPreOwnerCount?: InputMaybe<Scalars['Int']>;
  carType?: InputMaybe<CarType>;
  categorizedCustomEquipment?: InputMaybe<Array<InputMaybe<CategorizedCustomEquipmentInput>>>;
  co2Value?: InputMaybe<Scalars['Int']>;
  emissionSticker?: InputMaybe<Scalars['String']>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  fuelConsumption?: InputMaybe<FuelConsumptionDtoInput>;
  fuelType?: InputMaybe<Scalars['Int']>;
  gearType?: InputMaybe<Scalars['Int']>;
  handoverKeyCount?: InputMaybe<Scalars['Int']>;
  huCertificate?: InputMaybe<Scalars['Int']>;
  inspectionExpiryDate?: InputMaybe<Scalars['String']>;
  lastServiceOn?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<MileageDtoInput>;
  originalEngine?: InputMaybe<Scalars['Boolean']>;
  subType?: InputMaybe<Scalars['String']>;
  subTypeExtra?: InputMaybe<Scalars['String']>;
  taxRating?: InputMaybe<Scalars['Int']>;
  tuv?: InputMaybe<TuvdtoInput>;
  wasInCommercialUse?: InputMaybe<Scalars['Boolean']>;
  wheelSets?: InputMaybe<WheelSetsDtoInput>;
};

export type AdVideoDto = {
  __typename?: 'AdVideoDTO';
  adId?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type AdVideoDtoInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  created?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  updated?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type AmountInfo = {
  __typename?: 'AmountInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type AmountInfoInput = {
  amount?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
};

export type ApplyPriceUpdateProjectionInput = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
  priceUpdateType?: InputMaybe<PriceUpdateType>;
};

export enum ApprovalLevel {
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3'
}

export type AttachmentUploadPresignedUrlProjection = {
  __typename?: 'AttachmentUploadPresignedUrlProjection';
  httpMethod?: Maybe<Scalars['String']>;
  internalFilename?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
};

export type AuxUserDto = {
  __typename?: 'AuxUserDTO';
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  doiSentOn?: Maybe<Scalars['String']>;
  doiVerifiedOn?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<RoleDto>>>;
  salutation?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  userRegistrationSource?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type AwsImageUploadRequestProjectionInput = {
  contentType?: InputMaybe<Scalars['String']>;
};

export type AwsImageUploadResponseProjection = {
  __typename?: 'AwsImageUploadResponseProjection';
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map_String_StringScalar']>;
  host?: Maybe<Scalars['String']>;
  id?: Maybe<ImageIdDto>;
};

export type AwsS3AdVideoPresignedUrlDto = {
  __typename?: 'AwsS3AdVideoPresignedUrlDTO';
  adId?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  httpMethod?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AwsS3AdVideoPresignedUrlRequestDtoInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  fileName?: InputMaybe<Scalars['String']>;
  httpMethod?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AwsS3PresignedUrlDto = {
  __typename?: 'AwsS3PresignedUrlDTO';
  fileName?: Maybe<Scalars['String']>;
  httpMethod?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
};

export type AwsS3PresignedUrlRequestDtoInput = {
  fileName?: InputMaybe<Scalars['String']>;
  httpMethod?: InputMaybe<Scalars['String']>;
};

export type AwsUploadCredentialsDto = {
  __typename?: 'AwsUploadCredentialsDTO';
  cdn?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map_String_StringScalar']>;
  host?: Maybe<Scalars['String']>;
};

export type AwsUploadCredentialsProjection = {
  __typename?: 'AwsUploadCredentialsProjection';
  awsUploadCredentialsDTOS?: Maybe<Array<Maybe<AwsUploadCredentialsDto>>>;
};

export type AwsUploadCredentialsRequestDtoInput = {
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  fileExt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type AwsUploadCredentialsRequestProjectionInput = {
  uploadCredentialsRequestDTOs?: InputMaybe<Array<InputMaybe<AwsUploadCredentialsRequestDtoInput>>>;
};

export type BankAccountDetailsProjection = {
  __typename?: 'BankAccountDetailsProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type BankAccountDetailsProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  bic?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export type BankAccountDetailsWithDocumentsProjection = {
  __typename?: 'BankAccountDetailsWithDocumentsProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type BankDetailsPendingTaskProjection = {
  __typename?: 'BankDetailsPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  requestedAmount?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput = {
  entities?: InputMaybe<Array<InputMaybe<AwsS3AdVideoPresignedUrlRequestDtoInput>>>;
};

export type BatchProjection_AwsS3PresignedUrlRequestDtoInput = {
  entities?: InputMaybe<Array<InputMaybe<AwsS3PresignedUrlRequestDtoInput>>>;
};

export type BatchProjection_CarRegistrationActionImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<CarRegistrationActionImportCreateProjectionInput>>>;
};

export type BatchProjection_ClaimCostDistributionProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<ClaimCostDistributionProjectionInput>>>;
};

export type BatchProjection_HighlightProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<HighlightProjectionInput>>>;
};

export type BatchProjection_InventorySourcingImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<InventorySourcingImportCreateProjectionInput>>>;
};

export type BatchProjection_PriceUpdateItemImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<PriceUpdateItemImportCreateProjectionInput>>>;
};

export type BatchProjection_ProfitAndLossImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<ProfitAndLossImportCreateProjectionInput>>>;
};

export type BatchProjection_RetailAdDeliveryOptionProjection = {
  __typename?: 'BatchProjection_RetailAdDeliveryOptionProjection';
  entities?: Maybe<Array<Maybe<RetailAdDeliveryOptionProjection>>>;
};

export type BatchProjection_RetailClaimCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailClaimCreateProjectionInput>>>;
};

export type BatchProjection_RetailDocumentInventoryImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailDocumentInventoryImportCreateProjectionInput>>>;
};

export type BatchProjection_RetailDocumentPackageUpdateImportProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailDocumentPackageUpdateImportProjectionInput>>>;
};

export type BatchProjection_RetailPaymentImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailPaymentImportCreateProjectionInput>>>;
};

export type BrakeConditionProjection = {
  __typename?: 'BrakeConditionProjection';
  condition?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type BranchDto = {
  __typename?: 'BranchDTO';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryETADate?: Maybe<Scalars['LocalDate']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BranchDeliveryOptionRequestDtoInput = {
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  direction?: InputMaybe<Direction>;
  distance?: InputMaybe<Scalars['Int']>;
  excludedBranches?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  fallbackDistances?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pointToCalculateDistanceFrom?: InputMaybe<LatLngInput>;
  pointToSearchBranches?: InputMaybe<LatLngInput>;
  properties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BranchEntryCheckCarSupportedProjection = {
  __typename?: 'BranchEntryCheckCarSupportedProjection';
  isSupported?: Maybe<Scalars['Boolean']>;
};

export type BranchEntryCheckComplementaryProjection = {
  __typename?: 'BranchEntryCheckComplementaryProjection';
  adminUser?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['LocalDate']>;
  deliveryLocation?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type BranchEntryCheckDetailsProjection = {
  __typename?: 'BranchEntryCheckDetailsProjection';
  adId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  data?: Maybe<BranchEntrySubmissionDto>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Scalars['Map_UUID_URLScalar']>;
  location?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  mostRecent: Scalars['Boolean'];
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  plannedHandoverDate?: Maybe<Scalars['LocalDate']>;
  refId?: Maybe<Scalars['UUID']>;
  refNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BranchEntryCheckStatus>;
  statusSetBy?: Maybe<Scalars['UUID']>;
  statusSetOn?: Maybe<Scalars['LocalDateTime']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type BranchEntryCheckSearchItemDto = {
  __typename?: 'BranchEntryCheckSearchItemDTO';
  adId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  entryCheckDate?: Maybe<Scalars['LocalDate']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  plannedHandoverDate?: Maybe<Scalars['LocalDate']>;
  refId?: Maybe<Scalars['UUID']>;
  refNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BranchEntryCheckStatus>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export enum BranchEntryCheckStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type BranchEntryCheckSubmissionResultProjection = {
  __typename?: 'BranchEntryCheckSubmissionResultProjection';
  id?: Maybe<Scalars['UUID']>;
};

export type BranchEntrySubmissionDto = {
  __typename?: 'BranchEntrySubmissionDTO';
  comment?: Maybe<Scalars['String']>;
  commentPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  damages?: Maybe<Array<Maybe<DamageDto>>>;
  exterior?: Maybe<ExteriorDto>;
  general?: Maybe<GeneralDto>;
  hasDamages?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<InteriorDto>;
};

export type BranchEntrySubmissionDtoInput = {
  comment?: InputMaybe<Scalars['String']>;
  commentPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  damages?: InputMaybe<Array<InputMaybe<DamageDtoInput>>>;
  exterior?: InputMaybe<ExteriorDtoInput>;
  general?: InputMaybe<GeneralDtoInput>;
  hasDamages?: InputMaybe<Scalars['Boolean']>;
  interior?: InputMaybe<InteriorDtoInput>;
};

export type BranchForSelectionSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  distance?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BranchProjection = {
  __typename?: 'BranchProjection';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  branchHead?: Maybe<Scalars['String']>;
  capacityPlanningEnabled: Scalars['Boolean'];
  carShippingProvider?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  defaultDocumentStorageId?: Maybe<Scalars['Int']>;
  directionsHtml?: Maybe<Scalars['String']>;
  documentShippingProvider?: Maybe<Scalars['Int']>;
  gmapsLarge?: Maybe<Scalars['String']>;
  gmapsLink?: Maybe<Scalars['String']>;
  handoverAppointmentsEnabled: Scalars['Boolean'];
  handoverProtocolEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  navigationInfo?: Maybe<Scalars['String']>;
  openingHoursMerchantText?: Maybe<Scalars['String']>;
  openingHoursText?: Maybe<Scalars['String']>;
  overbookingEnabled: Scalars['Boolean'];
  partner?: Maybe<PartnerDto>;
  phone?: Maybe<Scalars['String']>;
  premiumHandlingEnabledOn?: Maybe<Scalars['String']>;
  priorityBookingEnabled: Scalars['Boolean'];
  privateSaleEnabled?: Maybe<Scalars['Boolean']>;
  refurbishmentPartnerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  twilioRetriesEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Int']>;
  videoUploadEnabled: Scalars['Boolean'];
  zipcode?: Maybe<Scalars['String']>;
};

export type BranchSearchingResult_DocumentBranchProjection = {
  __typename?: 'BranchSearchingResult_DocumentBranchProjection';
  hubId?: Maybe<Scalars['Int']>;
  nodes: Array<Maybe<DocumentBranchProjection>>;
  totalCount: Scalars['Long'];
};

export type CamundaSearchProjection2Input = {
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type CancelRequestDto = {
  __typename?: 'CancelRequestDTO';
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
};

export type CancellationRequestedTaskProjection = {
  __typename?: 'CancellationRequestedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  cancellationRequestedDate?: Maybe<Scalars['String']>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CancellationTaskProjection = {
  __typename?: 'CancellationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CarDtoInput = {
  gps?: InputMaybe<GpsDtoInput>;
  hasFirstAidKit?: InputMaybe<Scalars['Boolean']>;
  hasFloorMat?: InputMaybe<Scalars['Boolean']>;
  hasKey?: InputMaybe<Scalars['Boolean']>;
  hasSecondKey?: InputMaybe<Scalars['Boolean']>;
  hasSecurityTriangle?: InputMaybe<Scalars['Boolean']>;
  hasSpareTire?: InputMaybe<Scalars['Boolean']>;
  hasTireFit?: InputMaybe<Scalars['Boolean']>;
  hasTrunkCover?: InputMaybe<Scalars['Boolean']>;
  hasWarningVest?: InputMaybe<Scalars['Boolean']>;
  millage?: InputMaybe<Scalars['Int']>;
  primaryWheels?: InputMaybe<WheelDtoInput>;
  radio?: InputMaybe<RadioDtoInput>;
  secondaryWheels?: InputMaybe<WheelDtoInput>;
};

export type CarRegistrationActionImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type CarRegistrationActionProjection = {
  __typename?: 'CarRegistrationActionProjection';
  actionCanonical?: Maybe<Scalars['String']>;
  actionHumanReadable?: Maybe<Scalars['String']>;
  applied: Scalars['Boolean'];
  appliedOn?: Maybe<Scalars['LocalDate']>;
  canBeApplied: Scalars['Boolean'];
  eta?: Maybe<Scalars['LocalDate']>;
  reasonNotApplicable?: Maybe<Scalars['String']>;
};

export type CarRegistrationItemProjection = {
  __typename?: 'CarRegistrationItemProjection';
  carRegistration?: Maybe<RetailCarRegistrationDto>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type CarRegistrationStatusDictionaryDto = {
  __typename?: 'CarRegistrationStatusDictionaryDTO';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum CarRegistrationType {
  AhDigitalCarRegistration = 'AH_DIGITAL_CAR_REGISTRATION',
  AhDigitalCarRegistrationWithCustomPlate = 'AH_DIGITAL_CAR_REGISTRATION_WITH_CUSTOM_PLATE',
  AhRegistration = 'AH_REGISTRATION',
  AhRegistrationTemporary = 'AH_REGISTRATION_TEMPORARY',
  AhRegistrationWithLicensePlate = 'AH_REGISTRATION_WITH_LICENSE_PLATE',
  SelfRegistration = 'SELF_REGISTRATION'
}

export type CarReturnHandoverAppointmentTaskProjection = {
  __typename?: 'CarReturnHandoverAppointmentTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  carReturnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CarReturnedTaskProjection = {
  __typename?: 'CarReturnedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  carReturnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export enum CarType {
  Ctte = 'CTTE',
  Vp = 'VP'
}

export type CareCallTaskProjection = {
  __typename?: 'CareCallTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CashOutRefundApprovalPendingTaskProjection = {
  __typename?: 'CashOutRefundApprovalPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CategorizedCustomEquipment = {
  __typename?: 'CategorizedCustomEquipment';
  categoryId?: Maybe<Scalars['UUID']>;
  customEquipments?: Maybe<Array<Maybe<CustomEquipment>>>;
};

export type CategorizedCustomEquipmentInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  customEquipments?: InputMaybe<Array<InputMaybe<CustomEquipmentInput>>>;
};

export type CategorizedEquipmentsDto = {
  __typename?: 'CategorizedEquipmentsDTO';
  categoryId?: Maybe<Scalars['UUID']>;
  categoryName?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<EquipmentItemDto>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translationKey?: Maybe<Scalars['String']>;
};

export type ChangeDocumentPackageStatusInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ChangeDocumentPackageStatusProjection = {
  __typename?: 'ChangeDocumentPackageStatusProjection';
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
};

export type ChangeDocumentPackageStatusResult = ChangeDocumentPackageStatusProjection | DocumentPackageAlreadyCanceledProblem | DocumentPackageAlreadyDeliveredProblem | DocumentPackageEmptyDeliveredOnProblem | DocumentPackageEmptyPickedUpOnProblem | DocumentPackageSameStatusProblem | DocumentPackagesGeneralProblem;

export type ChangeInput = {
  id?: InputMaybe<Scalars['UUID']>;
  value: Scalars['Boolean'];
};

export type ChangelogProjection = {
  __typename?: 'ChangelogProjection';
  action?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  newValue?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['UUID']>;
  objectType?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentType?: Maybe<Scalars['String']>;
};

export type CheckoutPaymentDetailsProjection = {
  __typename?: 'CheckoutPaymentDetailsProjection';
  directPaymentMethod?: Maybe<Scalars['String']>;
  directPaymentOption?: Maybe<Scalars['String']>;
};

export type CheckoutPaymentDetailsUpdateProjectionInput = {
  directPaymentOption?: InputMaybe<Scalars['String']>;
};

export enum CheckoutSource {
  Autohero = 'AUTOHERO',
  Backoffice = 'BACKOFFICE'
}

export type ClaimChangelogResponseProjection = {
  __typename?: 'ClaimChangelogResponseProjection';
  claimId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<AuxUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeNumber?: Maybe<Scalars['String']>;
};

export type ClaimCostCompensationProjection = {
  __typename?: 'ClaimCostCompensationProjection';
  accountId?: Maybe<Scalars['UUID']>;
  approveStatus?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<UserProjection>;
  approvedOn?: Maybe<Scalars['String']>;
  claimCostNumber?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['UUID']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  retailOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
};

export type ClaimCostCompensationUpdateProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  approvalStatus?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  requestBankDetails?: InputMaybe<Scalars['Boolean']>;
};

export type ClaimCostDistributionProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  subClaimId?: InputMaybe<Scalars['UUID']>;
};

export type ClaimCostWrapperProjection = {
  __typename?: 'ClaimCostWrapperProjection';
  approvalLevel?: Maybe<Scalars['String']>;
  approvalLevelSetBy?: Maybe<UserProjection>;
  approvalLevelSetOn?: Maybe<Scalars['String']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  costType?: Maybe<Scalars['String']>;
  costTypeCategory?: Maybe<Scalars['String']>;
  customerDecision?: Maybe<Scalars['String']>;
  customerDecisionSetBy?: Maybe<UserProjection>;
  customerDecisionSetOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  level?: Maybe<ApprovalLevel>;
  number?: Maybe<Scalars['String']>;
};

export type ClaimDetailsProjection = {
  __typename?: 'ClaimDetailsProjection';
  claimCostId?: Maybe<Scalars['UUID']>;
};

export type ClaimDetailsProjectionInput = {
  claimCostId?: InputMaybe<Scalars['UUID']>;
};

export type ClaimPartialRefundPendingTaskProjection = {
  __typename?: 'ClaimPartialRefundPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClaimPartialRefundProjection = {
  __typename?: 'ClaimPartialRefundProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  accountType?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type ClaimProofPendingTaskProjection = {
  __typename?: 'ClaimProofPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  claimGroup?: Maybe<RetailClaimGroupProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClaimProofSubmittedTaskProjection = {
  __typename?: 'ClaimProofSubmittedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  claimGroup?: Maybe<RetailClaimGroupProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClosedReasonProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type ColorDto = {
  __typename?: 'ColorDTO';
  metallic?: Maybe<Scalars['Boolean']>;
  outside?: Maybe<Scalars['Int']>;
};

export type CommentTagProjection = {
  __typename?: 'CommentTagProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CompileOrderEmailTemplateProjectionInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  placeholders?: InputMaybe<Array<InputMaybe<OrderHistoryEmailPlaceholderInput>>>;
  templateId?: InputMaybe<Scalars['UUID']>;
};

export type CompiledOrderEmailTemplateProjection = {
  __typename?: 'CompiledOrderEmailTemplateProjection';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CompositeImageProjection = {
  __typename?: 'CompositeImageProjection';
  adId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Array<Maybe<ImageProjection>>>;
  lastModified?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompositeImageProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  lastModified?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentInventoryTypePayload = {
  __typename?: 'CreateDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  errors?: Maybe<CreateDocumentInventoryTypeValidationError>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type CreateDocumentInventoryTypeValidationError = {
  __typename?: 'CreateDocumentInventoryTypeValidationError';
  message?: Maybe<Scalars['String']>;
  value?: Maybe<JsonObject>;
};

export type CreateDocumentPackageInput = {
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars['UUID']>>;
  orderId: Scalars['UUID'];
  shippingProvider: Scalars['Int'];
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentPackageProjection = {
  __typename?: 'CreateDocumentPackageProjection';
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
};

export type CreateDocumentPackageResult = CreateDocumentPackageProjection | DocumentItemsInPackageProblem | DocumentPackageConflictProblem | DocumentPackageEmptyBranchNameProblem | DocumentPackageEmptyFirstNameProblem | DocumentPackageEmptyLastNameProblem | DocumentPackageNoHouseNumAddressProblem | DocumentPackageZeroItemsProblem | DocumentPackagesGeneralProblem;

export type CreateExternalFinanceRiskEvaluationProjectionInput = {
  contractType?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateExternalFinancingOptionsProjectionInput = {
  ahOpeningFees?: InputMaybe<Scalars['BigDecimal']>;
  contractType?: InputMaybe<Scalars['String']>;
  damageInsurance?: InputMaybe<Scalars['String']>;
  externalFinancingRefNumber?: InputMaybe<Scalars['String']>;
  financingProvider?: InputMaybe<Scalars['String']>;
  gapInsurance?: InputMaybe<Scalars['String']>;
  interestRate?: InputMaybe<Scalars['Float']>;
  ppi?: InputMaybe<Scalars['String']>;
};

export type CreateOrderHistoryEmailEventProjectionInput = {
  attachments?: InputMaybe<Array<InputMaybe<OrderEmailEventAttachmentCreateProjectionInput>>>;
  customerEmail?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateRetailFeatureProjectionInput = {
  condition?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateRetailOutboundPaymentProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  description?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  paymentType?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
  reason?: InputMaybe<RetailOutboundPaymentReasonProjectionInput>;
  status?: InputMaybe<RetailOutboundPaymentStatus>;
};

export type CreditNoteCreateProjectionInput = {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemCreateProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type CreditNoteDistributionProjection = {
  __typename?: 'CreditNoteDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  id?: Maybe<Scalars['UUID']>;
  items?: Maybe<Array<Maybe<CreditNoteItemDistributionProjection>>>;
  reason?: Maybe<Scalars['String']>;
  tempId?: Maybe<Scalars['Int']>;
};

export type CreditNoteDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  id?: InputMaybe<Scalars['UUID']>;
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>>;
  reason?: InputMaybe<Scalars['String']>;
  tempId?: InputMaybe<Scalars['Int']>;
};

export type CreditNoteItemCreateProjectionInput = {
  amountMinorUnits: Scalars['Long'];
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
};

export type CreditNoteItemDistributionProjection = {
  __typename?: 'CreditNoteItemDistributionProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  creditNoteItemId?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  orderItemId?: Maybe<Scalars['UUID']>;
};

export type CreditNoteItemDistributionProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditNoteItemId?: InputMaybe<Scalars['UUID']>;
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
};

export type CreditNoteItemProjection = {
  __typename?: 'CreditNoteItemProjection';
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<InvoiceItemExternalType>;
  id?: Maybe<Scalars['UUID']>;
  vatKey?: Maybe<Scalars['String']>;
};

export type CreditNoteNumberPendingTaskProjection = {
  __typename?: 'CreditNoteNumberPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CreditNoteOutboundPaymentRefDistributionProjection = {
  __typename?: 'CreditNoteOutboundPaymentRefDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  creditNoteTempId?: Maybe<Scalars['Int']>;
  refundTempId?: Maybe<Scalars['Int']>;
};

export type CreditNoteOutboundPaymentRefDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  creditNoteTempId?: InputMaybe<Scalars['Int']>;
  refundTempId?: InputMaybe<Scalars['Int']>;
};

export type CreditNoteOutboundPaymentRefProjection = {
  __typename?: 'CreditNoteOutboundPaymentRefProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  outboundPayment?: Maybe<RetailOutboundPaymentProjection>;
};

export type CreditNotePendingTaskProjection = {
  __typename?: 'CreditNotePendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CreditNoteProjection = {
  __typename?: 'CreditNoteProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  invoicedOn?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CreditNoteItemProjection>>>;
  number?: Maybe<Scalars['String']>;
  oracleAccountingDate?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  oracleGrossAmount?: Maybe<PriceDto>;
  oracleIssueDate?: Maybe<Scalars['String']>;
  oracleTransactionalNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  outboundPaymentRefs?: Maybe<Array<Maybe<CreditNoteOutboundPaymentRefProjection>>>;
  reason?: Maybe<Scalars['String']>;
  state?: Maybe<InvoiceState>;
  totalAmount?: Maybe<PriceDto>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
};

export type CreditNoteUpdateProjectionInput = {
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type CreditRiskDataDto = {
  __typename?: 'CreditRiskDataDTO';
  averageDaysOverdrawn?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  encashmentPayments?: Maybe<AmountInfo>;
  gamblingExpenses?: Maybe<AmountInfo>;
  hasCreditCard?: Maybe<Scalars['Boolean']>;
  monthlyNetIncome?: Maybe<AmountInfo>;
  unemployedBenefits?: Maybe<AmountInfo>;
};

export type CurrencyCode = {
  __typename?: 'CurrencyCode';
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
};

export type CustomEquipment = {
  __typename?: 'CustomEquipment';
  groupId?: Maybe<Scalars['Long']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomEquipmentInput = {
  groupId?: InputMaybe<Scalars['Long']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressCreateProjectionInput = {
  additionalAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressProjection = {
  __typename?: 'CustomerAddressProjection';
  additionalAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  info?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type CustomerAddressUpdateProjectionInput = {
  additionalAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CustomerDtoInput = {
  hasPowerOfAttorneyForPickUp?: InputMaybe<Scalars['Boolean']>;
  idNumber?: InputMaybe<Scalars['String']>;
};

export type CustomerExpectedMaxEtaData = {
  __typename?: 'CustomerExpectedMaxETAData';
  customerExpectedMaxETA?: Maybe<Scalars['String']>;
  customerExpectedMaxETACalculatedOn?: Maybe<Scalars['String']>;
  customerExpectedMaxETAChangeCount?: Maybe<Scalars['Int']>;
  initialCustomerExpectedMaxETA?: Maybe<Scalars['String']>;
};

export type CustomerProjection = {
  __typename?: 'CustomerProjection';
  billingAddress?: Maybe<CustomerAddressProjection>;
  carRegistrationAddress?: Maybe<CustomerAddressProjection>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<CustomerAddressProjection>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type CustomerUserCreateProjectionInput = {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
};

export type CustomerUserUpdateProjectionInput = {
  billingAddressId?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryAddressId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type DamageCompensationPendingTaskProjection = {
  __typename?: 'DamageCompensationPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  damageCompensationDescription?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type DamageDto = {
  __typename?: 'DamageDTO';
  carPart?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  damageType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['UUID']>;
};

export type DamageDtoInput = {
  carPart?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  damageType?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['UUID']>;
};

export type DamageImageDto = {
  __typename?: 'DamageImageDTO';
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type DamageTypeProjection = {
  __typename?: 'DamageTypeProjection';
  translationKey?: Maybe<Scalars['String']>;
};

export type DamagesProjection = {
  __typename?: 'DamagesProjection';
  brakes?: Maybe<Array<Maybe<BrakeConditionProjection>>>;
  retailAd?: Maybe<Array<Maybe<RetailAdDamageProjection>>>;
};

export type DataValidationDto = {
  __typename?: 'DataValidationDTO';
  errors?: Maybe<Scalars['Json']>;
  input?: Maybe<Scalars['Json']>;
  processable: Scalars['Boolean'];
  stockNumber?: Maybe<Scalars['String']>;
};

export type DealerDto = {
  __typename?: 'DealerDTO';
  accountId?: Maybe<Scalars['String']>;
  branchType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  imageLimit?: Maybe<Scalars['Int']>;
};

export type DeleteDocumentInventoryItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type DeleteDocumentInventoryItemPayload = {
  __typename?: 'DeleteDocumentInventoryItemPayload';
  ad?: Maybe<AdProjection>;
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryItem?: Maybe<DocumentInventoryItem>;
};


export type DeleteDocumentInventoryItemPayloadAdArgs = {
  adId: Scalars['UUID'];
};

export type DeliveryEta = {
  __typename?: 'DeliveryETA';
  customerExpectedMaxETAData?: Maybe<CustomerExpectedMaxEtaData>;
  deliveryETAType?: Maybe<Scalars['String']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRange>;
};

export type DeliveryEtaRange = {
  __typename?: 'DeliveryEtaRange';
  deliveryEtaRangeCalculatedOn?: Maybe<Scalars['String']>;
  deliveryEtaRangeChangeCount?: Maybe<Scalars['Int']>;
  earliest?: Maybe<Scalars['String']>;
  initialEarliestEta?: Maybe<Scalars['String']>;
  initialLatestEta?: Maybe<Scalars['String']>;
  latest?: Maybe<Scalars['String']>;
};

export type DeliveryEtaRangeDto = {
  __typename?: 'DeliveryEtaRangeDTO';
  earliest?: Maybe<Scalars['String']>;
  latest?: Maybe<Scalars['String']>;
};

export type DeliveryPrice = {
  __typename?: 'DeliveryPrice';
  amount?: Maybe<Scalars['Long']>;
  currency?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['Long']>;
  vatAmount?: Maybe<Scalars['Long']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type DeliveryRouteProjection = {
  __typename?: 'DeliveryRouteProjection';
  destinationAddress?: Maybe<RetailRouteAddressProjection>;
  items?: Maybe<Array<Maybe<RetailRouteItemProjection>>>;
  originAddress?: Maybe<RetailRouteAddressProjection>;
};

export type DigitalCarRegistrationProcessingErrorProjection = {
  __typename?: 'DigitalCarRegistrationProcessingErrorProjection';
  description: Scalars['String'];
};

export type DigitalCarRegistrationProjection = {
  __typename?: 'DigitalCarRegistrationProjection';
  accountData?: Maybe<AccountDataDto>;
  assignedTo?: Maybe<Scalars['UUID']>;
  assignedToUser?: Maybe<RetailUserDto>;
  bankAccountDataId?: Maybe<Scalars['UUID']>;
  carRegistrationAddress?: Maybe<RetailAddressDto>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  customer?: Maybe<RetailCustomerProjection>;
  customerId?: Maybe<Scalars['UUID']>;
  evbNumber?: Maybe<Scalars['String']>;
  history?: Maybe<Array<DigitalCarRegistrationStatusHistoryProjection>>;
  holderPlaceOfBirthday?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  licensePlateNumber?: Maybe<Scalars['String']>;
  licensePlatePin?: Maybe<Scalars['String']>;
  orderId: Scalars['UUID'];
  registrationCertificate1Code?: Maybe<Scalars['String']>;
  registrationCertificate2Number?: Maybe<Scalars['String']>;
  registrationType: CarRegistrationType;
  retailOrder?: Maybe<RetailOrderDto>;
  status: Scalars['String'];
  stockNumber: Scalars['String'];
  updatedOn?: Maybe<Scalars['String']>;
  vehicleTaxesCollectedPeriod?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
};

export type DigitalCarRegistrationRequestProjectionInput = {
  dateOfFirstRegistration?: InputMaybe<Scalars['LocalDate']>;
  insuranceConfirmationNumber?: InputMaybe<Scalars['String']>;
  licensePlatePattern?: InputMaybe<Scalars['String']>;
  numberOfTheRegistrationCertificate?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  securityCodeOfTheRegistrationCertificate?: InputMaybe<Scalars['String']>;
  stampBadgeSecurityCode?: InputMaybe<Scalars['String']>;
};

export type DigitalCarRegistrationStatusHistoryProjection = {
  __typename?: 'DigitalCarRegistrationStatusHistoryProjection';
  date: Scalars['String'];
  error?: Maybe<DigitalCarRegistrationProcessingErrorProjection>;
  status: Scalars['String'];
  step: Scalars['String'];
};

export type DigitalCarRegistrationStatusProjection = {
  __typename?: 'DigitalCarRegistrationStatusProjection';
  orderId?: Maybe<Scalars['UUID']>;
};

export type DigitalCarRegistrationUpdateProjectionInput = {
  assignedTo?: InputMaybe<Scalars['UUID']>;
  bankAccountDataId?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  evbNumber?: InputMaybe<Scalars['String']>;
  holderPlaceOfBirthday?: InputMaybe<Scalars['String']>;
  licensePlateNumber?: InputMaybe<Scalars['String']>;
  licensePlatePin?: InputMaybe<Scalars['String']>;
  registrationCertificate1Code?: InputMaybe<Scalars['String']>;
  registrationCertificate2Number?: InputMaybe<Scalars['String']>;
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisableDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type DisableDocumentInventoryTypePayload = {
  __typename?: 'DisableDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type DiscountCreateProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderId?: InputMaybe<Scalars['UUID']>;
};

export type DiscountDto = {
  __typename?: 'DiscountDTO';
  amount?: Maybe<PriceDto>;
  amountMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteNumber?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type DocumentBranchProjection = {
  __typename?: 'DocumentBranchProjection';
  address2?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  documentContact?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type DocumentInventoryItem = {
  __typename?: 'DocumentInventoryItem';
  id: Scalars['UUID'];
  location?: Maybe<DocumentLocation>;
  name: Scalars['String'];
};

export type DocumentInventoryItemConnection_DocumentInventoryItem = {
  __typename?: 'DocumentInventoryItemConnection_DocumentInventoryItem';
  nodes?: Maybe<Array<Maybe<DocumentInventoryItem>>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type DocumentInventoryItemLocationFilterInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DocumentInventoryItemsFilterInput = {
  location?: InputMaybe<DocumentLocationFilterInput>;
  orderId: Scalars['UUID'];
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DocumentInventoryPackageAddressInput = {
  address: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  deliveryLocationId?: InputMaybe<Scalars['Int']>;
  documentContact?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  houseNum?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  shippingCompanyName?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type DocumentInventoryPackageAddressProjection = {
  __typename?: 'DocumentInventoryPackageAddressProjection';
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  documentContact?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  houseNum?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingCompanyName?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type DocumentInventoryPackageFilterInput = {
  orderId: Scalars['UUID'];
};

export type DocumentInventoryPackageProjection = {
  __typename?: 'DocumentInventoryPackageProjection';
  changelog: PageDto_ChangelogProjection;
  createdAt: Scalars['String'];
  deliveredOn?: Maybe<Scalars['String']>;
  deliveryAddress: DocumentInventoryPackageAddressProjection;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  estimatedDeliveryDate?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  originAddress: DocumentInventoryPackageAddressProjection;
  packageNumber: Scalars['Int'];
  pickedUpOn?: Maybe<Scalars['String']>;
  shippingProvider?: Maybe<ShippingProviderProjection>;
  status: Scalars['String'];
  trackingCode?: Maybe<Scalars['String']>;
};

export type DocumentInventoryType = {
  __typename?: 'DocumentInventoryType';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<DocumentInventoryTypeState>;
};

export type DocumentInventoryTypeConnection_DocumentInventoryType = {
  __typename?: 'DocumentInventoryTypeConnection_DocumentInventoryType';
  nodes?: Maybe<Array<Maybe<DocumentInventoryType>>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export enum DocumentInventoryTypeState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type DocumentItemInPackageProblem = {
  __typename?: 'DocumentItemInPackageProblem';
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentItemsInPackageProblem = {
  __typename?: 'DocumentItemsInPackageProblem';
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentLocation = {
  __typename?: 'DocumentLocation';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  zipcode: Scalars['String'];
};

export type DocumentLocationFilterInput = {
  locationId?: InputMaybe<Scalars['Int']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentPackageAlreadyCanceledProblem = {
  __typename?: 'DocumentPackageAlreadyCanceledProblem';
  isCanceled?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageAlreadyDeliveredProblem = {
  __typename?: 'DocumentPackageAlreadyDeliveredProblem';
  isDelivered?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageConflictProblem = {
  __typename?: 'DocumentPackageConflictProblem';
  documentItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyBranchNameProblem = {
  __typename?: 'DocumentPackageEmptyBranchNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyDeliveredOnProblem = {
  __typename?: 'DocumentPackageEmptyDeliveredOnProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyFirstNameProblem = {
  __typename?: 'DocumentPackageEmptyFirstNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyLastNameProblem = {
  __typename?: 'DocumentPackageEmptyLastNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyPickedUpOnProblem = {
  __typename?: 'DocumentPackageEmptyPickedUpOnProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageNoHouseNumAddressProblem = {
  __typename?: 'DocumentPackageNoHouseNumAddressProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageNotOpenProblem = {
  __typename?: 'DocumentPackageNotOpenProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageSameStatusProblem = {
  __typename?: 'DocumentPackageSameStatusProblem';
  message?: Maybe<Scalars['String']>;
  sameStatus?: Maybe<Scalars['String']>;
};

export type DocumentPackageZeroItemsProblem = {
  __typename?: 'DocumentPackageZeroItemsProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackagesGeneralProblem = {
  __typename?: 'DocumentPackagesGeneralProblem';
  errorCode?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DocumentSignInProcessType {
  DigitalCarRegistration = 'DIGITAL_CAR_REGISTRATION'
}

export type DocumentsDtoInput = {
  hasCOCDocument?: InputMaybe<Scalars['Boolean']>;
  hasCarRegistrationDocument?: InputMaybe<Scalars['Boolean']>;
  hasCertifiedChequeReceived?: InputMaybe<Scalars['Boolean']>;
  hasInstantTransferReceived?: InputMaybe<Scalars['Boolean']>;
  hasLicensePlate?: InputMaybe<Scalars['Boolean']>;
  hasOtherDocuments?: InputMaybe<Scalars['Boolean']>;
  hasOwnershipDocument?: InputMaybe<Scalars['Boolean']>;
  hasServiceBook?: InputMaybe<Scalars['Boolean']>;
  hasVehicleInspection?: InputMaybe<Scalars['Boolean']>;
  otherDocuments?: InputMaybe<Scalars['String']>;
};

export type DocumentsReturnedDeregistrationTaskProjection = {
  __typename?: 'DocumentsReturnedDeregistrationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type DownPaymentItemProjection = {
  __typename?: 'DownPaymentItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  downPayment?: Maybe<DownPaymentProjection>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type DownPaymentProjection = {
  __typename?: 'DownPaymentProjection';
  active: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  priceDetails?: Maybe<PriceDetailsDto>;
  refId?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  updatedOn?: Maybe<Scalars['String']>;
};

export type ElasticCustomerProjection = {
  __typename?: 'ElasticCustomerProjection';
  customerType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export type ElasticPriceProjection = {
  __typename?: 'ElasticPriceProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type EnableDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type EnableDocumentInventoryTypePayload = {
  __typename?: 'EnableDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type EquipmentItemDto = {
  __typename?: 'EquipmentItemDTO';
  dataSource?: Maybe<Scalars['Int']>;
  equipmentType?: Maybe<EquipmentType>;
  groupId?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
};

export enum EquipmentType {
  Custom = 'CUSTOM',
  Vin = 'VIN'
}

export type EsOrderProjection = {
  __typename?: 'EsOrderProjection';
  adId?: Maybe<Scalars['UUID']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customer?: Maybe<ElasticCustomerProjection>;
  id?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  overallOverdue?: Maybe<Scalars['Int']>;
  overdueBranchDelivery?: Maybe<Scalars['Long']>;
  overdueMaxEta?: Maybe<Scalars['Long']>;
  paymentStatus?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  totalPriceGross?: Maybe<ElasticPriceProjection>;
};

export type ExitCheckCommentProjection = {
  __typename?: 'ExitCheckCommentProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  exitCheckId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
};

export type ExitCheckCommentsProjection = {
  __typename?: 'ExitCheckCommentsProjection';
  comments?: Maybe<Array<Maybe<ExitCheckCommentProjection>>>;
};

export type ExitCheckProjection = {
  __typename?: 'ExitCheckProjection';
  adId?: Maybe<Scalars['UUID']>;
  branchData?: Maybe<BranchProjection>;
  completedOn?: Maybe<Scalars['LocalDateTime']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  id?: Maybe<Scalars['UUID']>;
  partnerData?: Maybe<RefurbishmentPartnerDto>;
  preparedStatusDate?: Maybe<Scalars['LocalDateTime']>;
  repairEta?: Maybe<Scalars['LocalDate']>;
  state?: Maybe<ExitCheckState>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
  vin?: Maybe<Scalars['String']>;
};

export enum ExitCheckState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Ordered = 'ORDERED',
  Prepared = 'PREPARED',
  RepairsCompleted = 'REPAIRS_COMPLETED',
  RepairsInProgress = 'REPAIRS_IN_PROGRESS',
  RepairsNeeded = 'REPAIRS_NEEDED'
}

export type ExportItem = {
  __typename?: 'ExportItem';
  daysOnline?: Maybe<Scalars['Int']>;
  dealerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  inProgress?: Maybe<Scalars['Boolean']>;
  lastPublishingErrors?: Maybe<Array<Maybe<PublishingError>>>;
  links?: Maybe<Array<Maybe<LinkProjection>>>;
  marketplace?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ExportSectionMutationProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  mobileDeDealerId?: InputMaybe<Scalars['String']>;
  priceMajorUnits?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ExportSectionProjection = {
  __typename?: 'ExportSectionProjection';
  exportItems?: Maybe<Array<Maybe<ExportItem>>>;
};

export enum ExportTaxAdvantageType {
  Bpm = 'BPM',
  Dk = 'DK',
  Nova = 'NOVA'
}

export type ExteriorDto = {
  __typename?: 'ExteriorDTO';
  backLeftRimType?: Maybe<RimType>;
  backLeftTireType?: Maybe<TireType>;
  backRightRimType?: Maybe<RimType>;
  backRightTireType?: Maybe<TireType>;
  exteriorPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  frontLeftRimType?: Maybe<RimType>;
  frontLeftTireType?: Maybe<TireType>;
  frontRightRimType?: Maybe<RimType>;
  frontRightTireType?: Maybe<TireType>;
  hasLicensePlateFront?: Maybe<Scalars['Boolean']>;
  hasLicensePlateRear?: Maybe<Scalars['Boolean']>;
  isAntennaAvailable?: Maybe<Scalars['Boolean']>;
  isExteriorClean?: Maybe<Scalars['Boolean']>;
  overviewFrontPhoto?: Maybe<Scalars['UUID']>;
  overviewRearPhoto?: Maybe<Scalars['UUID']>;
  roofPhoto?: Maybe<Scalars['UUID']>;
  sideLeftPhoto?: Maybe<Scalars['UUID']>;
  sideRightPhoto?: Maybe<Scalars['UUID']>;
};

export type ExteriorDtoInput = {
  backLeftRimType?: InputMaybe<RimType>;
  backLeftTireType?: InputMaybe<TireType>;
  backRightRimType?: InputMaybe<RimType>;
  backRightTireType?: InputMaybe<TireType>;
  exteriorPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  frontLeftRimType?: InputMaybe<RimType>;
  frontLeftTireType?: InputMaybe<TireType>;
  frontRightRimType?: InputMaybe<RimType>;
  frontRightTireType?: InputMaybe<TireType>;
  hasLicensePlateFront?: InputMaybe<Scalars['Boolean']>;
  hasLicensePlateRear?: InputMaybe<Scalars['Boolean']>;
  isAntennaAvailable?: InputMaybe<Scalars['Boolean']>;
  isExteriorClean?: InputMaybe<Scalars['Boolean']>;
  overviewFrontPhoto?: InputMaybe<Scalars['UUID']>;
  overviewRearPhoto?: InputMaybe<Scalars['UUID']>;
  roofPhoto?: InputMaybe<Scalars['UUID']>;
  sideLeftPhoto?: InputMaybe<Scalars['UUID']>;
  sideRightPhoto?: InputMaybe<Scalars['UUID']>;
};

export type ExternalFinanceRiskEvaluationProjection = {
  __typename?: 'ExternalFinanceRiskEvaluationProjection';
  contractType?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
  orderId?: Maybe<Scalars['UUID']>;
  result?: Maybe<Scalars['String']>;
  sameEmployerOverTimePeriod?: Maybe<Scalars['Boolean']>;
};

export type ExternalFinancingOptionsProjection = {
  __typename?: 'ExternalFinancingOptionsProjection';
  ahOpeningFees?: Maybe<Scalars['BigDecimal']>;
  contractType?: Maybe<Scalars['String']>;
  damageInsurance?: Maybe<Scalars['String']>;
  externalFinancingRefNumber?: Maybe<Scalars['String']>;
  financingProvider?: Maybe<Scalars['String']>;
  gapInsurance?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  ppi?: Maybe<Scalars['String']>;
};

export type ExternalFinancingOptionsValuesProjection = {
  __typename?: 'ExternalFinancingOptionsValuesProjection';
  options?: Maybe<Scalars['Map_String_Map_String_OptionProjectionScalar']>;
};

export enum ExternalPaymentSystem {
  Adyen = 'ADYEN'
}

export enum Feature {
  HasAbs = 'HAS_ABS',
  HasAccelerationSkidControl = 'HAS_ACCELERATION_SKID_CONTROL',
  HasAdaptiveCruiseController = 'HAS_ADAPTIVE_CRUISE_CONTROLLER',
  HasAdaptiveHeadlights = 'HAS_ADAPTIVE_HEADLIGHTS',
  HasAirbag = 'HAS_AIRBAG',
  HasAirConditioning = 'HAS_AIR_CONDITIONING',
  HasAirSuspension = 'HAS_AIR_SUSPENSION',
  HasAlarmSystem = 'HAS_ALARM_SYSTEM',
  HasAlloyRims = 'HAS_ALLOY_RIMS',
  HasAllWheelDrive = 'HAS_ALL_WHEEL_DRIVE',
  HasAmbientLighting = 'HAS_AMBIENT_LIGHTING',
  HasAndroidPhoneConnection = 'HAS_ANDROID_PHONE_CONNECTION',
  HasAppleCarplay = 'HAS_APPLE_CARPLAY',
  HasAutomaticAirCondition = 'HAS_AUTOMATIC_AIR_CONDITION',
  HasAutomaticHeadlightControl = 'HAS_AUTOMATIC_HEADLIGHT_CONTROL',
  HasAuxiliaryHeater = 'HAS_AUXILIARY_HEATER',
  HasAxleLoadIncrease = 'HAS_AXLE_LOAD_INCREASE',
  HasBacklightLed = 'HAS_BACKLIGHT_LED',
  HasBiXenonLights = 'HAS_BI_XENON_LIGHTS',
  /** @deprecated Deprecated */
  HasBlindSpotAssist = 'HAS_BLIND_SPOT_ASSIST',
  HasBlockHeater = 'HAS_BLOCK_HEATER',
  HasBluetooth = 'HAS_BLUETOOTH',
  HasBrakeAssist = 'HAS_BRAKE_ASSIST',
  HasCargoSpacePartition = 'HAS_CARGO_SPACE_PARTITION',
  HasCarCommunication = 'HAS_CAR_COMMUNICATION',
  HasCdMultichanger = 'HAS_CD_MULTICHANGER',
  HasCenterArmRest = 'HAS_CENTER_ARM_REST',
  HasCentralLocking = 'HAS_CENTRAL_LOCKING',
  HasChassisControlSystem = 'HAS_CHASSIS_CONTROL_SYSTEM',
  /** @deprecated Deprecated */
  HasClimatisation = 'HAS_CLIMATISATION',
  HasCocDocument = 'HAS_COC_DOCUMENT',
  HasComingHomeLeavingHome = 'HAS_COMING_HOME_LEAVING_HOME',
  HasCorneringLights = 'HAS_CORNERING_LIGHTS',
  HasCoTwo = 'HAS_CO_TWO',
  HasCrosswindAssist = 'HAS_CROSSWIND_ASSIST',
  HasCrossTrafficAssist = 'HAS_CROSS_TRAFFIC_ASSIST',
  HasCruiseControl = 'HAS_CRUISE_CONTROL',
  HasDaytimeRunningLights = 'HAS_DAYTIME_RUNNING_LIGHTS',
  /** @deprecated Deprecated */
  HasDetachableTowHook = 'HAS_DETACHABLE_TOW_HOOK',
  HasDieselParticulateFilter = 'HAS_DIESEL_PARTICULATE_FILTER',
  HasDigitalRadio = 'HAS_DIGITAL_RADIO',
  HasDimmingInteriorMirror = 'HAS_DIMMING_INTERIOR_MIRROR',
  HasDistanceWarner = 'HAS_DISTANCE_WARNER',
  HasDraughtStop = 'HAS_DRAUGHT_STOP',
  HasDriverDrowsinessDetection = 'HAS_DRIVER_DROWSINESS_DETECTION',
  HasDrivingModeSwitch = 'HAS_DRIVING_MODE_SWITCH',
  HasDynamicPerformanceControl = 'HAS_DYNAMIC_PERFORMANCE_CONTROL',
  HasEcoDriveControl = 'HAS_ECO_DRIVE_CONTROL',
  HasElectricSeats = 'HAS_ELECTRIC_SEATS',
  HasElectricSideViewMirrors = 'HAS_ELECTRIC_SIDE_VIEW_MIRRORS',
  HasElectricSlidingDoors = 'HAS_ELECTRIC_SLIDING_DOORS',
  HasElectricTailGate = 'HAS_ELECTRIC_TAIL_GATE',
  HasEmergencyBreakAssist = 'HAS_EMERGENCY_BREAK_ASSIST',
  HasEmergencyCallSystem = 'HAS_EMERGENCY_CALL_SYSTEM',
  HasEntertainmentSystem = 'HAS_ENTERTAINMENT_SYSTEM',
  HasEnvironmentAssistSystem = 'HAS_ENVIRONMENT_ASSIST_SYSTEM',
  HasEsp = 'HAS_ESP',
  HasExteriorMirror = 'HAS_EXTERIOR_MIRROR',
  HasFogLights = 'HAS_FOG_LIGHTS',
  HasFoldFlatPassengerSeat = 'HAS_FOLD_FLAT_PASSENGER_SEAT',
  HasFrontCamera = 'HAS_FRONT_CAMERA',
  HasGlareFreeHighBeam = 'HAS_GLARE_FREE_HIGH_BEAM',
  /** @deprecated Deprecated */
  HasGps = 'HAS_GPS',
  HasHandicappedFeatures = 'HAS_HANDICAPPED_FEATURES',
  HasHandsFreeSystem = 'HAS_HANDS_FREE_SYSTEM',
  HasHardtop = 'HAS_HARDTOP',
  HasHeadlightCleaningSystem = 'HAS_HEADLIGHT_CLEANING_SYSTEM',
  HasHeadUpDisplay = 'HAS_HEAD_UP_DISPLAY',
  HasHeatedFrontWindow = 'HAS_HEATED_FRONT_WINDOW',
  HasHeatedSeats = 'HAS_HEATED_SEATS',
  HasHeatedSeatsFront = 'HAS_HEATED_SEATS_FRONT',
  HasHeatedSeatsFrontAndRear = 'HAS_HEATED_SEATS_FRONT_AND_REAR',
  HasHeatedSideViewMirrors = 'HAS_HEATED_SIDE_VIEW_MIRRORS',
  HasHeatedSteeringWheel = 'HAS_HEATED_STEERING_WHEEL',
  HasHeatedWindShield = 'HAS_HEATED_WIND_SHIELD',
  HasHighBeamAssist = 'HAS_HIGH_BEAM_ASSIST',
  HasHighPerformanceBrakes = 'HAS_HIGH_PERFORMANCE_BRAKES',
  HasHillDescentControl = 'HAS_HILL_DESCENT_CONTROL',
  HasHillStartAssist = 'HAS_HILL_START_ASSIST',
  HasIntegralSafetyConcept = 'HAS_INTEGRAL_SAFETY_CONCEPT',
  HasIntegratedMusicStreaming = 'HAS_INTEGRATED_MUSIC_STREAMING',
  HasIsofixMountingPoints = 'HAS_ISOFIX_MOUNTING_POINTS',
  HasKeylessEntry = 'HAS_KEYLESS_ENTRY',
  HasLaneAssist = 'HAS_LANE_ASSIST',
  HasLaneChangeAssist = 'HAS_LANE_CHANGE_ASSIST',
  HasLaserHeadlights = 'HAS_LASER_HEADLIGHTS',
  HasLeatherSteeringWheel = 'HAS_LEATHER_STEERING_WHEEL',
  HasLedHeadlights = 'HAS_LED_HEADLIGHTS',
  HasLevelControl = 'HAS_LEVEL_CONTROL',
  HasLimitedSlipDifferential = 'HAS_LIMITED_SLIP_DIFFERENTIAL',
  HasLpg = 'HAS_LPG',
  HasMaintenanceGuide = 'HAS_MAINTENANCE_GUIDE',
  HasMassageSeats = 'HAS_MASSAGE_SEATS',
  HasNavigationSystem = 'HAS_NAVIGATION_SYSTEM',
  HasNewHuAu = 'HAS_NEW_HU_AU',
  HasNightViewAssist = 'HAS_NIGHT_VIEW_ASSIST',
  HasOnBoardComputer = 'HAS_ON_BOARD_COMPUTER',
  HasPaddleShifters = 'HAS_PADDLE_SHIFTERS',
  HasPanoramicRoof = 'HAS_PANORAMIC_ROOF',
  /** @deprecated Deprecated */
  HasParkingAssist = 'HAS_PARKING_ASSIST',
  HasParkDistanceControl = 'HAS_PARK_DISTANCE_CONTROL',
  HasParkDistanceControlFrontAndRear = 'HAS_PARK_DISTANCE_CONTROL_FRONT_AND_REAR',
  HasParkDistanceControlRear = 'HAS_PARK_DISTANCE_CONTROL_REAR',
  HasParkDistanceControlWithCamera = 'HAS_PARK_DISTANCE_CONTROL_WITH_CAMERA',
  HasParkHeater = 'HAS_PARK_HEATER',
  HasPowerTakeOff = 'HAS_POWER_TAKE_OFF',
  HasPowerWindows = 'HAS_POWER_WINDOWS',
  HasPowerWindowsFront = 'HAS_POWER_WINDOWS_FRONT',
  HasPowerWindowsFrontAndRear = 'HAS_POWER_WINDOWS_FRONT_AND_REAR',
  HasRadioCassette = 'HAS_RADIO_CASSETTE',
  HasRadioCd = 'HAS_RADIO_CD',
  HasRadioDab = 'HAS_RADIO_DAB',
  HasRadioMp3 = 'HAS_RADIO_MP3',
  HasRadioUsb = 'HAS_RADIO_USB',
  HasRainSensorWipers = 'HAS_RAIN_SENSOR_WIPERS',
  HasRangeExtender = 'HAS_RANGE_EXTENDER',
  HasRearSeatPassThrough = 'HAS_REAR_SEAT_PASS_THROUGH',
  HasRearSeatSplit = 'HAS_REAR_SEAT_SPLIT',
  HasRearWindBlocker = 'HAS_REAR_WIND_BLOCKER',
  HasRemoteCentralLocking = 'HAS_REMOTE_CENTRAL_LOCKING',
  HasRoofRack = 'HAS_ROOF_RACK',
  HasSeatSettings = 'HAS_SEAT_SETTINGS',
  HasSeatVentilation = 'HAS_SEAT_VENTILATION',
  HasSecurityPackage = 'HAS_SECURITY_PACKAGE',
  HasSelfParkingSystem = 'HAS_SELF_PARKING_SYSTEM',
  HasSelfSteeringSystem = 'HAS_SELF_STEERING_SYSTEM',
  HasServoSteering = 'HAS_SERVO_STEERING',
  HasSkiBag = 'HAS_SKI_BAG',
  HasSmartphoneInductionCharger = 'HAS_SMARTPHONE_INDUCTION_CHARGER',
  HasSmokersPackage = 'HAS_SMOKERS_PACKAGE',
  HasSoundSystem = 'HAS_SOUND_SYSTEM',
  /** @deprecated Deprecated */
  HasSpeedControl = 'HAS_SPEED_CONTROL',
  HasSpeedLimiter = 'HAS_SPEED_LIMITER',
  HasSportPackage = 'HAS_SPORT_PACKAGE',
  HasSportSeats = 'HAS_SPORT_SEATS',
  HasSportSuspension = 'HAS_SPORT_SUSPENSION',
  HasSportUndercarriage = 'HAS_SPORT_UNDERCARRIAGE',
  HasStartStopAutomatic = 'HAS_START_STOP_AUTOMATIC',
  /** @deprecated Deprecated */
  HasStartStopSystemEngine = 'HAS_START_STOP_SYSTEM_ENGINE',
  HasSteeringStabilizerAssist = 'HAS_STEERING_STABILIZER_ASSIST',
  HasSteeringWheelWithMultifunction = 'HAS_STEERING_WHEEL_WITH_MULTIFUNCTION',
  HasSunroof = 'HAS_SUNROOF',
  HasSunBlindSystem = 'HAS_SUN_BLIND_SYSTEM',
  HasThermalGlazingInsulation = 'HAS_THERMAL_GLAZING_INSULATION',
  HasTirePressureMonitoringSystem = 'HAS_TIRE_PRESSURE_MONITORING_SYSTEM',
  HasTouchScreen = 'HAS_TOUCH_SCREEN',
  HasTowBar = 'HAS_TOW_BAR',
  HasTractionControl = 'HAS_TRACTION_CONTROL',
  HasTrafficSignRecognition = 'HAS_TRAFFIC_SIGN_RECOGNITION',
  HasTrailerCoupling = 'HAS_TRAILER_COUPLING',
  HasTuning = 'HAS_TUNING',
  HasTv = 'HAS_TV',
  HasUnclassifiedFeatureDetail = 'HAS_UNCLASSIFIED_FEATURE_DETAIL',
  HasUndercarriage = 'HAS_UNDERCARRIAGE',
  HasUsb = 'HAS_USB',
  HasVirtualCockpit = 'HAS_VIRTUAL_COCKPIT',
  HasVoiceControl = 'HAS_VOICE_CONTROL',
  HasWarranty = 'HAS_WARRANTY',
  HasWifiHotspot = 'HAS_WIFI_HOTSPOT',
  HasWinterPackage = 'HAS_WINTER_PACKAGE',
  HasWoodFlooringCargoArea = 'HAS_WOOD_FLOORING_CARGO_AREA',
  HasWoodPanelingCargoArea = 'HAS_WOOD_PANELING_CARGO_AREA',
  HasXenonLights = 'HAS_XENON_LIGHTS',
  IsBiodieselSuitable = 'IS_BIODIESEL_SUITABLE',
  IsConvertible = 'IS_CONVERTIBLE',
  IsE10Enabled = 'IS_E10_ENABLED',
  IsElectroHydraulicConvertible = 'IS_ELECTRO_HYDRAULIC_CONVERTIBLE',
  IsFinanced = 'IS_FINANCED'
}

export type FilterProjectionInput = {
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type FinanceAppProjection = {
  __typename?: 'FinanceAppProjection';
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type FinanceRejectionReasonFiltersInput = {
  financingType?: InputMaybe<OrderFinancingType>;
};

export type FinanceRiskEvaluationProjection = {
  __typename?: 'FinanceRiskEvaluationProjection';
  contractType?: Maybe<Scalars['String']>;
  employedUnderLocalWorkContract?: Maybe<Scalars['Boolean']>;
  employmentStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  sameEmployerOverTimePeriod?: Maybe<Scalars['Boolean']>;
};

export type FinancingCalculatorResponseProjection = {
  __typename?: 'FinancingCalculatorResponseProjection';
  calculatorTextFragmentFinancingPlanDetails?: Maybe<RetailFinancingPlanProjection>;
  financingConditions?: Maybe<FinancingConditionsProjection>;
  internalFinancingFeature?: Maybe<RetailFinancingPlanProjection>;
};

export type FinancingConditionsProjection = {
  __typename?: 'FinancingConditionsProjection';
  additionalFinancingFee?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Long']>;
  downpayment?: Maybe<Scalars['Long']>;
  effectiveInterestRate?: Maybe<Scalars['Float']>;
  financingAmount?: Maybe<Scalars['Long']>;
  financingPeriod?: Maybe<Scalars['Int']>;
  insuranceEffectiveRate?: Maybe<Scalars['Float']>;
  insuranceMonthlyInstalment?: Maybe<Scalars['Long']>;
  maxFinancingAmount?: Maybe<Scalars['Long']>;
  maxPeriod?: Maybe<Scalars['Int']>;
  minFinancingAmount?: Maybe<Scalars['Long']>;
  minPeriod?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['Long']>;
  monthlyInstalmentWithInsurance?: Maybe<Scalars['Long']>;
  nominalInterestRate?: Maybe<Scalars['Float']>;
  periodStep?: Maybe<Scalars['Int']>;
  totalCostOfCredit?: Maybe<Scalars['Long']>;
  totalCostOfInsurance?: Maybe<Scalars['Long']>;
  totalFinancingAmount?: Maybe<Scalars['Long']>;
  totalOwedAmount?: Maybe<Scalars['Long']>;
  totalOwedAmountWithInsurance?: Maybe<Scalars['Long']>;
};

export type FuelConsumptionDto = {
  __typename?: 'FuelConsumptionDTO';
  city?: Maybe<Scalars['BigDecimal']>;
  combined?: Maybe<Scalars['BigDecimal']>;
  highway?: Maybe<Scalars['BigDecimal']>;
};

export type FuelConsumptionDtoInput = {
  city?: InputMaybe<Scalars['BigDecimal']>;
  combined?: InputMaybe<Scalars['BigDecimal']>;
  highway?: InputMaybe<Scalars['BigDecimal']>;
};

export type GdprProjection = {
  __typename?: 'GDPRProjection';
  report?: Maybe<Scalars['Map_String_StringScalar']>;
};

export enum GpsType {
  BuiltIn = 'BUILT_IN',
  Mobile = 'MOBILE'
}

export type GqlRetailCarDocumentsService = {
  __typename?: 'GQLRetailCarDocumentsService';
  DOCUMENT_ITEM_UPDATE_NOT_PERMITTED?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_BRANCH_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_FIRST_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_LAST_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_NO_HOUSE_NUM_ADDRESS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_STATUS_NOT_OPEN_VALIDATION_CODE?: Maybe<Scalars['String']>;
  EMPTY_DELIVERED_ON_DATE_VALIDATION_CODE?: Maybe<Scalars['String']>;
  EMPTY_PICKEDUP_ON_DATE_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ITEMS_IN_MULTI_PACKAGES_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ITEMS_LOCATION_CONFLICT_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_ALREADY_CANCELED_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_ALREADY_DELIVERED_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_SAME_STATUS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ZERO_ITEMS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ad?: Maybe<AdProjection>;
  documentInventoryItemLocations?: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes?: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingProviders?: Maybe<ResultConnection_ShippingProviderProjection>;
};


export type GqlRetailCarDocumentsServiceAdArgs = {
  adId: Scalars['UUID'];
};


export type GqlRetailCarDocumentsServiceDocumentInventoryItemLocationsArgs = {
  filter: DocumentInventoryItemLocationFilterInput;
};


export type GqlRetailCarDocumentsServiceDocumentInventoryItemsArgs = {
  filter: DocumentInventoryItemsFilterInput;
};


export type GqlRetailCarDocumentsServiceDocumentInventoryPackageArgs = {
  id: Scalars['UUID'];
};


export type GqlRetailCarDocumentsServiceDocumentInventoryPackagesArgs = {
  filter?: InputMaybe<DocumentInventoryPackageFilterInput>;
};

export type GeneralDto = {
  __typename?: 'GeneralDTO';
  carStarts?: Maybe<Scalars['Boolean']>;
  cmrPhoto?: Maybe<Scalars['UUID']>;
  hasKey?: Maybe<Scalars['Boolean']>;
  keyBatteryWorks?: Maybe<Scalars['Boolean']>;
};

export type GeneralDtoInput = {
  carStarts?: InputMaybe<Scalars['Boolean']>;
  cmrPhoto?: InputMaybe<Scalars['UUID']>;
  hasKey?: InputMaybe<Scalars['Boolean']>;
  keyBatteryWorks?: InputMaybe<Scalars['Boolean']>;
};

export type GpsDtoInput = {
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type GroupCreateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type GroupProjection = {
  __typename?: 'GroupProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RoleProjection>>>;
  updatedOn?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<UserProjection>>>;
};

export type GroupUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type HandoverSubmissionResultProjection = {
  __typename?: 'HandoverSubmissionResultProjection';
  customerEmail?: Maybe<Scalars['String']>;
  pdfDownloadLink?: Maybe<Scalars['String']>;
};

export type HappinessCallTaskManagementProjection = {
  __typename?: 'HappinessCallTaskManagementProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderHandoverOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type HighlightDto = {
  __typename?: 'HighlightDTO';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HighlightProjectionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  translationKey?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum HistoryEventType {
  AttendedOutboundCall = 'ATTENDED_OUTBOUND_CALL',
  Branch = 'BRANCH',
  CarDescriptionUpdate = 'CAR_DESCRIPTION_UPDATE',
  CarDownpricing = 'CAR_DOWNPRICING',
  Chat = 'CHAT',
  ChatRequest = 'CHAT_REQUEST',
  Checkout = 'CHECKOUT',
  FailedOutboundCall = 'FAILED_OUTBOUND_CALL',
  ImportedInboundCall = 'IMPORTED_INBOUND_CALL',
  ImportedMissedCall = 'IMPORTED_MISSED_CALL',
  InboundEmail = 'INBOUND_EMAIL',
  InboundPhone = 'INBOUND_PHONE',
  MissedPhoneCall = 'MISSED_PHONE_CALL',
  Note = 'NOTE',
  OrderEmailOutgoing = 'ORDER_EMAIL_OUTGOING',
  OrderEmailReply = 'ORDER_EMAIL_REPLY',
  Other = 'OTHER',
  OutboundEmail = 'OUTBOUND_EMAIL',
  OutboundPhone = 'OUTBOUND_PHONE',
  Sms = 'SMS',
  SystemOutboundEmail = 'SYSTEM_OUTBOUND_EMAIL',
  Task = 'TASK',
  TestDriveRequest = 'TEST_DRIVE_REQUEST',
  UnattendedOutboundCall = 'UNATTENDED_OUTBOUND_CALL',
  Unknown = 'UNKNOWN'
}

export type HomeDeliveryOptionRequestDtoInput = {
  destinationCountry?: InputMaybe<Scalars['String']>;
  destinationZipcode?: InputMaybe<Scalars['String']>;
};

export type ImageDto = {
  __typename?: 'ImageDTO';
  cdnContainer?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageIdDto = {
  __typename?: 'ImageIdDTO';
  id?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageProjection = {
  __typename?: 'ImageProjection';
  cdnContainer?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageProjectionInput = {
  cdnContainer?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<Scalars['Int']>;
  part?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ImagesDtoInput = {
  certifiedCheque?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<Scalars['UUID']>;
  comments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  instantTransfer?: InputMaybe<Scalars['UUID']>;
  odometer?: InputMaybe<Scalars['UUID']>;
  overviewFront?: InputMaybe<Scalars['UUID']>;
  overviewRear?: InputMaybe<Scalars['UUID']>;
  secondaryWheels?: InputMaybe<Scalars['UUID']>;
  sideLeft?: InputMaybe<Scalars['UUID']>;
  sideRight?: InputMaybe<Scalars['UUID']>;
  signature?: InputMaybe<Scalars['UUID']>;
};

export type ImportCarleadProjectionInput = {
  carHash?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  skipPublish?: InputMaybe<Scalars['Boolean']>;
};

export type InboundOutboundPaymentRefDistributionProjection = {
  __typename?: 'InboundOutboundPaymentRefDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  inboundPayment?: Maybe<RetailPaymentProjection>;
  refundTempId?: Maybe<Scalars['Int']>;
};

export type InboundOutboundPaymentRefDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  inboundPayment?: InputMaybe<RetailPaymentProjectionInput>;
  refundTempId?: InputMaybe<Scalars['Int']>;
};

export type IntegerRangeDto = {
  __typename?: 'IntegerRangeDTO';
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type InteriorDto = {
  __typename?: 'InteriorDTO';
  backSeatsPhoto?: Maybe<Scalars['UUID']>;
  frontSeatsPhoto?: Maybe<Scalars['UUID']>;
  fuelLevel?: Maybe<Scalars['String']>;
  fuelLevelPhoto?: Maybe<Scalars['UUID']>;
  gps?: Maybe<Scalars['Boolean']>;
  gpsManufacturer?: Maybe<Scalars['String']>;
  gpsPhoto?: Maybe<Scalars['UUID']>;
  gpsType?: Maybe<GpsType>;
  hasFirstAidKit?: Maybe<Scalars['Boolean']>;
  hasFloorMat?: Maybe<Scalars['Boolean']>;
  hasSecondaryWheels?: Maybe<Scalars['Boolean']>;
  hasSecurityTriangle?: Maybe<Scalars['Boolean']>;
  hasSpareTire?: Maybe<Scalars['Boolean']>;
  hasTireFit?: Maybe<Scalars['Boolean']>;
  hasTrunkCover?: Maybe<Scalars['Boolean']>;
  hasWarningVest?: Maybe<Scalars['Boolean']>;
  interiorPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  isInteriorClean?: Maybe<Scalars['Boolean']>;
  millage?: Maybe<Scalars['Int']>;
  odometerPhoto?: Maybe<Scalars['UUID']>;
  radio?: Maybe<Scalars['Boolean']>;
  radioManufacturer?: Maybe<Scalars['String']>;
  radioPhoto?: Maybe<Scalars['UUID']>;
  secondaryWheelsPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  secondaryWheelsRimType?: Maybe<RimType>;
  secondaryWheelsTireType?: Maybe<TireType>;
  warningLights?: Maybe<WarningLightsDto>;
  warningLightsOn?: Maybe<Scalars['Boolean']>;
  warningLightsPhoto?: Maybe<Scalars['UUID']>;
};

export type InteriorDtoInput = {
  backSeatsPhoto?: InputMaybe<Scalars['UUID']>;
  frontSeatsPhoto?: InputMaybe<Scalars['UUID']>;
  fuelLevel?: InputMaybe<Scalars['String']>;
  fuelLevelPhoto?: InputMaybe<Scalars['UUID']>;
  gps?: InputMaybe<Scalars['Boolean']>;
  gpsManufacturer?: InputMaybe<Scalars['String']>;
  gpsPhoto?: InputMaybe<Scalars['UUID']>;
  gpsType?: InputMaybe<GpsType>;
  hasFirstAidKit?: InputMaybe<Scalars['Boolean']>;
  hasFloorMat?: InputMaybe<Scalars['Boolean']>;
  hasSecondaryWheels?: InputMaybe<Scalars['Boolean']>;
  hasSecurityTriangle?: InputMaybe<Scalars['Boolean']>;
  hasSpareTire?: InputMaybe<Scalars['Boolean']>;
  hasTireFit?: InputMaybe<Scalars['Boolean']>;
  hasTrunkCover?: InputMaybe<Scalars['Boolean']>;
  hasWarningVest?: InputMaybe<Scalars['Boolean']>;
  interiorPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  isInteriorClean?: InputMaybe<Scalars['Boolean']>;
  millage?: InputMaybe<Scalars['Int']>;
  odometerPhoto?: InputMaybe<Scalars['UUID']>;
  radio?: InputMaybe<Scalars['Boolean']>;
  radioManufacturer?: InputMaybe<Scalars['String']>;
  radioPhoto?: InputMaybe<Scalars['UUID']>;
  secondaryWheelsPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  secondaryWheelsRimType?: InputMaybe<RimType>;
  secondaryWheelsTireType?: InputMaybe<TireType>;
  warningLights?: InputMaybe<WarningLightsDtoInput>;
  warningLightsOn?: InputMaybe<Scalars['Boolean']>;
  warningLightsPhoto?: InputMaybe<Scalars['UUID']>;
};

export type InternalPaymentOptionsProjection = {
  __typename?: 'InternalPaymentOptionsProjection';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InventorySourcingImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type InvoiceCreditNoteCreateProjectionInput = {
  creditNoteNumber?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  proformaNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InvoiceState>;
  totalPriceNetMinorUnits?: InputMaybe<Scalars['Long']>;
};

export enum InvoiceItemExternalType {
  Ad = 'AD',
  AdSecondaryWheels = 'AD_SECONDARY_WHEELS',
  Delivery = 'DELIVERY',
  DownPayment = 'DOWN_PAYMENT',
  Product = 'PRODUCT',
  Registration = 'REGISTRATION',
  VehiclePreparation = 'VEHICLE_PREPARATION',
  Warranty = 'WARRANTY'
}

export type InvoiceItemProjection = {
  __typename?: 'InvoiceItemProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  discountGross?: Maybe<PriceDto>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<InvoiceItemExternalType>;
  id?: Maybe<Scalars['UUID']>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  taxAdvantageAmountMinorUnits?: Maybe<Scalars['Long']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type InvoiceProjection = {
  __typename?: 'InvoiceProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteNumber?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoicedOn?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<InvoiceItemProjection>>>;
  number?: Maybe<Scalars['String']>;
  oracleAccountingDate?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  oracleGrossAmount?: Maybe<PriceDto>;
  oracleIssueDate?: Maybe<Scalars['String']>;
  oracleTransactionalNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  paidOn?: Maybe<Scalars['String']>;
  proformaNumber?: Maybe<Scalars['String']>;
  state?: Maybe<InvoiceState>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceGrossMinorUnits?: Maybe<Scalars['Long']>;
  totalPriceNet?: Maybe<PriceDto>;
  totalPriceNetMinorUnits?: Maybe<Scalars['Long']>;
  totalVatAmount?: Maybe<PriceDto>;
  totalVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  type?: Maybe<InvoiceType>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
};

export enum InvoiceState {
  /** @deprecated Deprecated */
  Cancelled = 'CANCELLED',
  FullyCredited = 'FULLY_CREDITED',
  /** @deprecated Deprecated */
  Open = 'OPEN',
  Paid = 'PAID',
  PartiallyCredited = 'PARTIALLY_CREDITED',
  /** @deprecated Deprecated */
  PartiallyPaid = 'PARTIALLY_PAID',
  /** @deprecated Deprecated */
  Refund = 'REFUND'
}

export enum InvoiceType {
  /** @deprecated Deprecated */
  CreditNote = 'CREDIT_NOTE',
  DownPayment = 'DOWN_PAYMENT',
  Standard = 'STANDARD'
}

export type InvoiceUpdateProjectionInput = {
  creditNoteNumber?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  proformaNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InvoiceState>;
};

export type JsonObject = {
  __typename?: 'JSONObject';
  NULL?: Maybe<Scalars['ObjectScalar']>;
  empty: Scalars['Boolean'];
  null: Scalars['Boolean'];
};


export type JsonObjectNullArgs = {
  arg0?: InputMaybe<Scalars['String']>;
};

export type LatLngInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type LinkProjection = {
  __typename?: 'LinkProjection';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ListContainerDto_AuxUserDto = {
  __typename?: 'ListContainerDTO_AuxUserDTO';
  entities?: Maybe<Array<Maybe<AuxUserDto>>>;
};

export type ListContainerDto_GroupProjection = {
  __typename?: 'ListContainerDTO_GroupProjection';
  entities?: Maybe<Array<Maybe<GroupProjection>>>;
};

export type ListContainerDto_OrderItemsRefundsDto = {
  __typename?: 'ListContainerDTO_OrderItemsRefundsDTO';
  entities?: Maybe<Array<Maybe<OrderItemsRefundsDto>>>;
};

export type ListContainerDto_RetailYouSignSignatureRequestProjection = {
  __typename?: 'ListContainerDTO_RetailYouSignSignatureRequestProjection';
  entities?: Maybe<Array<Maybe<RetailYouSignSignatureRequestProjection>>>;
};

export type Location = {
  __typename?: 'Location';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type LocationDetailDto = {
  __typename?: 'LocationDetailDTO';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationInfoDto = {
  __typename?: 'LocationInfoDTO';
  currentLocation?: Maybe<LocationDetailDto>;
  documentLocation?: Maybe<LocationDetailDto>;
  routeEstimatedArrivalDate?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['Long']>;
  routeStatus?: Maybe<RouteStatus>;
  sourceLocation?: Maybe<LocationDetailDto>;
};

export type LocationInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type LogProjectionInput = {
  details?: InputMaybe<Scalars['Map_String_StringScalar']>;
  eventSource?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
};

export type MileageDto = {
  __typename?: 'MileageDTO';
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnit>;
};

export type MileageDtoInput = {
  distance?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<MileageUnit>;
};

export enum MileageUnit {
  Km = 'KM',
  Miles = 'MILES'
}

export type ModelDto = {
  __typename?: 'ModelDTO';
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  subModel?: Maybe<Scalars['String']>;
};

export type MonthlyNetIncomeProjection = {
  __typename?: 'MonthlyNetIncomeProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  month?: Maybe<Scalars['String']>;
};

export type MonthlyNetIncomeProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  month?: InputMaybe<Scalars['String']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addGroupUsers: Scalars['Boolean'];
  addRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  addUserGroups: Scalars['Boolean'];
  addUserProperties: Scalars['Boolean'];
  addpublishingblockers: Scalars['Boolean'];
  applyPriceUpdate: Scalars['Boolean'];
  approveOrderFinancing: Scalars['Boolean'];
  assignGroupRoles: Scalars['Boolean'];
  assignGroupUsers: Scalars['Boolean'];
  assignRoleGroups: Scalars['Boolean'];
  assignTaskManagerTask: Scalars['Boolean'];
  assignTestDataAccessParam: Scalars['Boolean'];
  assignUnidentifiedPaymentToOrder?: Maybe<RetailPaymentProjection>;
  assignUserCountryAccessParams: Scalars['Boolean'];
  assignUserGroupAccessParams: Scalars['Boolean'];
  cancelOrderFinancing: Scalars['Boolean'];
  cancelRetailPayment: Scalars['Boolean'];
  carRegisterOnline?: Maybe<DigitalCarRegistrationStatusProjection>;
  carRegistrationActions: Scalars['Boolean'];
  changeCustomerType?: Maybe<RetailCustomerProjection>;
  changeDocumentPackageStatus?: Maybe<ChangeDocumentPackageStatusResult>;
  changeRegistrationStatus: Scalars['Boolean'];
  claimPartialRefundRequestNewRetailBankDetails: Scalars['Boolean'];
  completeTaskManagerTask: Scalars['Boolean'];
  createAbTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  createAdditionalProofRequests?: Maybe<Array<Maybe<RetailClaimProofRequestProjection>>>;
  createAttachmentUploadPresignedUrl?: Maybe<AttachmentUploadPresignedUrlProjection>;
  createBranchEntryCheck?: Maybe<BranchEntryCheckSubmissionResultProjection>;
  createBranchEntryCheckImageUploadMetadata?: Maybe<AwsImageUploadResponseProjection>;
  createCreditNotes?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  createCustomerAddress?: Maybe<CustomerAddressProjection>;
  createCustomerUser?: Maybe<CustomerProjection>;
  createCustomerUserPasswordless?: Maybe<CustomerProjection>;
  createDigitalHandoverProtocol?: Maybe<HandoverSubmissionResultProjection>;
  createDiscount?: Maybe<DiscountDto>;
  createDistributedCreditNotesRefunds?: Maybe<Scalars['Boolean']>;
  createDistributedRefunds?: Maybe<Scalars['Boolean']>;
  createDocument?: Maybe<RetailDocumentProjection>;
  createDocumentInventoryType?: Maybe<CreateDocumentInventoryTypePayload>;
  createDocumentPackage?: Maybe<CreateDocumentPackageResult>;
  createExternalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  createGroup?: Maybe<GroupProjection>;
  createImageTag?: Maybe<RetailImageTagProjection>;
  createInvoiceCreditNote?: Maybe<InvoiceProjection>;
  createInvoiceDownPayment?: Maybe<InvoiceProjection>;
  createInvoiceStandard?: Maybe<InvoiceProjection>;
  createInvoiceStandardWithValidation?: Maybe<InvoiceProjection>;
  createOrUpdateExternalFinancingOptions?: Maybe<ExternalFinancingOptionsProjection>;
  createOracleDownPaymentInvoice: Scalars['Boolean'];
  createOracleStandardInvoice: Scalars['Boolean'];
  createOrder?: Maybe<OrderProjection>;
  createOrderAddress?: Maybe<OrderAddressProjection>;
  createOrderBranchCarCheckupTask: Scalars['Boolean'];
  createOrderBranchCarCheckupTaskV2: Scalars['Boolean'];
  createOrderCancellationRequestedTask: Scalars['Boolean'];
  createOrderCancellationRequestedTaskV2: Scalars['Boolean'];
  createOrderCancellationTask: Scalars['Boolean'];
  createOrderCancellationTaskV2: Scalars['Boolean'];
  createOrderCarReadyForHomeDeliveryTask: Scalars['Boolean'];
  createOrderCarReadyForHomeDeliveryTaskV2: Scalars['Boolean'];
  createOrderCarReadyForPickupCallTask: Scalars['Boolean'];
  createOrderCarReadyForPickupCallTaskV2: Scalars['Boolean'];
  createOrderCarRegistrationDocumentsReturnedToAHTask: Scalars['Boolean'];
  createOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars['Boolean'];
  createOrderCarReturnedToAHTask: Scalars['Boolean'];
  createOrderCarReturnedToAHTaskV2: Scalars['Boolean'];
  createOrderCareCallTask: Scalars['Boolean'];
  createOrderCareCallTaskV2: Scalars['Boolean'];
  createOrderClaimClosedTask: Scalars['Boolean'];
  createOrderClaimClosedTaskV2: Scalars['Boolean'];
  createOrderClaimReceivedTask: Scalars['Boolean'];
  createOrderClaimReceivedTaskV2: Scalars['Boolean'];
  createOrderComment?: Maybe<OrderCommentProjection>;
  createOrderCustomerAvailabilityForHomeDeliveryTask: Scalars['Boolean'];
  createOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars['Boolean'];
  createOrderCustomerSignedBankReleaseTask: Scalars['Boolean'];
  createOrderCustomerSignedBankReleaseTaskV2: Scalars['Boolean'];
  createOrderDocumentsRegistrationReadyTask: Scalars['Boolean'];
  createOrderDocumentsRegistrationReadyTaskV2: Scalars['Boolean'];
  createOrderDocumentsRequestedTask: Scalars['Boolean'];
  createOrderDocumentsRequestedTaskV2: Scalars['Boolean'];
  createOrderFinalDocumentsReceivedTask: Scalars['Boolean'];
  createOrderFinalDocumentsReceivedTaskV2: Scalars['Boolean'];
  createOrderHandoverAppointmentTask: Scalars['Boolean'];
  createOrderHandoverAppointmentTaskV2: Scalars['Boolean'];
  createOrderHappinessCallTask: Scalars['Boolean'];
  createOrderHappinessCallTaskV2: Scalars['Boolean'];
  createOrderHistoryEmailEvent?: Maybe<OrderHistoryEventProjection>;
  createOrderHistoryEvent?: Maybe<OrderHistoryEventProjection>;
  createOrderItem?: Maybe<RetailOrderItemDto>;
  createOrderRegistrationInitiatedTask: Scalars['Boolean'];
  createOrderRegistrationInitiatedTaskV2: Scalars['Boolean'];
  createOrderShippingAddressBody?: Maybe<RetailAddressProjection>;
  createOrderUploadedHandoverDocumentTask: Scalars['Boolean'];
  createOrderUploadedHandoverDocumentTaskV2: Scalars['Boolean'];
  createOrderWarrantyContractSentTask: Scalars['Boolean'];
  createOrderWarrantyContractSentTaskV2: Scalars['Boolean'];
  createOrderWarrantyContractSignedTask: Scalars['Boolean'];
  createOrderWarrantyContractSignedTaskV2: Scalars['Boolean'];
  createOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
  createPnlItem?: Maybe<PnlItemProjection>;
  createPnlItemType?: Maybe<PnlItemTypeProjection>;
  createPnlPartner?: Maybe<PnlPartnerProjection>;
  createProduct?: Maybe<RetailProductProjection>;
  createRefund?: Maybe<RetailRefundProjection>;
  createRefundsWithCreditNotesDistribution?: Maybe<Scalars['Boolean']>;
  createRefurbishment?: Maybe<RefurbishmentDto>;
  createRefurbishmentComment?: Maybe<RefurbishmentCommentProjection>;
  createRefurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  createRefurbishmentServicesOrder?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  createRetailAccountData?: Maybe<AccountDataDto>;
  createRetailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  createRetailAddress?: Maybe<RetailAddressProjection>;
  createRetailCheckout?: Maybe<RetailCheckoutProjection>;
  createRetailClaimCost?: Maybe<RetailClaimCostProjection>;
  createRetailClaimCostsComment?: Maybe<RetailClaimCostCommentProjection>;
  createRetailClaimWorkshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
  createRetailCustomer?: Maybe<RetailCustomerProjection>;
  createRetailFeature?: Maybe<RetailFeatureProjection>;
  createRetailLead?: Maybe<RetailLeadProjection>;
  createRetailOrderAccountDetails?: Maybe<RetailOrderAccountDetailsProjection>;
  createRetailOrderClaims?: Maybe<Array<Maybe<RetailClaimProjection>>>;
  createRetailOrderSubClaim?: Maybe<RetailSubClaimProjection>;
  createRetailPayment?: Maybe<RetailPaymentProjection>;
  createRetailSubClaimComment?: Maybe<RetailSubClaimCommentProjection>;
  createRetailSubClaimDocument?: Maybe<RetailSubClaimDocumentProjection>;
  createRole?: Maybe<RoleProjection>;
  createSellerAddress?: Maybe<SellerAddressProjection>;
  createTradeIn?: Maybe<RetailTradeInProjection>;
  createUser?: Maybe<AuxUserDto>;
  deleteAbTestingExperiment: Scalars['Boolean'];
  deleteAbTestingExperimentCounters: Scalars['Boolean'];
  deleteDocumentInventoryItem?: Maybe<DeleteDocumentInventoryItemResult>;
  deleteGroupUsers: Scalars['Boolean'];
  deleteImageTag: Scalars['Boolean'];
  deleteOrderAddress: Scalars['Boolean'];
  deleteOrderBranchCarCheckupTask: Scalars['Boolean'];
  deleteOrderBranchCarCheckupTaskV2: Scalars['Boolean'];
  deleteOrderCancellationRequestedTask: Scalars['Boolean'];
  deleteOrderCancellationRequestedTaskV2: Scalars['Boolean'];
  deleteOrderCarReadyForHomeDeliveryTask: Scalars['Boolean'];
  deleteOrderCarReadyForHomeDeliveryTaskV2: Scalars['Boolean'];
  deleteOrderCarReadyForPickupCallTask: Scalars['Boolean'];
  deleteOrderCarReadyForPickupCallTaskV2: Scalars['Boolean'];
  deleteOrderCarRegistrationDocumentsReturnedToAHTask: Scalars['Boolean'];
  deleteOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars['Boolean'];
  deleteOrderCarReturnedToAHTask: Scalars['Boolean'];
  deleteOrderCarReturnedToAHTaskV2: Scalars['Boolean'];
  deleteOrderCareCallTask: Scalars['Boolean'];
  deleteOrderCareCallTaskV2: Scalars['Boolean'];
  deleteOrderClaimClosedTask: Scalars['Boolean'];
  deleteOrderClaimClosedTaskV2: Scalars['Boolean'];
  deleteOrderClaimReceivedTask: Scalars['Boolean'];
  deleteOrderClaimReceivedTaskV2: Scalars['Boolean'];
  deleteOrderCustomerAvailabilityForHomeDeliveryTask: Scalars['Boolean'];
  deleteOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars['Boolean'];
  deleteOrderCustomerSignedBankReleaseTask: Scalars['Boolean'];
  deleteOrderCustomerSignedBankReleaseTaskV2: Scalars['Boolean'];
  deleteOrderDocumentsRegistrationReadyTask: Scalars['Boolean'];
  deleteOrderDocumentsRegistrationReadyTaskV2: Scalars['Boolean'];
  deleteOrderDocumentsRequestedTask: Scalars['Boolean'];
  deleteOrderDocumentsRequestedTaskV2: Scalars['Boolean'];
  deleteOrderFinalDocumentsReceivedTask: Scalars['Boolean'];
  deleteOrderFinalDocumentsReceivedTaskV2: Scalars['Boolean'];
  deleteOrderHandoverAppointmentTask: Scalars['Boolean'];
  deleteOrderHandoverAppointmentTaskV2: Scalars['Boolean'];
  deleteOrderHappinessCallTask: Scalars['Boolean'];
  deleteOrderHappinessCallTaskV2: Scalars['Boolean'];
  deleteOrderItem: Scalars['Boolean'];
  deleteOrderRegistrationInitiatedTask: Scalars['Boolean'];
  deleteOrderRegistrationInitiatedTaskV2: Scalars['Boolean'];
  deleteOrderUploadedHandoverDocumentTask: Scalars['Boolean'];
  deleteOrderUploadedHandoverDocumentTaskV2: Scalars['Boolean'];
  deleteOrderWarrantyContractSentTask: Scalars['Boolean'];
  deleteOrderWarrantyContractSentTaskV2: Scalars['Boolean'];
  deleteOrderWarrantyContractSignedTask: Scalars['Boolean'];
  deleteOrderWarrantyContractSignedTaskV2: Scalars['Boolean'];
  deletePnlItem: Scalars['Boolean'];
  deletePnlItemType: Scalars['Boolean'];
  deletePnlPartner: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  deleteRefurbishmentComment: Scalars['Boolean'];
  deleteRefurbishmentServices: Scalars['Boolean'];
  deleteRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  deleteRetailAddress: Scalars['Boolean'];
  deleteRetailClaimCost: Scalars['Boolean'];
  deleteRetailCustomer: Scalars['Boolean'];
  deleteRetailFeature?: Maybe<RetailFeatureProjection>;
  deleteRetailSubClaim: Scalars['Boolean'];
  deleteRetailSubClaimDocument: Scalars['Boolean'];
  deleteUserGroups: Scalars['Boolean'];
  deleteUserProperties: Scalars['Boolean'];
  disableDocumentInventoryType?: Maybe<DisableDocumentInventoryTypePayload>;
  disableOrderAutoCancellation: Scalars['Boolean'];
  distributeRetailClaimCosts: Scalars['Boolean'];
  enableDocumentInventoryType?: Maybe<EnableDocumentInventoryTypePayload>;
  importCarRegistrationActionItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importCarlead?: Maybe<AdProjection>;
  importInventorySourcingItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importPriceUpdateItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importProfitAndLossItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailDocumentInventoryItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailPaymentItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  markRetailReady: Scalars['Boolean'];
  markUnidentifiedPaymentAsInternalTransfer?: Maybe<RetailInternalTransferPaymentProjection>;
  moveOrderToCanceled: Scalars['Boolean'];
  moveOrderToCompleted: Scalars['Boolean'];
  moveOrderToContractSent: Scalars['Boolean'];
  moveOrderToContractSigned: Scalars['Boolean'];
  moveOrderToDelivered: Scalars['Boolean'];
  moveOrderToVerified: Scalars['Boolean'];
  moveRetailAdToImportedToRetail: Scalars['Boolean'];
  moveRetailAdToReturnToAuto1: Scalars['Boolean'];
  notificationVisualized: Scalars['Boolean'];
  patchDocument?: Maybe<RetailDocumentProjection>;
  persistOrderFinancingFlowType: Scalars['Boolean'];
  pinOrderComment: Scalars['Boolean'];
  pinOrderHistoryEvent: Scalars['Boolean'];
  preApproveOrderFinancing: Scalars['Boolean'];
  rejectOrderFinancing: Scalars['Boolean'];
  removeAdPublishingBlockers: Scalars['Boolean'];
  requestBankDetailsForOutboundPayment: Scalars['Boolean'];
  resetOrderHandoverDate: Scalars['Boolean'];
  resetUserPassword: Scalars['Boolean'];
  retailDocumentPackageUpdateImport?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  retryRefund: Scalars['Boolean'];
  revertLastOrderTransition: Scalars['Boolean'];
  saveFinanceRiskEvaluation?: Maybe<FinanceRiskEvaluationProjection>;
  saveVisitingLog: Scalars['Boolean'];
  selfAssignTaskManagerTask: Scalars['Boolean'];
  setHighlights?: Maybe<Array<Maybe<HighlightDto>>>;
  setRetailClaimCostApprove1?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove2?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove3?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostCustomerDecision?: Maybe<RetailClaimCostProjection>;
  startFullManualEvaluation: Scalars['Boolean'];
  startFullManualEvaluationProtected: Scalars['Boolean'];
  startFullManualEvaluationProtectedV2: Scalars['Boolean'];
  startFullManualEvaluationV2: Scalars['Boolean'];
  transitionPaymentUnidentifiedToInternalTransfer?: Maybe<RetailInternalTransferPaymentProjection>;
  unAssignTaskManagerTask: Scalars['Boolean'];
  updateAbTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  updateAd: Scalars['Boolean'];
  updateAdImages: Scalars['Boolean'];
  updateAdVehicle: Scalars['Boolean'];
  updateBranchEntryCheckStatus?: Maybe<Scalars['Boolean']>;
  updateCheckoutPaymentDetails?: Maybe<CheckoutPaymentDetailsProjection>;
  updateClaimCostCompensation: Scalars['Boolean'];
  updateCreditNote?: Maybe<CreditNoteProjection>;
  updateCustomerAddress?: Maybe<CustomerAddressProjection>;
  updateCustomerDisplayOnDamage?: Maybe<RetailAdDamageDto>;
  updateCustomerExpectedMaxETA?: Maybe<OrderProjection>;
  updateCustomerUser?: Maybe<CustomerProjection>;
  updateDigitalCarRegistration?: Maybe<DigitalCarRegistrationProjection>;
  updateDocumentPackage?: Maybe<UpdateDocumentPackageResult>;
  updateDocumentPackagePartial?: Maybe<UpdateDocumentPackagePartialResult>;
  updateExportSection: Scalars['Boolean'];
  updateExternalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  updateGroup?: Maybe<GroupProjection>;
  updateImageTag?: Maybe<RetailImageTagProjection>;
  updateInvoice?: Maybe<InvoiceProjection>;
  updateMobiledeImages: Scalars['Boolean'];
  updateOrder?: Maybe<OrderProjection>;
  updateOrderAddress?: Maybe<OrderAddressProjection>;
  updateOrderBillingAddress?: Maybe<OrderAddressProjection>;
  updateOrderBranch: Scalars['Boolean'];
  updateOrderCarRegistration?: Maybe<OrderProjection>;
  updateOrderCarRegistrationAddress?: Maybe<OrderAddressProjection>;
  updateOrderDeliveryOption: Scalars['Boolean'];
  updateOrderDeliveryPlannedCarHandoverDate: Scalars['Boolean'];
  updateOrderDeliveryType: Scalars['Boolean'];
  updateOrderDocumentHandoverOn?: Maybe<OrderProjection>;
  updateOrderDocumentShippingAddress?: Maybe<OrderAddressProjection>;
  updateOrderFinancing: Scalars['Boolean'];
  updateOrderIsBranchCheckupSuccessful?: Maybe<OrderProjection>;
  updateOrderIsTest?: Maybe<OrderProjection>;
  updateOrderItemDiscountGross: Scalars['Boolean'];
  updateOrderItemPriceGross: Scalars['Boolean'];
  updateOrderPaymentDetails?: Maybe<OrderProjection>;
  updateOrderShippingAddress?: Maybe<OrderAddressProjection>;
  updateOrderShippingAddressForce?: Maybe<OrderAddressProjection>;
  updateOrderVehicleDeliveredOn?: Maybe<OrderProjection>;
  updateOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
  updatePnlItem?: Maybe<PnlItemProjection>;
  updateProduct?: Maybe<RetailProductProjection>;
  updateRefund?: Maybe<RetailRefundProjection>;
  updateRefundAmount: Scalars['Boolean'];
  updateRefurbishment?: Maybe<RefurbishmentDto>;
  updateRefurbishmentComment?: Maybe<RefurbishmentCommentProjection>;
  updateRefurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  updateRefurbishmentState?: Maybe<RefurbishmentDto>;
  updateRetailAd?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1BuyPrice?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnOn?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnReason?: Maybe<RetailAdProjection>;
  updateRetailAdAutoheroPurchaseOn?: Maybe<RetailAdProjection>;
  updateRetailAdDamageDisplayToCustomerFrontend?: Maybe<Scalars['Boolean']>;
  updateRetailAdFirstImportOn?: Maybe<RetailAdProjection>;
  updateRetailAdHidingReason?: Maybe<RetailAdProjection>;
  updateRetailAdIsTest?: Maybe<RetailAdProjection>;
  updateRetailAdRetailReadyDate?: Maybe<RetailAdProjection>;
  updateRetailAdSecondaryWheelsDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsRimsDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsTiresDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAddress?: Maybe<RetailAddressProjection>;
  updateRetailCheckout?: Maybe<RetailCheckoutProjection>;
  updateRetailCheckoutByOrderId?: Maybe<RetailCheckoutProjection>;
  updateRetailClaimCompensationType: Scalars['Boolean'];
  updateRetailClaimCost?: Maybe<RetailClaimCostProjection>;
  updateRetailClaimWorkshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
  updateRetailCustomer?: Maybe<RetailCustomerProjection>;
  updateRetailFeature?: Maybe<RetailFeatureProjection>;
  updateRetailLead?: Maybe<RetailLeadProjection>;
  updateRetailOrderClaimAssigneeUser: Scalars['Boolean'];
  updateRetailOrderClaimGroup: Scalars['Boolean'];
  updateRetailOrderClaimState?: Maybe<RetailClaimProjection>;
  updateRetailOrderSubClaim?: Maybe<RetailSubClaimProjection>;
  updateRetailPayment?: Maybe<RetailPaymentProjection>;
  updateRetailShippingAddressForce?: Maybe<RetailAddressProjection>;
  updateRetailTaxNoVA: Scalars['Boolean'];
  updateRetailUnidentifiedPayment?: Maybe<RetailUnidentifiedPaymentProjection>;
  updateRole?: Maybe<RoleProjection>;
  updateSellerAddress?: Maybe<SellerAddressProjection>;
  updateTaxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  updateTestOrderDownPayment?: Maybe<OrderProjection>;
  updateUser?: Maybe<UserProjection>;
  userObfuscation?: Maybe<GdprProjection>;
};


/** Mutation root */
export type MutationAddGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAddRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationAddUserGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddUserPropertiesArgs = {
  id: Scalars['UUID'];
  properties: Array<InputMaybe<UserPropertyProjectionInput>>;
};


/** Mutation root */
export type MutationAddpublishingblockersArgs = {
  publishingBlockers: Array<InputMaybe<PublishingBlockerUpdateProjectionInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationApplyPriceUpdateArgs = {
  request: ApplyPriceUpdateProjectionInput;
};


/** Mutation root */
export type MutationApproveOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignGroupRolesArgs = {
  id: Scalars['UUID'];
  roleIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssignGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssignRoleGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignTestDataAccessParamArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignUnidentifiedPaymentToOrderArgs = {
  orderId?: InputMaybe<Scalars['UUID']>;
  unidentifiedPaymentId?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssignUserCountryAccessParamsArgs = {
  countries: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignUserGroupAccessParamsArgs = {
  groupIds: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCancelOrderFinancingArgs = {
  orderId: Scalars['UUID'];
  reason: RetailOrderFinanceCancelReason;
};


/** Mutation root */
export type MutationCancelRetailPaymentArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCarRegisterOnlineArgs = {
  registration?: InputMaybe<DigitalCarRegistrationRequestProjectionInput>;
};


/** Mutation root */
export type MutationCarRegistrationActionsArgs = {
  action: Scalars['String'];
  orderUuid: Scalars['UUID'];
};


/** Mutation root */
export type MutationChangeCustomerTypeArgs = {
  customer: RetailCustomerCreateProjectionInput;
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationChangeDocumentPackageStatusArgs = {
  input: ChangeDocumentPackageStatusInput;
};


/** Mutation root */
export type MutationChangeRegistrationStatusArgs = {
  registrationId: Scalars['UUID'];
  registrationStatus: Scalars['String'];
};


/** Mutation root */
export type MutationClaimPartialRefundRequestNewRetailBankDetailsArgs = {
  claimPartialRefundId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCompleteTaskManagerTaskArgs = {
  description?: InputMaybe<Scalars['String']>;
  taskId: Scalars['UUID'];
  variables?: InputMaybe<Scalars['Map_String_StringScalar']>;
};


/** Mutation root */
export type MutationCreateAbTestingExperimentArgs = {
  input: AbTestingExperimentInputInput;
};


/** Mutation root */
export type MutationCreateAdditionalProofRequestsArgs = {
  claimId: Scalars['UUID'];
  proofRequestsCreateProjection: ProofRequestsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateAttachmentUploadPresignedUrlArgs = {
  attachmentFilename: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateBranchEntryCheckArgs = {
  stockNumber: Scalars['String'];
  submission: BranchEntrySubmissionDtoInput;
};


/** Mutation root */
export type MutationCreateBranchEntryCheckImageUploadMetadataArgs = {
  uploadRequest: AwsImageUploadRequestProjectionInput;
};


/** Mutation root */
export type MutationCreateCreditNotesArgs = {
  creditNote?: InputMaybe<CreditNoteCreateProjectionInput>;
};


/** Mutation root */
export type MutationCreateCustomerAddressArgs = {
  address: CustomerAddressCreateProjectionInput;
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateCustomerUserArgs = {
  user: CustomerUserCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateCustomerUserPasswordlessArgs = {
  user: UserPasswordlessProjectionInput;
};


/** Mutation root */
export type MutationCreateDigitalHandoverProtocolArgs = {
  orderId: Scalars['UUID'];
  submission: ProtocolSubmissionDtoInput;
};


/** Mutation root */
export type MutationCreateDiscountArgs = {
  discount: DiscountCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateDistributedCreditNotesRefundsArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateDistributedRefundsArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateDocumentArgs = {
  retailCreateDocumentProjection: RetailCreateDocumentProjectionInput;
};


/** Mutation root */
export type MutationCreateDocumentInventoryTypeArgs = {
  input: CreateDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationCreateDocumentPackageArgs = {
  input: CreateDocumentPackageInput;
};


/** Mutation root */
export type MutationCreateExternalFinancingEvaluationArgs = {
  financeRiskEvaluation: CreateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateGroupArgs = {
  group: GroupCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateImageTagArgs = {
  imageTag: RetailImageTagCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceCreditNoteArgs = {
  creditNote: InvoiceCreditNoteCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceDownPaymentArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceStandardArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceStandardWithValidationArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
  validatePayments?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCreateOrUpdateExternalFinancingOptionsArgs = {
  externalFinancingOptions: CreateOrUpdateExternalFinancingOptionsProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOracleDownPaymentInvoiceArgs = {
  orderId: Scalars['UUID'];
  vatKey: Scalars['String'];
};


/** Mutation root */
export type MutationCreateOracleStandardInvoiceArgs = {
  orderId: Scalars['UUID'];
  vatKey: Scalars['String'];
};


/** Mutation root */
export type MutationCreateOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateOrderAddressArgs = {
  address: OrderAddressCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderBranchCarCheckupTaskArgs = {
  branchCheckupOn: Scalars['String'];
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderBranchCarCheckupTaskV2Args = {
  branchCheckupOn: Scalars['String'];
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationTaskArgs = {
  comment: OrderCancellationTaskProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationTaskV2Args = {
  comment: OrderCancellationTaskProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForPickupCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForPickupCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCareCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCareCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimClosedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimClosedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCommentArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerSignedBankReleaseTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerSignedBankReleaseTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRegistrationReadyTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRegistrationReadyTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderFinalDocumentsReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  finalDocumentHandoverOn: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderFinalDocumentsReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  finalDocumentHandoverOn: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHandoverAppointmentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
  plannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateOrderHandoverAppointmentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
  plannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateOrderHappinessCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHappinessCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHistoryEmailEventArgs = {
  emailEvent: CreateOrderHistoryEmailEventProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHistoryEventArgs = {
  event: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderItemArgs = {
  item: OrderItemCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderRegistrationInitiatedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderRegistrationInitiatedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderShippingAddressBodyArgs = {
  address: RetailAddressCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderUploadedHandoverDocumentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderUploadedHandoverDocumentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSignedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSignedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOutboundPaymentArgs = {
  payment: CreateRetailOutboundPaymentProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlItemArgs = {
  item: PnlItemCreateProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlItemTypeArgs = {
  pnlItemType: PnlItemTypeCreateProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlPartnerArgs = {
  partner: PnlPartnerCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateProductArgs = {
  product: ProductCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefundArgs = {
  refund: RetailRefundCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefundsWithCreditNotesDistributionArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentArgs = {
  refurbishment: RefurbishmentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentCommentArgs = {
  refurbishmentComment: RefurbishmentCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentServicesArgs = {
  refurbishmentId: Scalars['UUID'];
  services: Array<InputMaybe<RefurbishmentServiceCreateProjectionInput>>;
};


/** Mutation root */
export type MutationCreateRefurbishmentServicesOrderArgs = {
  adId: Scalars['UUID'];
  noRepairNeededDamages: Array<InputMaybe<Scalars['UUID']>>;
  refurbishmentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailAccountDataArgs = {
  accountDetails: AccountDetailsDtoInput;
};


/** Mutation root */
export type MutationCreateRetailAdSecondaryWheelsArgs = {
  id: Scalars['UUID'];
  retailAdSecondaryWheels: RetailAdSecondaryWheelsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailAddressArgs = {
  address: RetailAddressCreateProjectionInput;
  validate?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCreateRetailCheckoutArgs = {
  checkout: RetailCheckoutCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailClaimCostArgs = {
  claimCost: RetailClaimCostCreateProjectionInput;
  claimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailClaimCostsCommentArgs = {
  claimCostComment: RetailClaimCostCommentCreateProjectionInput;
  claimCostId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailClaimWorkshopDetailsArgs = {
  claimId: Scalars['UUID'];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailCustomerArgs = {
  customer: RetailCustomerCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailFeatureArgs = {
  feature: CreateRetailFeatureProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailLeadArgs = {
  retailLead: RetailLeadCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailOrderAccountDetailsArgs = {
  accountDetails: RetailOrderAccountDetailsCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailOrderClaimsArgs = {
  orderId: Scalars['UUID'];
  retailClaims: BatchProjection_RetailClaimCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailOrderSubClaimArgs = {
  claimId: Scalars['UUID'];
  retailClaim: RetailSubClaimCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailPaymentArgs = {
  payment: RetailPaymentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailSubClaimCommentArgs = {
  retailSubClaimComment: RetailSubClaimCommentCreateProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailSubClaimDocumentArgs = {
  retailSubClaimDocument: RetailSubClaimCreateDocumentProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRoleArgs = {
  role: RoleCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateSellerAddressArgs = {
  sellerAddress: SellerAddressCreateProjectionInput;
  sellerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateTradeInArgs = {
  create: RetailTradeInCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateUserArgs = {
  user: UserCreateProjectionInput;
};


/** Mutation root */
export type MutationDeleteAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteAbTestingExperimentCountersArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteDocumentInventoryItemArgs = {
  input: DeleteDocumentInventoryItemInput;
};


/** Mutation root */
export type MutationDeleteGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationDeleteImageTagArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderAddressArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderBranchCarCheckupTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderBranchCarCheckupTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCancellationRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCancellationRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForPickupCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForPickupCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCareCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCareCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimClosedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimClosedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerSignedBankReleaseTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerSignedBankReleaseTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRegistrationReadyTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRegistrationReadyTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderFinalDocumentsReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderFinalDocumentsReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHandoverAppointmentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHandoverAppointmentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHappinessCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHappinessCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderItemArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderRegistrationInitiatedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderRegistrationInitiatedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderUploadedHandoverDocumentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderUploadedHandoverDocumentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSignedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSignedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlItemTypeArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlPartnerArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteProductArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRefurbishmentCommentArgs = {
  commentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRefurbishmentServicesArgs = {
  serviceIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationDeleteRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationDeleteRetailAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailClaimCostArgs = {
  claimCostId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailCustomerArgs = {
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailFeatureArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailSubClaimArgs = {
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailSubClaimDocumentArgs = {
  documentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteUserGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteUserPropertiesArgs = {
  id: Scalars['UUID'];
  propertyNames: Array<InputMaybe<Scalars['String']>>;
};


/** Mutation root */
export type MutationDisableDocumentInventoryTypeArgs = {
  input: DisableDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationDisableOrderAutoCancellationArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDistributeRetailClaimCostsArgs = {
  claimCostDistributions: BatchProjection_ClaimCostDistributionProjectionInput;
  claimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationEnableDocumentInventoryTypeArgs = {
  input: EnableDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationImportCarRegistrationActionItemsArgs = {
  request: BatchProjection_CarRegistrationActionImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportCarleadArgs = {
  carlead: ImportCarleadProjectionInput;
};


/** Mutation root */
export type MutationImportInventorySourcingItemsArgs = {
  request: BatchProjection_InventorySourcingImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportPriceUpdateItemsArgs = {
  request: BatchProjection_PriceUpdateItemImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportProfitAndLossItemsArgs = {
  request: BatchProjection_ProfitAndLossImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportRetailDocumentInventoryItemsArgs = {
  request: BatchProjection_RetailDocumentInventoryImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportRetailPaymentItemsArgs = {
  request: BatchProjection_RetailPaymentImportCreateProjectionInput;
};


/** Mutation root */
export type MutationMarkRetailReadyArgs = {
  refurbishmentIds: Array<InputMaybe<Scalars['UUID']>>;
  retailReady: Scalars['Boolean'];
};


/** Mutation root */
export type MutationMarkUnidentifiedPaymentAsInternalTransferArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToCanceledArgs = {
  adId: Scalars['UUID'];
  canceledDescription?: InputMaybe<Scalars['String']>;
  canceledPeriod?: InputMaybe<Scalars['String']>;
  canceledReason: Scalars['String'];
  canceledType?: InputMaybe<Scalars['String']>;
  financingRejectionReason?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  subCancelReason?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationMoveOrderToCompletedArgs = {
  adId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToContractSentArgs = {
  adId: Scalars['UUID'];
  contractSentOn: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToContractSignedArgs = {
  adId: Scalars['UUID'];
  contractSignedOn: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToDeliveredArgs = {
  adId: Scalars['UUID'];
  id: Scalars['UUID'];
  vehicleDeliveredOn: Scalars['String'];
};


/** Mutation root */
export type MutationMoveOrderToVerifiedArgs = {
  adId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveRetailAdToImportedToRetailArgs = {
  adId: Scalars['UUID'];
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveRetailAdToReturnToAuto1Args = {
  adId: Scalars['UUID'];
  reason: Scalars['String'];
  retailAdId: Scalars['UUID'];
  returnedOn: Scalars['String'];
};


/** Mutation root */
export type MutationNotificationVisualizedArgs = {
  notificationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPatchDocumentArgs = {
  documentUpdate: RetailDocumentUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPersistOrderFinancingFlowTypeArgs = {
  flowTypeProjection: OrderFinancingSaveFlowTypeProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPinOrderCommentArgs = {
  id: Scalars['UUID'];
  pinInteraction: OrderCommentPinInteractionProjectionInput;
};


/** Mutation root */
export type MutationPinOrderHistoryEventArgs = {
  id: Scalars['UUID'];
  pinEvent: OrderHistoryEventPinProjectionInput;
};


/** Mutation root */
export type MutationPreApproveOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRejectOrderFinancingArgs = {
  orderId: Scalars['UUID'];
  reason: RetailOrderFinanceRejectReason;
};


/** Mutation root */
export type MutationRemoveAdPublishingBlockersArgs = {
  publishingBlockers: Array<InputMaybe<PublishingBlockerRemoveProjectionInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRequestBankDetailsForOutboundPaymentArgs = {
  request: RequestBankDetailsForOutboundPaymentProjectionInput;
};


/** Mutation root */
export type MutationResetOrderHandoverDateArgs = {
  adId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResetUserPasswordArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationRetailDocumentPackageUpdateImportArgs = {
  request: BatchProjection_RetailDocumentPackageUpdateImportProjectionInput;
};


/** Mutation root */
export type MutationRetryRefundArgs = {
  id: Scalars['UUID'];
  refund: RetailRefundRetryProjectionInput;
};


/** Mutation root */
export type MutationRevertLastOrderTransitionArgs = {
  adId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSaveFinanceRiskEvaluationArgs = {
  financeRiskEvaluation: SaveFinanceRiskEvaluationProjectionInput;
};


/** Mutation root */
export type MutationSaveVisitingLogArgs = {
  model: LogProjectionInput;
};


/** Mutation root */
export type MutationSelfAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetHighlightsArgs = {
  adId: Scalars['UUID'];
  highlights: BatchProjection_HighlightProjectionInput;
  locale?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove1Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove2Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove3Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostCustomerDecisionArgs = {
  costId: Scalars['UUID'];
  customerDecision: Scalars['String'];
};


/** Mutation root */
export type MutationStartFullManualEvaluationArgs = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionInput;
};


/** Mutation root */
export type MutationStartFullManualEvaluationProtectedArgs = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionInput;
};


/** Mutation root */
export type MutationStartFullManualEvaluationProtectedV2Args = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
};


/** Mutation root */
export type MutationStartFullManualEvaluationV2Args = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
};


/** Mutation root */
export type MutationTransitionPaymentUnidentifiedToInternalTransferArgs = {
  category: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUnAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateAbTestingExperimentArgs = {
  id: Scalars['UUID'];
  input: AbTestingExperimentInputInput;
};


/** Mutation root */
export type MutationUpdateAdArgs = {
  ad: AdUpdateProjectionInput;
  adId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateAdImagesArgs = {
  adId: Scalars['UUID'];
  adImages: AdImageProjectionInput;
};


/** Mutation root */
export type MutationUpdateAdVehicleArgs = {
  adId: Scalars['UUID'];
  vehicle: AdVehicleProjectionInput;
};


/** Mutation root */
export type MutationUpdateBranchEntryCheckStatusArgs = {
  id: Scalars['UUID'];
  status: BranchEntryCheckStatus;
};


/** Mutation root */
export type MutationUpdateCheckoutPaymentDetailsArgs = {
  checkoutPaymentDetails: CheckoutPaymentDetailsUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateClaimCostCompensationArgs = {
  claimCostCompensation: ClaimCostCompensationUpdateProjectionInput;
  compensationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCreditNoteArgs = {
  creditNote?: InputMaybe<CreditNoteUpdateProjectionInput>;
  id?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationUpdateCustomerAddressArgs = {
  address: CustomerAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCustomerDisplayOnDamageArgs = {
  customerDisplay: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCustomerExpectedMaxEtaArgs = {
  customerExpectedMaxETA: Scalars['LocalDate'];
  delayReason: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCustomerUserArgs = {
  id: Scalars['UUID'];
  user: CustomerUserUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateDigitalCarRegistrationArgs = {
  id: Scalars['UUID'];
  registration: DigitalCarRegistrationUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateDocumentPackageArgs = {
  input: UpdateDocumentPackageInput;
};


/** Mutation root */
export type MutationUpdateDocumentPackagePartialArgs = {
  input: UpdateDocumentPackagePartialInput;
};


/** Mutation root */
export type MutationUpdateExportSectionArgs = {
  adId: Scalars['UUID'];
  exportAttribute: ExportSectionMutationProjectionInput;
};


/** Mutation root */
export type MutationUpdateExternalFinancingEvaluationArgs = {
  financeRiskEvaluation: UpdateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateGroupArgs = {
  group: GroupUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateImageTagArgs = {
  id: Scalars['UUID'];
  imageTag: RetailImageTagUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateInvoiceArgs = {
  id: Scalars['UUID'];
  invoice: InvoiceUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateMobiledeImagesArgs = {
  adId: Scalars['UUID'];
  transferImages: TransferImageProjectionInput;
};


/** Mutation root */
export type MutationUpdateOrderArgs = {
  id: Scalars['UUID'];
  order: OrderUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateOrderAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderBillingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderBranchArgs = {
  branchId?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderCarRegistrationArgs = {
  carRegistration: OrderCarRegistrationUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderCarRegistrationAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDeliveryOptionArgs = {
  deliveryOption: Scalars['String'];
  estimationId?: InputMaybe<Scalars['UUID']>;
  hubId?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDeliveryPlannedCarHandoverDateArgs = {
  branchPlannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  comment: ReschedulingCommentProjectionInput;
  homePlannedCarHandoverFrom?: InputMaybe<Scalars['String']>;
  homePlannedCarHandoverTo?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDeliveryTypeArgs = {
  deliveryType: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDocumentHandoverOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateOrderDocumentShippingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderFinancingArgs = {
  orderFinancing: OrderFinancingUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderIsBranchCheckupSuccessfulArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateOrderIsTestArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateOrderItemDiscountGrossArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateOrderItemPriceGrossArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateOrderPaymentDetailsArgs = {
  orderId: Scalars['UUID'];
  paymentDetails: OrderPaymentDetailsUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateOrderShippingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderShippingAddressForceArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderVehicleDeliveredOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateOutboundPaymentArgs = {
  id: Scalars['UUID'];
  outboundPaymentUpdate: RetailOutboundPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdatePnlItemArgs = {
  id: Scalars['UUID'];
  item: PnlItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateProductArgs = {
  id: Scalars['UUID'];
  product: ProductUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefundArgs = {
  id: Scalars['UUID'];
  refund: RetailRefundUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefundAmountArgs = {
  id: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateRefurbishmentArgs = {
  id: Scalars['UUID'];
  refurbishment: RefurbishmentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefurbishmentCommentArgs = {
  comment: Scalars['String'];
  commentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRefurbishmentServicesArgs = {
  services: Array<InputMaybe<RefurbishmentUpdateServicesProjectionInput>>;
};


/** Mutation root */
export type MutationUpdateRefurbishmentStateArgs = {
  adId: Scalars['UUID'];
  refurbishmentId: Scalars['UUID'];
  stateChange: RetailRefurbishmentStateChangeDtoInput;
};


/** Mutation root */
export type MutationUpdateRetailAdArgs = {
  id: Scalars['UUID'];
  retailAd: RetailAdUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1BuyPriceArgs = {
  id: Scalars['UUID'];
  value: Scalars['Int'];
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1ReturnOnArgs = {
  id: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1ReturnReasonArgs = {
  id: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateRetailAdAutoheroPurchaseOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdDamageDisplayToCustomerFrontendArgs = {
  changes: Array<InputMaybe<ChangeInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailAdFirstImportOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdHidingReasonArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdIsTestArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateRetailAdRetailReadyDateArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsDetailsArgs = {
  details: RetailAdSecondaryWheelsDetailsUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsRimsDetailsArgs = {
  id: Scalars['UUID'];
  rimsDetails: NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput;
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsTiresDetailsArgs = {
  id: Scalars['UUID'];
  tiresDetails: NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput;
};


/** Mutation root */
export type MutationUpdateRetailAddressArgs = {
  address: RetailAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  validate?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationUpdateRetailCheckoutArgs = {
  checkout: RetailCheckoutUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailCheckoutByOrderIdArgs = {
  checkout: RetailCheckoutUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailClaimCompensationTypeArgs = {
  claimId: Scalars['UUID'];
  compensation: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailClaimCostArgs = {
  claimCostUpdate: RetailClaimCostUpdateProjectionInput;
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailClaimWorkshopDetailsArgs = {
  claimId: Scalars['UUID'];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsUpdateProjectionInput;
  workshopId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailCustomerArgs = {
  customer: RetailCustomerUpdateProjectionInput;
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailFeatureArgs = {
  feature: UpdateRetailFeatureProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailLeadArgs = {
  id: Scalars['UUID'];
  retailLead: RetailLeadProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimAssigneeUserArgs = {
  claimId: Scalars['UUID'];
  updateUserAssign: RetailClaimUpdateUserAssignProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimGroupArgs = {
  claimId: Scalars['UUID'];
  updateGroup: RetailClaimUpdateGroupProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimStateArgs = {
  claimId: Scalars['UUID'];
  updateState: RetailClaimUpdateStateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderSubClaimArgs = {
  retailClaim: RetailSubClaimUpdateProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailPaymentArgs = {
  id: Scalars['UUID'];
  payment: RetailPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailShippingAddressForceArgs = {
  address: RetailAddressUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailTaxNoVaArgs = {
  id: Scalars['UUID'];
  updateProjection: RetailTaxNoVaUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailUnidentifiedPaymentArgs = {
  id: Scalars['UUID'];
  unidentifiedPayment: RetailUnidentifiedPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRoleArgs = {
  id: Scalars['UUID'];
  role: RoleUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateSellerAddressArgs = {
  addressId: Scalars['UUID'];
  sellerAddress: SellerAddressProjectionInput;
  sellerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateTaxAdvantageArgs = {
  id: Scalars['UUID'];
  taxAdvantage: RetailAdTaxAdvantageUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateTestOrderDownPaymentArgs = {
  downPayment: UpdateTestOrderDownPaymentProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateUserArgs = {
  id: Scalars['UUID'];
  user: UserUpdateProjectionInput;
};


/** Mutation root */
export type MutationUserObfuscationArgs = {
  id: Scalars['UUID'];
};

export type NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput = {
  map?: InputMaybe<Scalars['Map_WheelPlacementType_RimDetailsScalar']>;
};

export type NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput = {
  map?: InputMaybe<Scalars['Map_WheelPlacementType_TireDetailsScalar']>;
};

export type OffsetBasedResponseProjection_BranchDto = {
  __typename?: 'OffsetBasedResponseProjection_BranchDTO';
  entities?: Maybe<Array<Maybe<BranchDto>>>;
  first: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type OrderAddressCreateProjectionInput = {
  addressId?: InputMaybe<Scalars['UUID']>;
  addressType?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Long']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  customerType?: InputMaybe<Scalars['String']>;
};

export type OrderAddressProjection = {
  __typename?: 'OrderAddressProjection';
  address?: Maybe<RetailAddressProjection>;
  addressId?: Maybe<Scalars['UUID']>;
  addressType?: Maybe<Scalars['String']>;
  branch?: Maybe<BranchProjection>;
  branchId?: Maybe<Scalars['Long']>;
  created?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
};

export type OrderAddressUpdateProjectionInput = {
  addressId?: InputMaybe<Scalars['UUID']>;
  branchId?: InputMaybe<Scalars['Long']>;
};

export type OrderCancelMainReasonProjection = {
  __typename?: 'OrderCancelMainReasonProjection';
  name?: Maybe<Scalars['String']>;
  subReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCancelPeriodProjection = {
  __typename?: 'OrderCancelPeriodProjection';
  name?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<OrderCancelMainReasonProjection>>>;
};

export type OrderCancelReasonProjection = {
  __typename?: 'OrderCancelReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  subCancelReasons?: Maybe<Array<Maybe<OrderCancelReasonProjection>>>;
};

export type OrderCancelTypeProjection = {
  __typename?: 'OrderCancelTypeProjection';
  name?: Maybe<Scalars['String']>;
  periods?: Maybe<Array<Maybe<OrderCancelPeriodProjection>>>;
};

export type OrderCancellationRevertibleProjection = {
  __typename?: 'OrderCancellationRevertibleProjection';
  reasonForNotSupporting?: Maybe<Scalars['String']>;
  stateBeforeCancelling?: Maybe<Scalars['String']>;
  supported?: Maybe<Scalars['Boolean']>;
};

export type OrderCancellationTaskProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

export type OrderCarRegistrationUpdateProjectionInput = {
  status?: InputMaybe<Scalars['String']>;
};

export type OrderCommentCreateProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

export type OrderCommentPinInteractionProjectionInput = {
  interactionId?: InputMaybe<Scalars['UUID']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type OrderCommentProjection = {
  __typename?: 'OrderCommentProjection';
  channel?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  tags?: Maybe<Array<Maybe<CommentTagProjection>>>;
};

export type OrderCreateProjectionInput = {
  auxUserId?: InputMaybe<Scalars['UUID']>;
  branchId?: InputMaybe<Scalars['Int']>;
  checkoutBranchId?: InputMaybe<Scalars['Int']>;
  conversionType?: InputMaybe<Scalars['String']>;
  delivery?: InputMaybe<RetailOrderDeliveryDtoInput>;
  flowVersion?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<OrderItemCreateProjectionInput>>>;
  orderAddresses?: InputMaybe<Array<InputMaybe<OrderAddressCreateProjectionInput>>>;
  retailCustomers?: InputMaybe<Array<InputMaybe<RetailCustomerCreateProjectionInput>>>;
  source?: InputMaybe<Scalars['String']>;
};

export type OrderCustomerLeadProjection = {
  __typename?: 'OrderCustomerLeadProjection';
  lastUpdated?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type OrderEmailEventAttachmentCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
  originalFileName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type OrderEmailEventAttachmentProjection = {
  __typename?: 'OrderEmailEventAttachmentProjection';
  id?: Maybe<Scalars['String']>;
  originalFileName?: Maybe<Scalars['String']>;
  stored?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OrderFinanceCancelReasonProjection = {
  __typename?: 'OrderFinanceCancelReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type OrderFinanceRejectReasonProjection = {
  __typename?: 'OrderFinanceRejectReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type OrderFinancingDto = {
  __typename?: 'OrderFinancingDto';
  applicationNumber?: Maybe<Scalars['String']>;
  approvedOn?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelSubReason?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  flowType?: Maybe<RetailOrderFinancingFlowType>;
  id?: Maybe<Scalars['UUID']>;
  monthlyPaymentDay?: Maybe<Scalars['Int']>;
  nfaStatus?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderPaymentDetails?: Maybe<OrderFinancingPaymentDetailsDto>;
  preApprovedOn?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type OrderFinancingHistoryProjection = {
  __typename?: 'OrderFinancingHistoryProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  orderFinancingId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type OrderFinancingPaymentDetailsDto = {
  __typename?: 'OrderFinancingPaymentDetailsDTO';
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  lastUpdateInitiator?: Maybe<Scalars['String']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  paymentType?: Maybe<Scalars['String']>;
};

export type OrderFinancingSaveFlowTypeProjectionInput = {
  flowType?: InputMaybe<RetailOrderFinancingFlowType>;
};

export enum OrderFinancingType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export type OrderFinancingUpdateProjectionInput = {
  cancelReason?: InputMaybe<RetailOrderFinanceCancelReason>;
  rejectReason?: InputMaybe<RetailOrderFinanceRejectReason>;
  status?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEmailPlaceholderInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventCreateProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventPinProjectionInput = {
  eventId?: InputMaybe<Scalars['UUID']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventProjection = {
  __typename?: 'OrderHistoryEventProjection';
  attachments?: Maybe<Array<Maybe<OrderEmailEventAttachmentProjection>>>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  eventType?: Maybe<HistoryEventType>;
  id?: Maybe<Scalars['UUID']>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  pinned?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Map_String_StringScalar']>;
  recipient?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderItemCreateProjectionInput = {
  discountGrossMinorUnits?: InputMaybe<Scalars['Long']>;
  discountMinorUnits?: InputMaybe<Scalars['Long']>;
  externalId?: InputMaybe<Scalars['UUID']>;
  externalType?: InputMaybe<Scalars['Int']>;
  priceGrossMinorUnits?: InputMaybe<Scalars['Long']>;
  type?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type OrderItemProjection = {
  __typename?: 'OrderItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type OrderItemsRefundsDto = {
  __typename?: 'OrderItemsRefundsDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  externalOrderItemId?: Maybe<Scalars['UUID']>;
};

export type OrderPaymentDetailsProjection = {
  __typename?: 'OrderPaymentDetailsProjection';
  amountInWords?: Maybe<Scalars['String']>;
  balanceAmount?: Maybe<PriceDto>;
  creditAmount?: Maybe<PriceDto>;
  creditNoteAmount?: Maybe<PriceDto>;
  creditPeriod?: Maybe<Scalars['Int']>;
  depositAmount?: Maybe<PriceDto>;
  depositDueDate?: Maybe<Scalars['String']>;
  depositPaidOn?: Maybe<Scalars['String']>;
  financingType?: Maybe<Scalars['String']>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidAmount?: Maybe<PriceDto>;
  paidCashAmount?: Maybe<PriceDto>;
  paidFinancingAmount?: Maybe<PriceDto>;
  paymentState?: Maybe<OrderPaymentState>;
  paymentType?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  remainingAmount?: Maybe<PriceDto>;
  remainingCashAmount?: Maybe<PriceDto>;
};

export type OrderPaymentDetailsUpdateProjectionInput = {
  amountInWords?: InputMaybe<Scalars['String']>;
  creditAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditPeriod?: InputMaybe<Scalars['Int']>;
  depositAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  depositDueDate?: InputMaybe<Scalars['String']>;
  discountRequested?: InputMaybe<Scalars['Boolean']>;
  financingType?: InputMaybe<Scalars['String']>;
  fullPaymentDueDate?: InputMaybe<Scalars['String']>;
  monthlyPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  paymentType?: InputMaybe<Scalars['String']>;
};

export enum OrderPaymentState {
  FullyPaid = 'FULLY_PAID',
  FullyRefunded = 'FULLY_REFUNDED',
  Open = 'OPEN',
  Overpaid = 'OVERPAID',
  PartlyPaid = 'PARTLY_PAID',
  Refunded = 'REFUNDED'
}

export type OrderProjection = {
  __typename?: 'OrderProjection';
  adItems?: Maybe<Array<Maybe<AdItemProjection>>>;
  adSecondaryWheelsItems?: Maybe<Array<Maybe<SecondaryWheelsItemProjection>>>;
  amountInWords?: Maybe<Scalars['String']>;
  autoCancellation?: Maybe<RetailOrderAutoCancellationDto>;
  branchCheckupOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  canceledOn?: Maybe<Scalars['String']>;
  carInBranch?: Maybe<Scalars['Boolean']>;
  carRegistration?: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationItems?: Maybe<Array<Maybe<CarRegistrationItemProjection>>>;
  carRegistrationStatus?: Maybe<CarRegistrationStatusDictionaryDto>;
  checkoutBranchId?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  contractSentOn?: Maybe<Scalars['String']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  conversionType?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  customer?: Maybe<RetailCustomerProjection>;
  customerCountry?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  deliveryDetails?: Maybe<RetailOrderDeliveryDetailsProjection>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryItems?: Maybe<Array<Maybe<OrderItemProjection>>>;
  deliveryType?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<PriceDto>;
  depositAmountMinorUnits?: Maybe<Scalars['Long']>;
  depositDueDate?: Maybe<Scalars['String']>;
  documentHandoverOn?: Maybe<Scalars['String']>;
  downPaymentItems?: Maybe<Array<Maybe<DownPaymentItemProjection>>>;
  editable?: Maybe<Scalars['Boolean']>;
  finalDocumentHandoverOn?: Maybe<Scalars['String']>;
  finalPaymentDueDate?: Maybe<Scalars['String']>;
  financeApplications?: Maybe<Array<Maybe<FinanceAppProjection>>>;
  flowVersion?: Maybe<Scalars['String']>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isBranchCheckupSuccessful?: Maybe<Scalars['Boolean']>;
  isOracle?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTradeIn?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<OrderItemProjection>>>;
  locale?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  overallDelayCalendarDays?: Maybe<Scalars['Int']>;
  paymentDetails?: Maybe<OrderPaymentDetailsProjection>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed. Use deliveryDetails.branchPlannedCarHandoverOn instead. */
  plannedCarHandoverOn?: Maybe<Scalars['String']>;
  productItems?: Maybe<Array<Maybe<ProductItemProjection>>>;
  reservationEndOn?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  taxNoVA?: Maybe<RetailTaxNoVaProjection>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceNet?: Maybe<PriceDto>;
  totalTaxAdvantage?: Maybe<PriceDto>;
  totalVat?: Maybe<PriceDto>;
  tradeInItems?: Maybe<Array<Maybe<RetailTradeInItemProjection>>>;
  transportOrdered?: Maybe<Scalars['String']>;
  trialExpiredOn?: Maybe<Scalars['String']>;
  trialReturnOn?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  user?: Maybe<CustomerProjection>;
  userId?: Maybe<Scalars['UUID']>;
  vehicleDeliveredOn?: Maybe<Scalars['String']>;
  vehiclePreparationItems?: Maybe<Array<Maybe<VehiclePreparationItemProjection>>>;
  warrantyItems?: Maybe<Array<Maybe<WarrantyItemProjection>>>;
};

export type OrderReadyForVerificationTaskProjection = {
  __typename?: 'OrderReadyForVerificationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type OrderTaskProjection = {
  __typename?: 'OrderTaskProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderUpdateProjectionInput = {
  amountInWords?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  cancelDescription?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  canceledOn?: InputMaybe<Scalars['String']>;
  checkoutBranchId?: InputMaybe<Scalars['Int']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  contractSentOn?: InputMaybe<Scalars['String']>;
  contractSigned?: InputMaybe<Scalars['Boolean']>;
  contractSignedOn?: InputMaybe<Scalars['String']>;
  conversionType?: InputMaybe<Scalars['String']>;
  depositAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  depositDueDate?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  fullPaymentDueDate?: InputMaybe<Scalars['String']>;
  isBranchCheckupSuccessful?: InputMaybe<Scalars['Boolean']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  isTradeIn?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  reservationEndOn?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subCancelReason?: InputMaybe<Scalars['String']>;
  trialExpiredOn?: InputMaybe<Scalars['String']>;
  trialReturnOn?: InputMaybe<Scalars['String']>;
};

export type OutboundPaymentOracleDetailsProjection = {
  __typename?: 'OutboundPaymentOracleDetailsProjection';
  accountingDate?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  oracleFileName?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PageDto_BranchEntryCheckSearchItemDto = {
  __typename?: 'PageDTO_BranchEntryCheckSearchItemDTO';
  entities?: Maybe<Array<Maybe<BranchEntryCheckSearchItemDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_BranchProjection = {
  __typename?: 'PageDTO_BranchProjection';
  entities?: Maybe<Array<Maybe<BranchProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_ChangelogProjection = {
  __typename?: 'PageDTO_ChangelogProjection';
  entities?: Maybe<Array<Maybe<ChangelogProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_ClaimChangelogResponseProjection = {
  __typename?: 'PageDTO_ClaimChangelogResponseProjection';
  entities?: Maybe<Array<Maybe<ClaimChangelogResponseProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_CreditNoteProjection = {
  __typename?: 'PageDTO_CreditNoteProjection';
  entities?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_CustomerProjection = {
  __typename?: 'PageDTO_CustomerProjection';
  entities?: Maybe<Array<Maybe<CustomerProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_DataValidationDto = {
  __typename?: 'PageDTO_DataValidationDTO';
  entities?: Maybe<Array<Maybe<DataValidationDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_DigitalCarRegistrationProjection = {
  __typename?: 'PageDTO_DigitalCarRegistrationProjection';
  entities?: Maybe<Array<Maybe<DigitalCarRegistrationProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_DiscountDto = {
  __typename?: 'PageDTO_DiscountDTO';
  entities?: Maybe<Array<Maybe<DiscountDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_EsOrderProjection = {
  __typename?: 'PageDTO_EsOrderProjection';
  entities?: Maybe<Array<Maybe<EsOrderProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_ExitCheckProjection = {
  __typename?: 'PageDTO_ExitCheckProjection';
  entities?: Maybe<Array<Maybe<ExitCheckProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_GroupProjection = {
  __typename?: 'PageDTO_GroupProjection';
  entities?: Maybe<Array<Maybe<GroupProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_InvoiceProjection = {
  __typename?: 'PageDTO_InvoiceProjection';
  entities?: Maybe<Array<Maybe<InvoiceProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_OrderCommentProjection = {
  __typename?: 'PageDTO_OrderCommentProjection';
  entities?: Maybe<Array<Maybe<OrderCommentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_OrderFinancingHistoryProjection = {
  __typename?: 'PageDTO_OrderFinancingHistoryProjection';
  entities?: Maybe<Array<Maybe<OrderFinancingHistoryProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_OrderHistoryEventProjection = {
  __typename?: 'PageDTO_OrderHistoryEventProjection';
  entities?: Maybe<Array<Maybe<OrderHistoryEventProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_OrderProjection = {
  __typename?: 'PageDTO_OrderProjection';
  entities?: Maybe<Array<Maybe<OrderProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_OrderTaskProjection = {
  __typename?: 'PageDTO_OrderTaskProjection';
  entities?: Maybe<Array<Maybe<OrderTaskProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_PnlItemProjection = {
  __typename?: 'PageDTO_PnlItemProjection';
  entities?: Maybe<Array<Maybe<PnlItemProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_PnlItemTypeProjection = {
  __typename?: 'PageDTO_PnlItemTypeProjection';
  entities?: Maybe<Array<Maybe<PnlItemTypeProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_PnlPartnerProjection = {
  __typename?: 'PageDTO_PnlPartnerProjection';
  entities?: Maybe<Array<Maybe<PnlPartnerProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RefurbishmentAggregateProjection = {
  __typename?: 'PageDTO_RefurbishmentAggregateProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentAggregateProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RefurbishmentCommentProjection = {
  __typename?: 'PageDTO_RefurbishmentCommentProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentCommentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RefurbishmentDto = {
  __typename?: 'PageDTO_RefurbishmentDTO';
  entities?: Maybe<Array<Maybe<RefurbishmentDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RefurbishmentPartnerDto = {
  __typename?: 'PageDTO_RefurbishmentPartnerDTO';
  entities?: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RefurbishmentProjection = {
  __typename?: 'PageDTO_RefurbishmentProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailAbTestingExperimentProjection = {
  __typename?: 'PageDTO_RetailAbTestingExperimentProjection';
  entities?: Maybe<Array<Maybe<RetailAbTestingExperimentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailAbTestingParticipantProjection = {
  __typename?: 'PageDTO_RetailAbTestingParticipantProjection';
  entities?: Maybe<Array<Maybe<RetailAbTestingParticipantProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailAdPnlDataProjection = {
  __typename?: 'PageDTO_RetailAdPnlDataProjection';
  entities?: Maybe<Array<Maybe<RetailAdPnlDataProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailAdPriceChangeDto = {
  __typename?: 'PageDTO_RetailAdPriceChangeDTO';
  entities?: Maybe<Array<Maybe<RetailAdPriceChangeDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailAdProjection = {
  __typename?: 'PageDTO_RetailAdProjection';
  entities?: Maybe<Array<Maybe<RetailAdProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailCarRegistrationDto = {
  __typename?: 'PageDTO_RetailCarRegistrationDTO';
  entities?: Maybe<Array<Maybe<RetailCarRegistrationDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailClaimAssigneeDto = {
  __typename?: 'PageDTO_RetailClaimAssigneeDTO';
  entities?: Maybe<Array<Maybe<RetailClaimAssigneeDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailClaimProjection = {
  __typename?: 'PageDTO_RetailClaimProjection';
  entities?: Maybe<Array<Maybe<RetailClaimProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailDataImportProjection = {
  __typename?: 'PageDTO_RetailDataImportProjection';
  entities?: Maybe<Array<Maybe<RetailDataImportProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailDigitalCarRegistrationAssigneeDto = {
  __typename?: 'PageDTO_RetailDigitalCarRegistrationAssigneeDTO';
  entities?: Maybe<Array<Maybe<RetailDigitalCarRegistrationAssigneeDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailFeatureProjection = {
  __typename?: 'PageDTO_RetailFeatureProjection';
  entities?: Maybe<Array<Maybe<RetailFeatureProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailImageTagProjection = {
  __typename?: 'PageDTO_RetailImageTagProjection';
  entities?: Maybe<Array<Maybe<RetailImageTagProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailInternalTransferPaymentProjection = {
  __typename?: 'PageDTO_RetailInternalTransferPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailInternalTransferPaymentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailLeadProjection = {
  __typename?: 'PageDTO_RetailLeadProjection';
  entities?: Maybe<Array<Maybe<RetailLeadProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailOutboundPaymentProjection = {
  __typename?: 'PageDTO_RetailOutboundPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailOutboundPaymentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailPaymentProjection = {
  __typename?: 'PageDTO_RetailPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailProductDto = {
  __typename?: 'PageDTO_RetailProductDTO';
  entities?: Maybe<Array<Maybe<RetailProductDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailRefundProjection = {
  __typename?: 'PageDTO_RetailRefundProjection';
  entities?: Maybe<Array<Maybe<RetailRefundProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailUnidentifiedPaymentProjection = {
  __typename?: 'PageDTO_RetailUnidentifiedPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailUnidentifiedPaymentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailUserDto = {
  __typename?: 'PageDTO_RetailUserDTO';
  entities?: Maybe<Array<Maybe<RetailUserDto>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailVehiclePreparationProjection = {
  __typename?: 'PageDTO_RetailVehiclePreparationProjection';
  entities?: Maybe<Array<Maybe<RetailVehiclePreparationProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RetailWarrantyProjection = {
  __typename?: 'PageDTO_RetailWarrantyProjection';
  entities?: Maybe<Array<Maybe<RetailWarrantyProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_RoleProjection = {
  __typename?: 'PageDTO_RoleProjection';
  entities?: Maybe<Array<Maybe<RoleProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_SearchRetailDocumentProjection = {
  __typename?: 'PageDTO_SearchRetailDocumentProjection';
  entities?: Maybe<Array<Maybe<SearchRetailDocumentProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageDto_TaskManagementProjection = {
  __typename?: 'PageDTO_taskManagementProjection';
  entities?: Maybe<Array<Maybe<TaskManagementProjection>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type PageFilterProjectionInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterProjectionInput>>>;
};

export type PageRequestProjectionInput = {
  filter?: InputMaybe<PageFilterProjectionInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sort?: InputMaybe<PageSortProjectionInput>;
};

export type PageSortProjectionInput = {
  order?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type PageableRequestProjection_RefurbishmentAggregateSearchDtoInput = {
  criteria?: InputMaybe<RefurbishmentAggregateSearchDtoInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PartnerDto = {
  __typename?: 'PartnerDTO';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  logoPath?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type PartnerWorkshopConnectionDto = {
  __typename?: 'PartnerWorkshopConnectionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isImportedFromBackoffice?: Maybe<Scalars['Boolean']>;
  mainWorkshop?: Maybe<WorkshopDto>;
  repairPartner?: Maybe<RepairPartnerDto>;
  repairWorkshop?: Maybe<WorkshopDto>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PdfDto = {
  __typename?: 'PdfDTO';
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type PdfDtoInput = {
  fullUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PermissionDto = {
  __typename?: 'PermissionDTO';
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
};

export type PermissionProjection = {
  __typename?: 'PermissionProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  internationalCallingCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  internationalCallingCode?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum PnlInvoiceType {
  CreditNote = 'CREDIT_NOTE',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  Estimate = 'ESTIMATE',
  Invoice = 'INVOICE',
  InvoiceCorrection = 'INVOICE_CORRECTION',
  SalesCreditNote = 'SALES_CREDIT_NOTE'
}

export type PnlItemCreateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  entityChargedName?: InputMaybe<Scalars['String']>;
  grossAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceDate?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  netAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['UUID']>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars['UUID']>;
  restoreIfDeleted?: InputMaybe<Scalars['Boolean']>;
  retailAdId?: InputMaybe<Scalars['UUID']>;
  retailCountry?: InputMaybe<Scalars['String']>;
  serviceEndDate?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type PnlItemProjection = {
  __typename?: 'PnlItemProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  entityChargedName?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<PriceDto>;
  grossAmountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  netAmount?: Maybe<PriceDto>;
  netAmountMinorUnits?: Maybe<Scalars['Long']>;
  partner?: Maybe<PnlPartnerProjection>;
  partnerId?: Maybe<Scalars['UUID']>;
  pnlInvoiceType?: Maybe<PnlInvoiceType>;
  pnlItemTypeId?: Maybe<Scalars['UUID']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailCountry?: Maybe<Scalars['String']>;
  serviceEndDate?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  type?: Maybe<PnlItemTypeProjection>;
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatRate?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type PnlItemTypeCreateProjectionInput = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PnlItemTypeProjection = {
  __typename?: 'PnlItemTypeProjection';
  category?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PnlItemUpdateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  entityChargedName?: InputMaybe<Scalars['String']>;
  grossAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceDate?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  netAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['UUID']>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars['UUID']>;
  serviceEndDate?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type PnlPartnerCreateProjectionInput = {
  adminCarrierId?: InputMaybe<Scalars['Long']>;
  auto1Entity?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  invoiceCurrency?: InputMaybe<Scalars['String']>;
  partnerAddress?: InputMaybe<Scalars['String']>;
  partnerCity?: InputMaybe<Scalars['String']>;
  partnerCountry?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  partnerVatId?: InputMaybe<Scalars['String']>;
  partnerZip?: InputMaybe<Scalars['String']>;
};

export type PnlPartnerProjection = {
  __typename?: 'PnlPartnerProjection';
  adminCarrierId?: Maybe<Scalars['Long']>;
  auto1Entity?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoiceCurrency?: Maybe<Scalars['String']>;
  partnerAddress?: Maybe<Scalars['String']>;
  partnerCity?: Maybe<Scalars['String']>;
  partnerCountry?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  partnerVatId?: Maybe<Scalars['String']>;
  partnerZip?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PollValidationStatusProjectionInput = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
};

export type PositionDto = {
  __typename?: 'PositionDTO';
  leftPercentage?: Maybe<Scalars['Float']>;
  topPercentage?: Maybe<Scalars['Float']>;
};

export type PositionDtoInput = {
  leftPercentage?: InputMaybe<Scalars['Float']>;
  topPercentage?: InputMaybe<Scalars['Float']>;
};

export type Price = {
  __typename?: 'Price';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  conversionMajor?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
};

export type PriceDto = {
  __typename?: 'PriceDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export enum PriceDetailType {
  Invoice = 'INVOICE',
  Pnl = 'PNL'
}

export type PriceDetailsDto = {
  __typename?: 'PriceDetailsDTO';
  grossPriceMinorUnits?: Maybe<Scalars['Int']>;
  netPriceMinorUnits?: Maybe<Scalars['Int']>;
  priceDetailType?: Maybe<PriceDetailType>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type PriceInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  conversionMajor?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
};

export enum PriceType {
  Fixed = 'FIXED',
  Negotiable = 'NEGOTIABLE'
}

export type PriceUpdateItemImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export enum PriceUpdateType {
  ProceedExcludingPriceError = 'PROCEED_EXCLUDING_PRICE_ERROR',
  ProceedWithPriceError = 'PROCEED_WITH_PRICE_ERROR'
}

export type ProductCreateProjectionInput = {
  active: Scalars['Boolean'];
  category?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: InputMaybe<Scalars['Float']>;
  excludedFromInvoice?: InputMaybe<Scalars['Boolean']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: InputMaybe<Scalars['String']>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: InputMaybe<Scalars['Float']>;
  properties?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type ProductItemProjection = {
  __typename?: 'ProductItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  product?: Maybe<ProductProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type ProductPartnersProjection = {
  __typename?: 'ProductPartnersProjection';
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductProjection = {
  __typename?: 'ProductProjection';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<PriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<PriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<PriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type ProductTypesProjection = {
  __typename?: 'ProductTypesProjection';
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  depositApplicable?: InputMaybe<Scalars['Boolean']>;
  depositPercentage?: InputMaybe<Scalars['Float']>;
  excludedFromInvoice?: InputMaybe<Scalars['Boolean']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars['Long']>;
  paidService?: InputMaybe<Scalars['Boolean']>;
  partner?: InputMaybe<Scalars['String']>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlVatApplicable?: InputMaybe<Scalars['Boolean']>;
  pnlVatRate?: InputMaybe<Scalars['Float']>;
  properties?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type Profile = {
  __typename?: 'Profile';
  depth?: Maybe<Scalars['Int']>;
  unit?: Maybe<ProfileUnit>;
};

export enum ProfileUnit {
  Mm = 'MM'
}

export type ProfitAndLossImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type ProofRequestItemCreateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProofRequestsCreateProjectionInput = {
  requests?: InputMaybe<Array<InputMaybe<ProofRequestItemCreateProjectionInput>>>;
};

export type ProtocolComplementaryProjection = {
  __typename?: 'ProtocolComplementaryProjection';
  chequeVerificationRequired?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<Scalars['String']>;
  customerDateOfBirth?: Maybe<Scalars['LocalDate']>;
  customerName?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['LocalDate']>;
  handoverLocation?: Maybe<Scalars['String']>;
  isHomeDelivery?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  modelDescription?: Maybe<Scalars['String']>;
  onDeliveryPaymentType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentShouldBeDoneOnDelivery?: Maybe<Scalars['Boolean']>;
  paymentStatus?: Maybe<Scalars['String']>;
  remainingBalanceMinorUnits?: Maybe<Scalars['Long']>;
  shippingAddress?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ProtocolSubmissionDtoInput = {
  car?: InputMaybe<CarDtoInput>;
  customer?: InputMaybe<CustomerDtoInput>;
  customerComment?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<DocumentsDtoInput>;
  images?: InputMaybe<ImagesDtoInput>;
  vin?: InputMaybe<Scalars['String']>;
};

export type PublishingBlockerProjection = {
  __typename?: 'PublishingBlockerProjection';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  publishingBlockerReasonId?: Maybe<Scalars['UUID']>;
  publishingBlockerSubReasonId?: Maybe<Scalars['UUID']>;
};

export type PublishingBlockerReasonDto = {
  __typename?: 'PublishingBlockerReasonDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  subReasons?: Maybe<Array<Maybe<PublishingBlockerSubReasonDto>>>;
};

export type PublishingBlockerRemoveProjectionInput = {
  publishingBlockerSubReasonId?: InputMaybe<Scalars['UUID']>;
};

export type PublishingBlockerSubReasonDto = {
  __typename?: 'PublishingBlockerSubReasonDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type PublishingBlockerUpdateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  publishingBlockerSubReasonId?: InputMaybe<Scalars['UUID']>;
};

export type PublishingError = {
  __typename?: 'PublishingError';
  createdOn?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  abTestingAvailableConditionVariables?: Maybe<Array<Maybe<RetailAbTestingConditionVariableProjection>>>;
  abTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  abTestingExperimentCounters?: Maybe<RetailAbTestingExperimentCountersProjection>;
  abTestingExperiments?: Maybe<Array<Maybe<RetailAbTestingExperimentProjection>>>;
  ad?: Maybe<AdProjection>;
  adByStockNumber?: Maybe<AdProjection>;
  adDetailByRetailAdId?: Maybe<AdDetailsProjection>;
  adEquipment?: Maybe<Array<Maybe<CategorizedEquipmentsDto>>>;
  adManagementDictionary?: Maybe<AdManagementDictionaryProjection>;
  adVideoPresignedUrls?: Maybe<Array<Maybe<AwsS3AdVideoPresignedUrlDto>>>;
  attachableTradeIns?: Maybe<Array<Maybe<RetailTradeInProjection>>>;
  batchPriceUpdateValidationSummary?: Maybe<RetailBatchPriceUpdateValidationSummaryDto>;
  buildImageUploadCredentials?: Maybe<AwsUploadCredentialsProjection>;
  carRegistrationActions?: Maybe<Array<Maybe<CarRegistrationActionProjection>>>;
  checkoutPaymentDetails?: Maybe<CheckoutPaymentDetailsProjection>;
  claimOrderDetails?: Maybe<OrderProjection>;
  compiledEmailTemplate?: Maybe<CompiledOrderEmailTemplateProjection>;
  creditScoreDataByOrderFinancing?: Maybe<RetailCreditScoreDataProjection>;
  creditScoreDataByOrderFinancingV2?: Maybe<RetailCreditScoreDataProjectionV2>;
  customerAddress?: Maybe<CustomerAddressProjection>;
  deliveryRoute?: Maybe<DeliveryRouteProjection>;
  digitalCarRegistration?: Maybe<DigitalCarRegistrationProjection>;
  digitalCarRegistrationByOrderId?: Maybe<DigitalCarRegistrationProjection>;
  digitalHandoverComplementary?: Maybe<ProtocolComplementaryProjection>;
  distributeCreditNotesRefunds?: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefunds?: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefundsByOrderItems?: Maybe<ListContainerDto_OrderItemsRefundsDto>;
  /** Returns all the categories with their respective document types */
  documentCategories?: Maybe<RetailDocumentTopCategoryProjection>;
  documentInventoryItemLocations?: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes?: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingDeliveryAddresses?: Maybe<BranchSearchingResult_DocumentBranchProjection>;
  documentShippingProviders?: Maybe<ResultConnection_ShippingProviderProjection>;
  esSearchOrders?: Maybe<PageDto_EsOrderProjection>;
  exitCheckComments?: Maybe<ExitCheckCommentsProjection>;
  experianDataByOrderFinancing?: Maybe<RetailExperianDataProjection>;
  exportSection?: Maybe<ExportSectionProjection>;
  extendedTradeInsForUser?: Maybe<Array<Maybe<RetailTradeInExtendedProjection>>>;
  externalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  externalFinancingOptions?: Maybe<ExternalFinancingOptionsProjection>;
  externalFinancingOptionsValues?: Maybe<ExternalFinancingOptionsValuesProjection>;
  financeRiskEvaluation?: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrder?: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrderFinancing?: Maybe<FinanceRiskEvaluationProjection>;
  financingCalculator?: Maybe<FinancingCalculatorResponseProjection>;
  financingPlanPdfData?: Maybe<Array<Maybe<RetailFinancingPlanBreakdownProjection>>>;
  fullFtsDataByOrderFinancing?: Maybe<RetailFullFtsDataProjection>;
  getAllValidations?: Maybe<PageDto_DataValidationDto>;
  getBranchEntryCheckCarSupported?: Maybe<BranchEntryCheckCarSupportedProjection>;
  getBranchEntryCheckComplementary?: Maybe<BranchEntryCheckComplementaryProjection>;
  getBranchEntryCheckDetails?: Maybe<BranchEntryCheckDetailsProjection>;
  getClaimsChangelog?: Maybe<PageDto_ClaimChangelogResponseProjection>;
  getHandover?: Maybe<RetailOrderHandoverProjection>;
  getOutboundPaymentOptions?: Maybe<Scalars['Map_String_RetailOutboundPaymentOptionProjectionScalar']>;
  getRefurbishmentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  getReschedulingReasons?: Maybe<Array<Maybe<RetailOrderDeliveryReschedulingReasonDto>>>;
  getRetailClaimById?: Maybe<RetailClaimProjection>;
  getVatRateByCountry?: Maybe<VatRateProjection>;
  group?: Maybe<GroupProjection>;
  highlights?: Maybe<Array<Maybe<HighlightDto>>>;
  highlightsSuggestions?: Maybe<Array<Maybe<HighlightDto>>>;
  importedAd?: Maybe<Scalars['Map_String_ObjectScalar']>;
  internalPaymentOptions?: Maybe<InternalPaymentOptionsProjection>;
  internalTransferPaymentById?: Maybe<RetailInternalTransferPaymentProjection>;
  isAppointmentBookingApplicable: Scalars['Boolean'];
  isDigitalCarRegistrationAvailable?: Maybe<RetailDigitalCarRegistrationEnabledProjection>;
  isDigitalCarRegistrationAvailableForAd?: Maybe<RetailDigitalCarRegistrationEnabledProjection>;
  isProductDeRegTempRequired: Scalars['Boolean'];
  isRevertOrderCancellationSupported?: Maybe<OrderCancellationRevertibleProjection>;
  me?: Maybe<RetailUserDto>;
  mobiledeImages?: Maybe<Array<Maybe<TransferImageDto>>>;
  order?: Maybe<OrderProjection>;
  orderAddresses?: Maybe<Array<Maybe<OrderAddressProjection>>>;
  orderCancelReasons?: Maybe<Array<Maybe<OrderCancelReasonProjection>>>;
  orderCancelTypes?: Maybe<Array<Maybe<OrderCancelTypeProjection>>>;
  orderCountsByExternalIds?: Maybe<Scalars['Map_UUID_LongScalar']>;
  orderCustomerNotes?: Maybe<OrderCustomerLeadProjection>;
  orderDelivery?: Maybe<RetailOrderDeliveryProjection>;
  orderEmailTemplates?: Maybe<Array<Maybe<TemplateProjection>>>;
  orderFinanceCancelReasons?: Maybe<Array<Maybe<OrderFinanceCancelReasonProjection>>>;
  orderFinanceRejectReasons?: Maybe<Array<Maybe<OrderFinanceRejectReasonProjection>>>;
  orderFinanceRejectReasonsByFilters?: Maybe<Array<Maybe<OrderFinanceRejectReasonProjection>>>;
  orderFinancing?: Maybe<OrderFinancingDto>;
  orderFinancingPlansHistory?: Maybe<Array<Maybe<RetailFinancingPlanCalculationProjection>>>;
  orderIncludedInABTestingExperiment: Scalars['Boolean'];
  orderStatistics?: Maybe<RetailOrderStatisticsDto>;
  ordersByAdId?: Maybe<Array<Maybe<OrderProjection>>>;
  pnlItem?: Maybe<PnlItemProjection>;
  pnlItemChangelog?: Maybe<PageDto_ChangelogProjection>;
  pnlItemType?: Maybe<PnlItemTypeProjection>;
  pnlItemTypeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  pnlPartner?: Maybe<PnlPartnerProjection>;
  pollValidationStatus?: Maybe<RetailDataImportDto>;
  presignedUrls?: Maybe<Array<Maybe<AwsS3PresignedUrlDto>>>;
  product?: Maybe<RetailProductProjection>;
  productPartners?: Maybe<ProductPartnersProjection>;
  productTypes?: Maybe<ProductTypesProjection>;
  refundsWithCreditNotesDistribute?: Maybe<RetailOrderRefundsDistributionProjection>;
  refurbishment?: Maybe<RefurbishmentDto>;
  refurbishmentByRefId?: Maybe<RefurbishmentDto>;
  refurbishmentCommentsByRefurbishmentId?: Maybe<PageDto_RefurbishmentCommentProjection>;
  refurbishmentPartners?: Maybe<PageDto_RefurbishmentPartnerDto>;
  refurbishmentsByRetailAdId?: Maybe<PageDto_RefurbishmentProjection>;
  refurbishmentsOverview?: Maybe<PageDto_RefurbishmentAggregateProjection>;
  retailAccountDetailsByOrderId?: Maybe<Array<Maybe<RetailAccountDetailsWithPaymentsProjection>>>;
  retailAccountDetailsForPayment?: Maybe<RetailAccountDetailsForPaymentProjection>;
  retailAd?: Maybe<RetailAdProjection>;
  retailAdByAdId?: Maybe<RetailAdProjection>;
  retailAdDamage?: Maybe<Array<Maybe<RetailAdDamageImageDto>>>;
  retailAdDeliveryOptions?: Maybe<BatchProjection_RetailAdDeliveryOptionProjection>;
  retailAdPnlData?: Maybe<RetailAdPnlDataProjection>;
  retailAdPriceHistory?: Maybe<PageDto_RetailAdPriceChangeDto>;
  retailAdPublishingBlockers?: Maybe<RetailAdPublishingBlockerProjection>;
  retailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  retailAdSecondaryWheelsOptions?: Maybe<SecondaryWheelsOptionsProjection>;
  retailAddress?: Maybe<RetailAddressProjection>;
  retailCheckout?: Maybe<RetailCheckoutProjection>;
  retailCheckoutStepTypes?: Maybe<RetailCheckoutAllStepTypesProjection>;
  retailClaimCostCompensationsByOrderId?: Maybe<Array<Maybe<ClaimCostCompensationProjection>>>;
  retailClaimGroupsOptions?: Maybe<Array<Maybe<RetailClaimGroupProjection>>>;
  retailCustomer?: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  retailCustomersByOrderIds?: Maybe<Array<Maybe<RetailCustomersByOrderProjection>>>;
  retailFeature?: Maybe<RetailFeatureProjection>;
  /** evaluate status one feature by name */
  retailFeatureStatusV2?: Maybe<RetailFeatureStatusProjectionV2>;
  /**
   * returns status of all features
   * @deprecated request is heavy and returns unnecessary data
   */
  retailFeatures?: Maybe<Array<Maybe<RetailFeatureStatusDto>>>;
  /** evaluate status of requested features by names */
  retailFeaturesStatusV2?: Maybe<Array<Maybe<RetailFeatureStatusProjectionV2>>>;
  retailFtsRiskDataByOrderFinancing?: Maybe<RetailFtsRiskDataProjection>;
  retailLead?: Maybe<RetailLeadProjection>;
  retailOrderReturnAppointment?: Maybe<RetailOrderReturnAppointmentProjection>;
  retailPayment?: Maybe<RetailPaymentProjection>;
  retailPublishingBlockerReasons?: Maybe<Array<Maybe<PublishingBlockerReasonDto>>>;
  retailVehiclePreparation?: Maybe<RetailVehiclePreparationProjection>;
  role?: Maybe<RoleProjection>;
  roleGroups?: Maybe<ListContainerDto_GroupProjection>;
  roleUsers?: Maybe<ListContainerDto_AuxUserDto>;
  searchAbTestingExperiments?: Maybe<PageDto_RetailAbTestingExperimentProjection>;
  searchAbTestingParticipants?: Maybe<PageDto_RetailAbTestingParticipantProjection>;
  searchAdsByChannelAndSourceIds?: Maybe<Array<Maybe<AdProjection>>>;
  searchAdsByIds?: Maybe<Array<Maybe<AdProjection>>>;
  searchBranchEntryChecks?: Maybe<PageDto_BranchEntryCheckSearchItemDto>;
  searchBranches?: Maybe<PageDto_BranchProjection>;
  searchBranchesForSelection?: Maybe<OffsetBasedResponseProjection_BranchDto>;
  searchCarRegistrationProduct?: Maybe<PageDto_RetailCarRegistrationDto>;
  searchClaimAssigneeUsers?: Maybe<PageDto_RetailClaimAssigneeDto>;
  searchCreditNotes?: Maybe<PageDto_CreditNoteProjection>;
  searchCustomerUsers?: Maybe<PageDto_CustomerProjection>;
  searchDigitalCarRegistrationAssigneeUsers?: Maybe<PageDto_RetailDigitalCarRegistrationAssigneeDto>;
  searchDigitalCarRegistrations?: Maybe<PageDto_DigitalCarRegistrationProjection>;
  searchDiscounts?: Maybe<PageDto_DiscountDto>;
  searchDocuments?: Maybe<PageDto_SearchRetailDocumentProjection>;
  searchGroups?: Maybe<PageDto_GroupProjection>;
  searchImageTags?: Maybe<PageDto_RetailImageTagProjection>;
  searchInternalTransferPayments?: Maybe<PageDto_RetailInternalTransferPaymentProjection>;
  searchInvoices?: Maybe<PageDto_InvoiceProjection>;
  searchOrderChangelogs?: Maybe<PageDto_ChangelogProjection>;
  searchOrderComments?: Maybe<PageDto_OrderCommentProjection>;
  searchOrderFinancingByOrder?: Maybe<Array<Maybe<OrderFinancingDto>>>;
  searchOrderFinancingHistory?: Maybe<PageDto_OrderFinancingHistoryProjection>;
  searchOrderHistoryEvents?: Maybe<PageDto_OrderHistoryEventProjection>;
  searchOrderTasks?: Maybe<PageDto_OrderTaskProjection>;
  searchOrders?: Maybe<PageDto_OrderProjection>;
  searchOutboundPayment?: Maybe<PageDto_RetailOutboundPaymentProjection>;
  searchPnlItemTypes?: Maybe<PageDto_PnlItemTypeProjection>;
  searchPnlItems?: Maybe<PageDto_PnlItemProjection>;
  searchPnlPartners?: Maybe<PageDto_PnlPartnerProjection>;
  searchProducts?: Maybe<PageDto_RetailProductDto>;
  searchRefunds?: Maybe<PageDto_RetailRefundProjection>;
  searchRefurbishmentETAsByRetailAdId?: Maybe<RefurbishmentEtaListProjection>;
  searchRefurbishmentExitChecks?: Maybe<PageDto_ExitCheckProjection>;
  searchRefurbishments?: Maybe<PageDto_RefurbishmentDto>;
  searchRetailAdPnlData?: Maybe<PageDto_RetailAdPnlDataProjection>;
  searchRetailAds?: Maybe<PageDto_RetailAdProjection>;
  searchRetailClaims?: Maybe<PageDto_RetailClaimProjection>;
  searchRetailDataImport?: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailDataImports?: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailFeatureChangelogs?: Maybe<PageDto_ChangelogProjection>;
  searchRetailFeatures?: Maybe<PageDto_RetailFeatureProjection>;
  searchRetailLead?: Maybe<PageDto_RetailLeadProjection>;
  searchRetailPayments?: Maybe<PageDto_RetailPaymentProjection>;
  searchRetailUnidentifiedPayments?: Maybe<PageDto_RetailUnidentifiedPaymentProjection>;
  searchRoles?: Maybe<PageDto_RoleProjection>;
  searchSignatureRequest?: Maybe<ListContainerDto_RetailYouSignSignatureRequestProjection>;
  searchTaskManagerTasks?: Maybe<PageDto_TaskManagementProjection>;
  searchTradeInByStockNumber?: Maybe<RetailTradeInCreateProjection>;
  searchUsers?: Maybe<PageDto_RetailUserDto>;
  searchVehiclePreparationProduct?: Maybe<PageDto_RetailVehiclePreparationProjection>;
  searchWarrantyProducts?: Maybe<PageDto_RetailWarrantyProjection>;
  sellerAddress?: Maybe<SellerAddressProjection>;
  servicesPerType?: Maybe<Scalars['Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar']>;
  surveyScore?: Maybe<RetailSurveyScoreProjection>;
  surveyScores?: Maybe<Array<Maybe<RetailSurveyScoreProjection>>>;
  tasksManagerSearchOptions?: Maybe<TasksManagerSearchOptionsProjection>;
  tasksSearchOptions?: Maybe<TasksSearchOptionsProjection>;
  taxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  trustpilotInvitation?: Maybe<RetailTrustpilotInvitationProjection>;
  trustpilotReview?: Maybe<RetailTrustpilotCustomerReviewProjection>;
  unidentifiedPaymentById?: Maybe<RetailUnidentifiedPaymentProjection>;
  user?: Maybe<UserProjection>;
  vehicleByVin?: Maybe<VehicleInfoProjection>;
  warranty?: Maybe<RetailWarrantyProjection>;
};


/** Query root */
export type QueryAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAbTestingExperimentCountersArgs = {
  environment?: InputMaybe<Scalars['String']>;
  experimentId: Scalars['UUID'];
};


/** Query root */
export type QueryAbTestingExperimentsArgs = {
  environment?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryAdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAdByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** Query root */
export type QueryAdDetailByRetailAdIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAdEquipmentArgs = {
  adId: Scalars['UUID'];
  locale: Scalars['String'];
};


/** Query root */
export type QueryAdVideoPresignedUrlsArgs = {
  request: BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput;
};


/** Query root */
export type QueryAttachableTradeInsArgs = {
  auxUserId: Scalars['UUID'];
};


/** Query root */
export type QueryBatchPriceUpdateValidationSummaryArgs = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryBuildImageUploadCredentialsArgs = {
  awsUploadCredentialsRequestProjection: AwsUploadCredentialsRequestProjectionInput;
};


/** Query root */
export type QueryCarRegistrationActionsArgs = {
  orderUuid: Scalars['UUID'];
};


/** Query root */
export type QueryCheckoutPaymentDetailsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryClaimOrderDetailsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryCompiledEmailTemplateArgs = {
  compileOrderEmailTemplateProjection?: InputMaybe<CompileOrderEmailTemplateProjectionInput>;
};


/** Query root */
export type QueryCreditScoreDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryCreditScoreDataByOrderFinancingV2Args = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryCustomerAddressArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryDeliveryRouteArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalCarRegistrationArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalCarRegistrationByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalHandoverComplementaryArgs = {
  orderId: Scalars['UUID'];
  vin: Scalars['String'];
};


/** Query root */
export type QueryDistributeCreditNotesRefundsArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryDistributeRefundsArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryDistributeRefundsByOrderItemsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDocumentCategoriesArgs = {
  country: Scalars['String'];
  topCategory: Scalars['String'];
};


/** Query root */
export type QueryDocumentInventoryItemLocationsArgs = {
  filter: DocumentInventoryItemLocationFilterInput;
};


/** Query root */
export type QueryDocumentInventoryItemsArgs = {
  filter: DocumentInventoryItemsFilterInput;
};


/** Query root */
export type QueryDocumentInventoryPackageArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDocumentInventoryPackagesArgs = {
  filter?: InputMaybe<DocumentInventoryPackageFilterInput>;
};


/** Query root */
export type QueryDocumentShippingDeliveryAddressesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryEsSearchOrdersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryExitCheckCommentsArgs = {
  orderNumber: Scalars['String'];
};


/** Query root */
export type QueryExperianDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryExportSectionArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryExtendedTradeInsForUserArgs = {
  auxUserId: Scalars['UUID'];
  country?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryExternalFinancingEvaluationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalFinancingOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationByOrderArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryFinancingCalculatorArgs = {
  adId: Scalars['UUID'];
  financingAmount?: InputMaybe<Scalars['Long']>;
  financingPeriod?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryFinancingPlanPdfDataArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFullFtsDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryGetAllValidationsArgs = {
  request: SearchProjection2Input;
};


/** Query root */
export type QueryGetBranchEntryCheckCarSupportedArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetBranchEntryCheckComplementaryArgs = {
  stockNumber: Scalars['String'];
  vin: Scalars['String'];
};


/** Query root */
export type QueryGetBranchEntryCheckDetailsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryGetClaimsChangelogArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryGetHandoverArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetOutboundPaymentOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetRefurbishmentTypesArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryGetRetailClaimByIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryGetVatRateByCountryArgs = {
  countryCode: Scalars['String'];
};


/** Query root */
export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryHighlightsArgs = {
  adId: Scalars['UUID'];
  locale?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryHighlightsSuggestionsArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryImportedAdArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  stockNumber: Scalars['String'];
};


/** Query root */
export type QueryInternalTransferPaymentByIdArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryIsAppointmentBookingApplicableArgs = {
  appointmentType: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryIsDigitalCarRegistrationAvailableArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryIsDigitalCarRegistrationAvailableForAdArgs = {
  stockNumber: Scalars['String'];
  zipCode: Scalars['String'];
};


/** Query root */
export type QueryIsProductDeRegTempRequiredArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryIsRevertOrderCancellationSupportedArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryMobiledeImagesArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryOrderAddressesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderCancelReasonsArgs = {
  orderId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryOrderCountsByExternalIdsArgs = {
  id: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QueryOrderCustomerNotesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderDeliveryArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderEmailTemplatesArgs = {
  market: Scalars['String'];
  project: Scalars['String'];
};


/** Query root */
export type QueryOrderFinanceRejectReasonsByFiltersArgs = {
  filters: FinanceRejectionReasonFiltersInput;
};


/** Query root */
export type QueryOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderFinancingPlansHistoryArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderIncludedInAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  experimentName: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrdersByAdIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPnlItemArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPnlItemChangelogArgs = {
  retailAdId: Scalars['UUID'];
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPnlItemTypeArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPnlPartnerArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPollValidationStatusArgs = {
  request: PollValidationStatusProjectionInput;
};


/** Query root */
export type QueryPresignedUrlsArgs = {
  request: BatchProjection_AwsS3PresignedUrlRequestDtoInput;
};


/** Query root */
export type QueryProductArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryProductPartnersArgs = {
  category: Scalars['String'];
  country: Scalars['String'];
  type: Scalars['String'];
};


/** Query root */
export type QueryProductTypesArgs = {
  category: Scalars['String'];
  country: Scalars['String'];
};


/** Query root */
export type QueryRefundsWithCreditNotesDistributeArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryRefurbishmentArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentByRefIdArgs = {
  refId: Scalars['String'];
};


/** Query root */
export type QueryRefurbishmentCommentsByRefurbishmentIdArgs = {
  refurbishmentId: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentPartnersArgs = {
  search: PageRequestProjectionInput;
};


/** Query root */
export type QueryRefurbishmentsByRetailAdIdArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentsOverviewArgs = {
  search: PageableRequestProjection_RefurbishmentAggregateSearchDtoInput;
};


/** Query root */
export type QueryRetailAccountDetailsByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAccountDetailsForPaymentArgs = {
  country: Scalars['String'];
  orderId: Scalars['UUID'];
  paymentType: Scalars['String'];
};


/** Query root */
export type QueryRetailAdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdByAdIdArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdDamageArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryRetailAdDeliveryOptionsArgs = {
  branchDeliveryOptionRequest?: InputMaybe<BranchDeliveryOptionRequestDtoInput>;
  homeDeliveryOptionRequest?: InputMaybe<HomeDeliveryOptionRequestDtoInput>;
  orderId?: InputMaybe<Scalars['UUID']>;
  retailAdId: Scalars['UUID'];
  retailCountry?: InputMaybe<Scalars['String']>;
  tradeInCompatibleOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryRetailAdPnlDataArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdPriceHistoryArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  retailAdId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryRetailAdPublishingBlockersArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdSecondaryWheelsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAddressArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailCheckoutArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailClaimCostCompensationsByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailCustomerArgs = {
  orderId: Scalars['UUID'];
  type?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryRetailCustomersByOrderIdsArgs = {
  orderIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QueryRetailFeatureArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailFeatureStatusV2Args = {
  context: RetailFeatureStatusContextDtoInput;
  name: Scalars['String'];
};


/** Query root */
export type QueryRetailFeaturesArgs = {
  context: RetailFeatureStatusContextDtoInput;
};


/** Query root */
export type QueryRetailFeaturesStatusV2Args = {
  context: RetailFeatureStatusContextDtoInput;
  names: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QueryRetailFtsRiskDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailLeadArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailOrderReturnAppointmentArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailPaymentArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailVehiclePreparationArgs = {
  vehiclePreparationId: Scalars['UUID'];
};


/** Query root */
export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRoleGroupsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRoleUsersArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySearchAbTestingExperimentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchAbTestingParticipantsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchAdsByChannelAndSourceIdsArgs = {
  channel: Scalars['String'];
  sourceIds: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QuerySearchAdsByIdsArgs = {
  ids: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QuerySearchBranchEntryChecksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchBranchesArgs = {
  search: PageRequestProjectionInput;
};


/** Query root */
export type QuerySearchBranchesForSelectionArgs = {
  search: BranchForSelectionSearchProjectionInput;
};


/** Query root */
export type QuerySearchCarRegistrationProductArgs = {
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchClaimAssigneeUsersArgs = {
  countries: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QuerySearchCreditNotesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchCustomerUsersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDigitalCarRegistrationsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDiscountsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDocumentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchGroupsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchImageTagsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchInternalTransferPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchInvoicesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderChangelogsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderCommentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderFinancingByOrderArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchOrderFinancingHistoryArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderHistoryEventsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderTasksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrdersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOutboundPaymentArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlItemTypesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlItemsArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlPartnersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefundsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefurbishmentEtAsByRetailAdIdArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchRefurbishmentExitChecksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefurbishmentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailAdPnlDataArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailAdsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailClaimsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailDataImportArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailDataImportsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailFeatureChangelogsArgs = {
  id: Scalars['UUID'];
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailFeaturesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailLeadArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailUnidentifiedPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRolesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchSignatureRequestArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchTaskManagerTasksArgs = {
  search: CamundaSearchProjection2Input;
};


/** Query root */
export type QuerySearchTradeInByStockNumberArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  stockNumber: Scalars['String'];
};


/** Query root */
export type QuerySearchUsersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchVehiclePreparationProductArgs = {
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  search?: InputMaybe<SearchProjection2Input>;
};


/** Query root */
export type QuerySearchWarrantyProductsArgs = {
  ad_id?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySellerAddressArgs = {
  addressId: Scalars['UUID'];
  sellerId: Scalars['UUID'];
};


/** Query root */
export type QuerySurveyScoreArgs = {
  extId: Scalars['UUID'];
  extType: Scalars['String'];
  surveyType: Scalars['String'];
};


/** Query root */
export type QuerySurveyScoresArgs = {
  extId: Scalars['UUID'];
  extType: Scalars['String'];
  surveyTypes: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QueryTaxAdvantageArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryTrustpilotInvitationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryTrustpilotReviewArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryUnidentifiedPaymentByIdArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryVehicleByVinArgs = {
  vin: Scalars['String'];
};


/** Query root */
export type QueryWarrantyArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type RadioDtoInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type RangeDto_Integer = {
  __typename?: 'RangeDTO_Integer';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type RangeDto_IntegerInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type RefundDistributionProjection = {
  __typename?: 'RefundDistributionProjection';
  accountDetails?: Maybe<RetailAccountDetailsCreateProjection>;
  amount?: Maybe<RetailFinancePriceProjection>;
  tempId?: Maybe<Scalars['Int']>;
};

export type RefundDistributionProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  tempId?: InputMaybe<Scalars['Int']>;
};

export type RefundPendingTaskProjection = {
  __typename?: 'RefundPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type RefurbishmentAggregateProjection = {
  __typename?: 'RefurbishmentAggregateProjection';
  adId?: Maybe<Scalars['UUID']>;
  beautyShot?: Maybe<Scalars['String']>;
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  branchName?: Maybe<Scalars['String']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  modelInfo?: Maybe<ModelDto>;
  notificationCount?: Maybe<Scalars['Map_RetailNotificationEntityType_IntegerScalar']>;
  partnerId?: Maybe<Scalars['Int']>;
  partnerName?: Maybe<Scalars['String']>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentLocation?: Maybe<Scalars['String']>;
  refurbishmentPartner?: Maybe<Scalars['String']>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  retailStockCountry?: Maybe<Scalars['String']>;
  sourcingCountry?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
  updatedOn?: Maybe<Scalars['String']>;
  videoUploadedOn?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReason>;
};

export type RefurbishmentAggregateSearchDtoInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  refId?: InputMaybe<Scalars['String']>;
  refurbishmentCancelReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentCompletedReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retailReady?: InputMaybe<Scalars['Boolean']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vinNumber?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentCommentCreateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  refurbishmentId?: InputMaybe<Scalars['UUID']>;
};

export type RefurbishmentCommentProjection = {
  __typename?: 'RefurbishmentCommentProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<AuxUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  refurbishmentTransitionId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RefurbishmentCreateProjectionInput = {
  beautyShotsTakenOn?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  picturesReceivedOn?: InputMaybe<Scalars['String']>;
  picturesUploadedOn?: InputMaybe<Scalars['String']>;
  refurbishmentType?: InputMaybe<Scalars['String']>;
  retailAdId?: InputMaybe<Scalars['UUID']>;
};

export type RefurbishmentDto = {
  __typename?: 'RefurbishmentDTO';
  adDetails?: Maybe<AdDetailsProjection>;
  beautyShot?: Maybe<Scalars['String']>;
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  branchName?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Maybe<BranchProjection>>>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currentPartnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  defaultPartnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  keysCount?: Maybe<Scalars['Int']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  partnerId?: Maybe<Scalars['Int']>;
  partnerName?: Maybe<Scalars['String']>;
  partnerWorkshopConnections?: Maybe<Array<Maybe<PartnerWorkshopConnectionDto>>>;
  partners?: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
  serviceBookType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
  transitions?: Maybe<Array<Maybe<RefurbishmentTransitionDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
  videoUploadedOn?: Maybe<Scalars['String']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReason>;
};

export type RefurbishmentEtaListProjection = {
  __typename?: 'RefurbishmentEtaListProjection';
  etaList?: Maybe<Array<Maybe<RefurbishmentEtaProjection>>>;
};

export type RefurbishmentEtaProjection = {
  __typename?: 'RefurbishmentEtaProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
};

export enum RefurbishmentFlowVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export type RefurbishmentPartnerDto = {
  __typename?: 'RefurbishmentPartnerDTO';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdOn?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  partnerEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RefurbishmentProjection = {
  __typename?: 'RefurbishmentProjection';
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchData?: Maybe<BranchProjection>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  maximumBudget?: Maybe<PriceDto>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  partnerData?: Maybe<RefurbishmentPartnerDto>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  totalCostBudget?: Maybe<PriceDto>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
};

export type RefurbishmentServiceCreateProjectionInput = {
  budgetMinorUnits?: InputMaybe<Scalars['Long']>;
  comment?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  retailAdDamageId?: InputMaybe<Scalars['UUID']>;
  serviceName?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentServiceDto = {
  __typename?: 'RefurbishmentServiceDTO';
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  refurbishmentTransitionId?: Maybe<Scalars['UUID']>;
  relatedServiceId?: Maybe<Scalars['UUID']>;
  retailAdDamageId?: Maybe<Scalars['UUID']>;
  serviceName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateComment?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RefurbishmentServiceUpdateProjectionInput = {
  budgetMinorUnits?: InputMaybe<Scalars['Long']>;
  comment?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentTransitionDto = {
  __typename?: 'RefurbishmentTransitionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  stateFrom?: Maybe<Scalars['String']>;
  stateTo?: Maybe<Scalars['String']>;
  transitionDate?: Maybe<Scalars['String']>;
};

export type RefurbishmentUpdateProjectionInput = {
  beautyShotsTakenOn?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  estimatedCompleteDate?: InputMaybe<Scalars['String']>;
  maximumBudgetMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  picturesReceivedOn?: InputMaybe<Scalars['String']>;
  picturesUploadedOn?: InputMaybe<Scalars['String']>;
  retailReady?: InputMaybe<Scalars['Boolean']>;
  videoUploadedOn?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentUpdateServicesProjectionInput = {
  refurbishmentServiceUpdateProjection?: InputMaybe<RefurbishmentServiceUpdateProjectionInput>;
  serviceId?: InputMaybe<Scalars['UUID']>;
};

export enum RegistrationUserSource {
  AhBackofficeCheckout = 'AH_BACKOFFICE_CHECKOUT',
  AhCheckout = 'AH_CHECKOUT',
  AhComingSoonAdSubscribe = 'AH_COMING_SOON_AD_SUBSCRIBE',
  AhFavourites = 'AH_FAVOURITES',
  AhFinanceFlow = 'AH_FINANCE_FLOW',
  AhGeneral = 'AH_GENERAL',
  AhMakretingRealmadridRaffles = 'AH_MAKRETING_REALMADRID_RAFFLES',
  AhMarketingDtm = 'AH_MARKETING_DTM',
  AhMarketingHertha = 'AH_MARKETING_HERTHA',
  AhOnlineCheckout = 'AH_ONLINE_CHECKOUT',
  AhReservedAdSubscribe = 'AH_RESERVED_AD_SUBSCRIBE',
  AhSavedSearch = 'AH_SAVED_SEARCH',
  AhSearch = 'AH_SEARCH',
  AhSellOnly = 'AH_SELL_ONLY',
  AhSellOnlyFunnel = 'AH_SELL_ONLY_FUNNEL',
  AhTradeIn = 'AH_TRADE_IN'
}

export type RemainingRefundAmountProjection = {
  __typename?: 'RemainingRefundAmountProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
};

export type RepairPartnerDto = {
  __typename?: 'RepairPartnerDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  refurbishmentPartnerId?: Maybe<Scalars['Int']>;
};

export type RepeatHappinessCallTaskManagementProjection = {
  __typename?: 'RepeatHappinessCallTaskManagementProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderHandoverOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type RequestBankDetailsForOutboundPaymentProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentType?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
};

export type ReschedulingCommentProjectionInput = {
  channel?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type ResultConnection_DocumentInventoryItem = {
  __typename?: 'ResultConnection_DocumentInventoryItem';
  nodes: Array<Maybe<DocumentInventoryItem>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_DocumentInventoryPackageProjection = {
  __typename?: 'ResultConnection_DocumentInventoryPackageProjection';
  nodes: Array<Maybe<DocumentInventoryPackageProjection>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_DocumentLocation = {
  __typename?: 'ResultConnection_DocumentLocation';
  nodes: Array<Maybe<DocumentLocation>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_ShippingProviderProjection = {
  __typename?: 'ResultConnection_ShippingProviderProjection';
  nodes: Array<Maybe<ShippingProviderProjection>>;
  totalCount: Scalars['Long'];
};

export type RetailAbTestingConditionVariableProjection = {
  __typename?: 'RetailAbTestingConditionVariableProjection';
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  variable?: Maybe<Scalars['String']>;
};

export type RetailAbTestingExperimentCountersProjection = {
  __typename?: 'RetailAbTestingExperimentCountersProjection';
  counters: Array<Maybe<Scalars['Long']>>;
  totalHits: Scalars['Long'];
};

export type RetailAbTestingExperimentProjection = {
  __typename?: 'RetailAbTestingExperimentProjection';
  condition?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['String']>;
  gaExperimentId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  multiStep: Scalars['Boolean'];
  name: Scalars['String'];
  nextStepVariants?: Maybe<Array<Maybe<RetailAbTestingNextStepVariantProjection>>>;
  startAt: Scalars['String'];
  trafficPercents: Scalars['Int'];
  variants?: Maybe<Array<Maybe<RetailAbTestingVariantProjection>>>;
  viewNames: Array<Maybe<Scalars['String']>>;
};

export type RetailAbTestingNextStepVariantProjection = {
  __typename?: 'RetailAbTestingNextStepVariantProjection';
  gaExperimentId?: Maybe<Scalars['String']>;
  gaVariationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  nextStep?: Maybe<Scalars['String']>;
  skipSteps?: Maybe<Array<Maybe<Scalars['String']>>>;
  weightPercents?: Maybe<Scalars['Int']>;
};

export type RetailAbTestingNextStepVariantProjectionInput = {
  gaExperimentId?: InputMaybe<Scalars['String']>;
  gaVariationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  nextStep?: InputMaybe<Scalars['String']>;
  skipSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weightPercents?: InputMaybe<Scalars['Int']>;
};

export type RetailAbTestingParticipantProjection = {
  __typename?: 'RetailAbTestingParticipantProjection';
  adId?: Maybe<Scalars['UUID']>;
  checkoutId?: Maybe<Scalars['UUID']>;
  experimentId: Scalars['UUID'];
  experimentName: Scalars['String'];
  gaExperimentId: Scalars['String'];
  gaVariationId: Scalars['String'];
  orderId?: Maybe<Scalars['UUID']>;
  participatedOn: Scalars['String'];
  userId?: Maybe<Scalars['UUID']>;
  visitorId: Scalars['String'];
};

export type RetailAbTestingVariantProjection = {
  __typename?: 'RetailAbTestingVariantProjection';
  gaExperimentId?: Maybe<Scalars['String']>;
  gaVariationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  viewParams?: Maybe<Scalars['String']>;
  viewVariant?: Maybe<Scalars['String']>;
  weightPercents?: Maybe<Scalars['Int']>;
};

export type RetailAbTestingVariantProjectionInput = {
  gaExperimentId?: InputMaybe<Scalars['String']>;
  gaVariationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  viewParams?: InputMaybe<Scalars['String']>;
  viewVariant?: InputMaybe<Scalars['String']>;
  weightPercents?: InputMaybe<Scalars['Int']>;
};

export type RetailAccessParamDto = {
  __typename?: 'RetailAccessParamDTO';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailAccountDetailsCreateProjection = {
  __typename?: 'RetailAccountDetailsCreateProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type RetailAccountDetailsCreateProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  bic?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export type RetailAccountDetailsForPaymentProjection = {
  __typename?: 'RetailAccountDetailsForPaymentProjection';
  accountType?: Maybe<Scalars['String']>;
  accounts?: Maybe<Array<Maybe<RetailAccountDetailsWithPaymentsProjection>>>;
};

export type RetailAccountDetailsWithPaymentsProjection = {
  __typename?: 'RetailAccountDetailsWithPaymentsProjection';
  payments?: Maybe<Array<Maybe<RetailPaymentWrapperProjection>>>;
  retailAccount?: Maybe<BankAccountDetailsWithDocumentsProjection>;
  source?: Maybe<Scalars['String']>;
};

export enum RetailAdDamageCancellationReason {
  IncorrectVehiclePart = 'INCORRECT_VEHICLE_PART',
  NotFound = 'NOT_FOUND'
}

export type RetailAdDamageDto = {
  __typename?: 'RetailAdDamageDTO';
  area?: Maybe<Scalars['String']>;
  cancelComment?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<RetailAdDamageCancellationReason>;
  comment?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerDisplay: Scalars['Boolean'];
  damageExtendedId?: Maybe<Scalars['Long']>;
  displayToCustomerFrontend?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  severities?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailAdDamageImageDto = {
  __typename?: 'RetailAdDamageImageDTO';
  displayToCustomerFrontend: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  image?: Maybe<DamageImageDto>;
  part?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailAdDamageProjection = {
  __typename?: 'RetailAdDamageProjection';
  area?: Maybe<Scalars['String']>;
  areaTranslation?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  damageService?: Maybe<RefurbishmentServiceDto>;
  id?: Maybe<Scalars['UUID']>;
  image?: Maybe<ImageProjection>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  part?: Maybe<Scalars['String']>;
  partTranslation?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  service?: Maybe<RefurbishmentServiceDto>;
  severities?: Maybe<Array<Maybe<Scalars['String']>>>;
  severityTranslations?: Maybe<Array<Maybe<SeverityProjection>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  subAreaTranslation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeTranslation?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryAddressDetailsProjection = {
  __typename?: 'RetailAdDeliveryAddressDetailsProjection';
  DELIVERY_RANGE_ETA?: Maybe<Scalars['String']>;
  customerExpectedEta?: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  deliveryType?: Maybe<Scalars['String']>;
  estimationId?: Maybe<Scalars['UUID']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  hubId?: Maybe<Scalars['Int']>;
};

export type RetailAdDeliveryBranchDetailsDto = {
  __typename?: 'RetailAdDeliveryBranchDetailsDTO';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerExpectedEta?: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryETA?: Maybe<IntegerRangeDto>;
  deliveryETADate?: Maybe<Scalars['String']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  deliveryType?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  estimationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['Int']>;
  logisticsEtaInCalendarDays?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryBranchDetailsProjection = {
  __typename?: 'RetailAdDeliveryBranchDetailsProjection';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerExpectedEta?: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryETA?: Maybe<IntegerRangeDto>;
  deliveryETADate?: Maybe<Scalars['LocalDate']>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  distance?: Maybe<Scalars['Float']>;
  estimationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryBranchesDetailsProjection = {
  __typename?: 'RetailAdDeliveryBranchesDetailsProjection';
  branches?: Maybe<Array<Maybe<RetailAdDeliveryBranchDetailsDto>>>;
  distanceWhereBranchesFound?: Maybe<Scalars['Int']>;
  estimationId?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type RetailAdDeliveryOptionProjection = {
  __typename?: 'RetailAdDeliveryOptionProjection';
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  retailAdDeliveryOptionDetails?: Maybe<RetailAdDeliveryOptionDetails>;
  retailAdId?: Maybe<Scalars['UUID']>;
};

export type RetailAdPnlDataProjection = {
  __typename?: 'RetailAdPnlDataProjection';
  classifiedAd?: Maybe<AdProjection>;
  costItems?: Maybe<Array<Maybe<PnlItemProjection>>>;
  order?: Maybe<OrderProjection>;
  retailAd?: Maybe<RetailAdProjection>;
  revenueItems?: Maybe<Array<Maybe<PnlItemProjection>>>;
};

export type RetailAdPriceChangeDto = {
  __typename?: 'RetailAdPriceChangeDTO';
  actionType?: Maybe<Scalars['String']>;
  changeReason?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
  previousPrice?: Maybe<Scalars['String']>;
  priceChangeType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type RetailAdProjection = {
  __typename?: 'RetailAdProjection';
  adId?: Maybe<Scalars['UUID']>;
  auto1BuyPrice?: Maybe<Scalars['Int']>;
  auto1BuyPriceDto?: Maybe<PriceDto>;
  auto1ReturnOn?: Maybe<Scalars['String']>;
  auto1ReturnReason?: Maybe<Scalars['String']>;
  auto1Status?: Maybe<Scalars['Int']>;
  autoheroPurchaseOn?: Maybe<Scalars['String']>;
  contractDetails?: Maybe<Array<Maybe<Scalars['ObjectScalar']>>>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  documentLocation?: Maybe<Scalars['String']>;
  expectedSalesPriceGrossMinorUnits?: Maybe<Scalars['Long']>;
  financedPrice?: Maybe<PriceDto>;
  firstImportOn?: Maybe<Scalars['String']>;
  firstPurchasedOn?: Maybe<Scalars['String']>;
  grossPrice?: Maybe<PriceDto>;
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  hidingOn?: Maybe<Scalars['String']>;
  hidingReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  locationInfo?: Maybe<LocationInfoDto>;
  netPrice?: Maybe<PriceDto>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  retailReady: Scalars['Boolean'];
  retailReadyDate?: Maybe<Scalars['String']>;
  secondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  taxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  test: Scalars['Boolean'];
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatType?: Maybe<Scalars['Int']>;
};

export type RetailAdPublishingBlockerProjection = {
  __typename?: 'RetailAdPublishingBlockerProjection';
  blockers?: Maybe<Array<Maybe<PublishingBlockerProjection>>>;
  retailAdId?: Maybe<Scalars['UUID']>;
};

export type RetailAdSecondaryWheelsCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  costPnlVatKey?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  damageCondition?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  internalRefName?: InputMaybe<Scalars['String']>;
  invoiceGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoiceNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoicePriceVatKey?: InputMaybe<Scalars['String']>;
  invoicePriceVatRate?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<SecondaryWheelsLocation>;
  locationDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostVatRate?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<Scalars['String']>;
  rimsDetails?: InputMaybe<Scalars['Map_WheelPlacementType_RimDetailsProjectionScalar']>;
  sku?: InputMaybe<Scalars['String']>;
  tiresDetails?: InputMaybe<Scalars['Map_WheelPlacementType_TireDetailsProjectionScalar']>;
};

export type RetailAdSecondaryWheelsDetailsUpdateProjectionInput = {
  damageCondition?: InputMaybe<Scalars['String']>;
  invoiceGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  invoicePriceVatRate?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
};

export type RetailAdSecondaryWheelsProjection = {
  __typename?: 'RetailAdSecondaryWheelsProjection';
  adId?: Maybe<Scalars['UUID']>;
  costPnlVatKey?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  damageCondition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Array<Maybe<ImageProjection>>>;
  internalRefName?: Maybe<Scalars['String']>;
  invoiceGrossPrice?: Maybe<RetailFinancePriceProjection>;
  invoiceNetPrice?: Maybe<RetailFinancePriceProjection>;
  invoicePriceVatKey?: Maybe<Scalars['String']>;
  invoicePriceVatRate?: Maybe<Scalars['Float']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isShippingRequired?: Maybe<Scalars['Boolean']>;
  location?: Maybe<SecondaryWheelsLocation>;
  locationDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  pnlCostGrossPrice?: Maybe<RetailFinancePriceProjection>;
  pnlCostNetPrice?: Maybe<RetailFinancePriceProjection>;
  pnlCostVatRate?: Maybe<Scalars['Float']>;
  publishedScope?: Maybe<Scalars['String']>;
  rimsDetails?: Maybe<Scalars['Map_WheelPlacementType_RimDetailsProjectionScalar']>;
  sku?: Maybe<Scalars['String']>;
  tiresDetails?: Maybe<Scalars['Map_WheelPlacementType_TireDetailsProjectionScalar']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailAdTaxAdvantageProjection = {
  __typename?: 'RetailAdTaxAdvantageProjection';
  actualAmount?: Maybe<PriceDto>;
  actualAmountMinorUnits?: Maybe<Scalars['Int']>;
  costAmount?: Maybe<PriceDto>;
  costAmountMinorUnits?: Maybe<Scalars['Int']>;
  currentCountryFirstRegDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  internationalFirstRegDate?: Maybe<Scalars['String']>;
  lastActualAmountCalcDate?: Maybe<Scalars['String']>;
  lastCostAmountCalcDate?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<PriceDto>;
  originalAmountMinorUnits?: Maybe<Scalars['Int']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  taxAdvantageSource?: Maybe<TaxAdvantageSource>;
  taxAdvantageType?: Maybe<ExportTaxAdvantageType>;
  warning?: Maybe<Scalars['String']>;
  warningLevel?: Maybe<Scalars['String']>;
};

export type RetailAdTaxAdvantageUpdateProjectionInput = {
  actualAmountMinorUnits?: InputMaybe<Scalars['Int']>;
  costAmountMinorUnits?: InputMaybe<Scalars['Int']>;
  warning?: InputMaybe<Scalars['String']>;
  warningLevel?: InputMaybe<Scalars['String']>;
};

export type RetailAdUpdateProjectionInput = {
  auto1BuyPrice?: InputMaybe<Scalars['Int']>;
  auto1Status?: InputMaybe<Scalars['Int']>;
  contractDetails?: InputMaybe<Scalars['String']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  netPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  retailCountry?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatType?: InputMaybe<Scalars['Int']>;
};

export type RetailAddressCreateProjectionInput = {
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasPrivateParking?: InputMaybe<Scalars['Boolean']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type RetailAddressDto = {
  __typename?: 'RetailAddressDto';
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPrivateParking?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailAddressProjection = {
  __typename?: 'RetailAddressProjection';
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPrivateParking?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailAddressUpdateProjectionInput = {
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasPrivateParking?: InputMaybe<Scalars['Boolean']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type RetailBatchDto_UuidInput = {
  entities?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type RetailBatchPriceUpdateValidationSummaryDto = {
  __typename?: 'RetailBatchPriceUpdateValidationSummaryDTO';
  processableEntityCount?: Maybe<Scalars['Int']>;
  processableErrorlessEntityCount?: Maybe<Scalars['Int']>;
};

export type RetailCarRegistrationDto = {
  __typename?: 'RetailCarRegistrationDTO';
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsDto>>>;
  publishedScope?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  refId?: Maybe<Scalars['String']>;
  registrationInAnyCountry?: Maybe<Scalars['Boolean']>;
  registrationInRetailCountry?: Maybe<Scalars['Boolean']>;
  registrationType?: Maybe<CarRegistrationType>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailCheckoutAllStepTypesProjection = {
  __typename?: 'RetailCheckoutAllStepTypesProjection';
  steps?: Maybe<Array<Maybe<RetailCheckoutStepTypeProjection>>>;
};

export type RetailCheckoutCreateProjectionInput = {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  source?: InputMaybe<CheckoutSource>;
};

export type RetailCheckoutProjection = {
  __typename?: 'RetailCheckoutProjection';
  completedSteps?: Maybe<Array<Maybe<RetailCheckoutStepProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  source?: Maybe<CheckoutSource>;
};

export type RetailCheckoutStepProjection = {
  __typename?: 'RetailCheckoutStepProjection';
  step?: Maybe<Scalars['String']>;
};

export type RetailCheckoutStepTypeProjection = {
  __typename?: 'RetailCheckoutStepTypeProjection';
  stepName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
};

export type RetailCheckoutUpdateProjectionInput = {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<CheckoutSource>;
};

export type RetailClaimAreaProjection = {
  __typename?: 'RetailClaimAreaProjection';
  carParts?: Maybe<Array<Maybe<RetailClaimCarPartProjection>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimAssigneeDto = {
  __typename?: 'RetailClaimAssigneeDTO';
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type RetailClaimCarPartProjection = {
  __typename?: 'RetailClaimCarPartProjection';
  damages?: Maybe<Array<Maybe<RetailClaimDamageProjection>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimCostCommentCreateProjectionInput = {
  approvalLevel1Comment?: InputMaybe<Scalars['String']>;
  approvalLevel2Comment?: InputMaybe<Scalars['String']>;
  approvalLevel3Comment?: InputMaybe<Scalars['String']>;
  customerDecisionComment?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCostCommentProjection = {
  __typename?: 'RetailClaimCostCommentProjection';
  approvalLevel1Comment?: Maybe<Scalars['String']>;
  approvalLevel2Comment?: Maybe<Scalars['String']>;
  approvalLevel3Comment?: Maybe<Scalars['String']>;
  claimCostId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  customerDecisionComment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimCostCreateProjectionInput = {
  cost?: InputMaybe<Scalars['Long']>;
  costType?: InputMaybe<Scalars['String']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCostPaymentProjection = {
  __typename?: 'RetailClaimCostPaymentProjection';
  claimCostId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  paymentId?: Maybe<Scalars['UUID']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  pnlId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimCostProjection = {
  __typename?: 'RetailClaimCostProjection';
  approvalLevel1?: Maybe<Scalars['String']>;
  approvalLevel1SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel1SetByProjection?: Maybe<UserProjection>;
  approvalLevel1SetOn?: Maybe<Scalars['String']>;
  approvalLevel2?: Maybe<Scalars['String']>;
  approvalLevel2SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel2SetByProjection?: Maybe<UserProjection>;
  approvalLevel2SetOn?: Maybe<Scalars['String']>;
  approvalLevel3?: Maybe<Scalars['String']>;
  approvalLevel3SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel3SetByProjection?: Maybe<UserProjection>;
  approvalLevel3SetOn?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['UUID']>;
  comments?: Maybe<Array<Maybe<RetailClaimCostCommentProjection>>>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  costType?: Maybe<Scalars['String']>;
  costTypeCategory?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  customerDecision?: Maybe<Scalars['String']>;
  customerDecisionSetBy?: Maybe<Scalars['UUID']>;
  customerDecisionSetByProjection?: Maybe<UserProjection>;
  customerDecisionSetOn?: Maybe<Scalars['String']>;
  editState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  number?: Maybe<Scalars['String']>;
  partialRefund?: Maybe<ClaimPartialRefundProjection>;
  payment?: Maybe<RetailClaimCostPaymentProjection>;
  status?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  warrantyCoverageType?: Maybe<Scalars['String']>;
};

export type RetailClaimCostUpdateProjectionInput = {
  cost?: InputMaybe<Scalars['Long']>;
  costType?: InputMaybe<Scalars['String']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCreateProjectionInput = {
  assignTo?: InputMaybe<Scalars['UUID']>;
  claimGroupId?: InputMaybe<Scalars['UUID']>;
  subClaim?: InputMaybe<Array<InputMaybe<RetailSubClaimCreateProjectionInput>>>;
};

export type RetailClaimDamageProjection = {
  __typename?: 'RetailClaimDamageProjection';
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimGroupProjection = {
  __typename?: 'RetailClaimGroupProjection';
  areas?: Maybe<Array<Maybe<RetailClaimAreaProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  liableParties?: Maybe<Array<Maybe<RetailClaimLiablePartyProjection>>>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimLiablePartyProjection = {
  __typename?: 'RetailClaimLiablePartyProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailClaimProjection = {
  __typename?: 'RetailClaimProjection';
  ad?: Maybe<AdProjection>;
  assignTo?: Maybe<Scalars['UUID']>;
  claimGroupId?: Maybe<Scalars['UUID']>;
  closedDescription?: Maybe<Scalars['String']>;
  closedOn?: Maybe<Scalars['String']>;
  closedReason?: Maybe<Scalars['String']>;
  compensationType?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  costs?: Maybe<Array<Maybe<RetailClaimCostProjection>>>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  number?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  proofRequests?: Maybe<Array<Maybe<RetailClaimProofRequestProjection>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subClaims?: Maybe<Array<Maybe<RetailSubClaimProjection>>>;
  updatedOn?: Maybe<Scalars['String']>;
  workshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
};

export type RetailClaimProofRequestProjection = {
  __typename?: 'RetailClaimProofRequestProjection';
  claimId?: Maybe<Scalars['UUID']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailClaimUpdateGroupProjectionInput = {
  claimGroupId?: InputMaybe<Scalars['UUID']>;
};

export type RetailClaimUpdateStateProjectionInput = {
  closedReason?: InputMaybe<ClosedReasonProjectionInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type RetailClaimUpdateUserAssignProjectionInput = {
  assignTo?: InputMaybe<Scalars['UUID']>;
};

export type RetailClaimWorkshopDetailsCreateProjectionInput = {
  address?: InputMaybe<Scalars['String']>;
  carPickedUp?: InputMaybe<Scalars['Boolean']>;
  carPickedUpOn?: InputMaybe<Scalars['String']>;
};

export type RetailClaimWorkshopDetailsProjection = {
  __typename?: 'RetailClaimWorkshopDetailsProjection';
  address?: Maybe<Scalars['String']>;
  carPickedUp?: Maybe<Scalars['Boolean']>;
  carPickedUpOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimWorkshopDetailsUpdateProjectionInput = {
  address?: InputMaybe<Scalars['String']>;
  carPickedUp?: InputMaybe<Scalars['Boolean']>;
  carPickedUpOn?: InputMaybe<Scalars['String']>;
};

export type RetailCreateDocumentProjectionInput = {
  retailDocumentCreateDTO?: InputMaybe<RetailDocumentCreateDtoInput>;
};

export type RetailCreditScoreDataProjection = {
  __typename?: 'RetailCreditScoreDataProjection';
  calculatedOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
};

export type RetailCreditScoreDataProjectionV2 = {
  __typename?: 'RetailCreditScoreDataProjectionV2';
  calculatedOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  monthlyAvgNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
  monthlyNetIncomePerMonth?: Maybe<Array<Maybe<MonthlyNetIncomeProjection>>>;
};

export type RetailCustomerCreateProjectionInput = {
  cityOfBirth?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  departmentOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type RetailCustomerExpectedEtaDto = {
  __typename?: 'RetailCustomerExpectedEtaDTO';
  customerExpectedMaxEta?: Maybe<Scalars['String']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType?: Maybe<Scalars['String']>;
  estimationId?: Maybe<Scalars['UUID']>;
  logisticsEtaInCalendarDays?: Maybe<Scalars['Int']>;
};

export type RetailCustomerProjection = {
  __typename?: 'RetailCustomerProjection';
  cityOfBirth?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  departmentOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  phone?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  taxCodeValidationState?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type RetailCustomerUpdateProjectionInput = {
  cityOfBirth?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  departmentOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  taxCodeValidationState?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type RetailCustomersByOrderProjection = {
  __typename?: 'RetailCustomersByOrderProjection';
  customers?: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type RetailDataImportDto = {
  __typename?: 'RetailDataImportDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  dataImportType?: Maybe<RetailDataImportType>;
  errorRows?: Maybe<Scalars['Int']>;
  errorsResourceLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  provider?: Maybe<RetailDataImportProvider>;
  resourceLocation?: Maybe<Scalars['String']>;
  state?: Maybe<RetailDataImportState>;
  successRows?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailDataImportProjection = {
  __typename?: 'RetailDataImportProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  errorRows?: Maybe<Scalars['Int']>;
  errorsResourceLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  provider?: Maybe<RetailDataImportProvider>;
  resourceLocation?: Maybe<Scalars['String']>;
  state?: Maybe<RetailDataImportState>;
  successRows?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export enum RetailDataImportProvider {
  AwsS3 = 'AWS_S3'
}

export enum RetailDataImportState {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Validated = 'VALIDATED',
  Validating = 'VALIDATING'
}

export enum RetailDataImportType {
  BulkPriceUpdate = 'BULK_PRICE_UPDATE',
  CarRegistrationAction = 'CAR_REGISTRATION_ACTION',
  DadCarRegistration = 'DAD_CAR_REGISTRATION',
  InventorySourcing = 'INVENTORY_SOURCING',
  Pnl = 'PNL',
  RetailDocumentInventory = 'RETAIL_DOCUMENT_INVENTORY',
  RetailDocumentPackage = 'RETAIL_DOCUMENT_PACKAGE',
  RetailPayment = 'RETAIL_PAYMENT'
}

export type RetailDeliveryRouteItemDto = {
  __typename?: 'RetailDeliveryRouteItemDTO';
  city?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  itemType?: Maybe<RetailDeliveryRouteItemType>;
  state?: Maybe<RetailDeliveryRouteState>;
};

export enum RetailDeliveryRouteItemType {
  DeliveryToLogisticsCenter = 'DELIVERY_TO_LOGISTICS_CENTER',
  DeliveryToTargetBranch = 'DELIVERY_TO_TARGET_BRANCH',
  PickupAppointmentScheduling = 'PICKUP_APPOINTMENT_SCHEDULING',
  PreparationForShipment = 'PREPARATION_FOR_SHIPMENT',
  Shipment = 'SHIPMENT'
}

export enum RetailDeliveryRouteState {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export type RetailDigitalCarRegistrationAssigneeDto = {
  __typename?: 'RetailDigitalCarRegistrationAssigneeDTO';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type RetailDigitalCarRegistrationEnabledProjection = {
  __typename?: 'RetailDigitalCarRegistrationEnabledProjection';
  enabled: Scalars['Boolean'];
};

export type RetailDisputeProjection = {
  __typename?: 'RetailDisputeProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  disputeReason?: Maybe<Scalars['String']>;
  disputeStatus?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventSuccess?: Maybe<Scalars['Boolean']>;
};

export type RetailDisputeProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  disputeReason?: InputMaybe<Scalars['String']>;
  disputeStatus?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['String']>;
  eventSuccess?: InputMaybe<Scalars['Boolean']>;
};

export type RetailDocumentCategoryDto = {
  __typename?: 'RetailDocumentCategoryDTO';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  topCategory?: Maybe<Scalars['String']>;
};

export type RetailDocumentCategoryProjection = {
  __typename?: 'RetailDocumentCategoryProjection';
  documentTypes?: Maybe<Array<Maybe<RetailDocumentTypeProjection>>>;
  name?: Maybe<Scalars['String']>;
};

export type RetailDocumentCreateDtoInput = {
  attachedTo?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  documentTypeKey?: InputMaybe<Scalars['String']>;
  documentUploadSourceType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
};

export type RetailDocumentInventoryImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type RetailDocumentPackageUpdateImportProjectionInput = {
  fileName: Scalars['String'];
};

export type RetailDocumentProjection = {
  __typename?: 'RetailDocumentProjection';
  attachedTo?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['UUID']>;
  deletedByUser?: Maybe<UserProjection>;
  deletedOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  signatureExternalId?: Maybe<Scalars['UUID']>;
  signatureProvider?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['Boolean']>;
  signedBy?: Maybe<Scalars['UUID']>;
  signedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  verifiedBy?: Maybe<Scalars['UUID']>;
  verifiedByUser?: Maybe<UserProjection>;
  verifiedOn?: Maybe<Scalars['String']>;
};

export type RetailDocumentTopCategoryProjection = {
  __typename?: 'RetailDocumentTopCategoryProjection';
  categories?: Maybe<Array<Maybe<RetailDocumentCategoryProjection>>>;
  topCategory?: Maybe<Scalars['String']>;
};

export type RetailDocumentTypeDto = {
  __typename?: 'RetailDocumentTypeDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  retailDocumentCategoryDTO?: Maybe<RetailDocumentCategoryDto>;
};

export type RetailDocumentTypeProjection = {
  __typename?: 'RetailDocumentTypeProjection';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailDocumentUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentUploadSourceType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type RetailExperianDataProjection = {
  __typename?: 'RetailExperianDataProjection';
  calculatedOn?: Maybe<Scalars['String']>;
  experianCreditScore?: Maybe<Scalars['Int']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
};

export type RetailFeatureProjection = {
  __typename?: 'RetailFeatureProjection';
  condition?: Maybe<Scalars['Map_String_ObjectScalar']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedByUser?: Maybe<RetailUserDto>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailFeatureStatusContextDtoInput = {
  country?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  featureUsageDate?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type RetailFeatureStatusDto = {
  __typename?: 'RetailFeatureStatusDTO';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailFeatureStatusProjectionV2 = {
  __typename?: 'RetailFeatureStatusProjectionV2';
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type RetailFinancePriceProjection = {
  __typename?: 'RetailFinancePriceProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type RetailFinancePriceProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
};

export type RetailFinancingPlanBreakdownProjection = {
  __typename?: 'RetailFinancingPlanBreakdownProjection';
  balloonPayment?: Maybe<Scalars['Long']>;
  balloonPaymentNoRounding?: Maybe<Scalars['Long']>;
  installmentsSum?: Maybe<Scalars['Long']>;
  interestSum?: Maybe<Scalars['Long']>;
  monthlyPayments?: Maybe<Array<Maybe<RetailFinancingPlanMonthlyPaymentProjection>>>;
  monthlyRate?: Maybe<Scalars['Long']>;
  totalRepayment?: Maybe<Scalars['Long']>;
};

export type RetailFinancingPlanCalculationProjection = {
  __typename?: 'RetailFinancingPlanCalculationProjection';
  createdOn?: Maybe<Scalars['String']>;
  financingAmountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  isSelectedForRetailRiskCalculation?: Maybe<Scalars['Boolean']>;
  maturity?: Maybe<Scalars['Int']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  retailFinancingTiers?: Maybe<Array<Maybe<RetailFinancingTierProjection>>>;
};

export type RetailFinancingPlanMonthlyPaymentProjection = {
  __typename?: 'RetailFinancingPlanMonthlyPaymentProjection';
  dueDate?: Maybe<Scalars['String']>;
  installmentRepaymentAmount?: Maybe<Scalars['Long']>;
  interestRateAmount?: Maybe<Scalars['Long']>;
  number?: Maybe<Scalars['Int']>;
  rateAmount?: Maybe<Scalars['Long']>;
  remainingDebtAmount?: Maybe<Scalars['Long']>;
};

export type RetailFinancingPlanProjection = {
  __typename?: 'RetailFinancingPlanProjection';
  debitInterest?: Maybe<Scalars['BigDecimal']>;
  effectiveAnnualInterest?: Maybe<Scalars['BigDecimal']>;
  monthlyInstalmentRate?: Maybe<Scalars['Long']>;
  totalLoanAmountWithInterest?: Maybe<Scalars['Long']>;
};

export type RetailFinancingTierProductProjection = {
  __typename?: 'RetailFinancingTierProductProjection';
  id: Scalars['UUID'];
  monthlyPayment: Scalars['Long'];
  price: Scalars['Long'];
  tierId: Scalars['UUID'];
  type: Scalars['String'];
};

export type RetailFinancingTierProjection = {
  __typename?: 'RetailFinancingTierProjection';
  additionalCash?: Maybe<Scalars['Long']>;
  apr?: Maybe<Scalars['Float']>;
  balloonPayment?: Maybe<Scalars['Long']>;
  balloonPercent?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  initialLoanAmount?: Maybe<Scalars['Long']>;
  interestRate?: Maybe<Scalars['Float']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  maturity?: Maybe<Scalars['Int']>;
  maxLoanAmount?: Maybe<Scalars['Long']>;
  monthlyPayment?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  parentTierId?: Maybe<Scalars['UUID']>;
  presentValue?: Maybe<Scalars['Long']>;
  products: Array<Maybe<RetailFinancingTierProductProjection>>;
  riskScore?: Maybe<Scalars['Float']>;
  totalPaymentAmount?: Maybe<Scalars['Long']>;
};

export type RetailFtsRiskDataProjection = {
  __typename?: 'RetailFtsRiskDataProjection';
  accountHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  finishedOn?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  riskData?: Maybe<RiskData>;
  transactionId?: Maybe<Scalars['String']>;
};

export type RetailFullFtsDataProjection = {
  __typename?: 'RetailFullFtsDataProjection';
  accountHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  creditRiskDataDTO?: Maybe<CreditRiskDataDto>;
  finishedOn?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type RetailGroupDto = {
  __typename?: 'RetailGroupDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RetailRoleDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<RetailUserDto>>>;
};

export type RetailImageTagCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  parentImageId?: InputMaybe<Scalars['UUID']>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
};

export type RetailImageTagProjection = {
  __typename?: 'RetailImageTagProjection';
  adId?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  imageId?: Maybe<Scalars['UUID']>;
  parentImageId?: Maybe<Scalars['UUID']>;
  position?: Maybe<PositionDto>;
  type?: Maybe<TagType>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailImageTagUpdateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  parentImageId?: InputMaybe<Scalars['UUID']>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
};

export enum RetailInternalTransferPaymentCategory {
  Chargeback = 'CHARGEBACK',
  DuplicateFinancingAmount = 'DUPLICATE_FINANCING_AMOUNT',
  FinancingKickback = 'FINANCING_KICKBACK',
  Internal = 'INTERNAL',
  Legal = 'LEGAL',
  Logistic = 'LOGISTIC',
  NotApplicablePayment = 'NOT_APPLICABLE_PAYMENT',
  OnHold = 'ON_HOLD',
  OutgoingRefund = 'OUTGOING_REFUND',
  Overpayment = 'OVERPAYMENT',
  RatenkaufFinancing = 'RATENKAUF_FINANCING',
  Refund = 'REFUND',
  RejectedOutboundPayment = 'REJECTED_OUTBOUND_PAYMENT'
}

export type RetailInternalTransferPaymentProjection = {
  __typename?: 'RetailInternalTransferPaymentProjection';
  accountData?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<RetailFinancePriceProjection>;
  category?: Maybe<RetailInternalTransferPaymentCategory>;
  countryCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  retailTransactionId?: Maybe<Scalars['UUID']>;
  status?: Maybe<RetailPaymentStatus>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailInvoiceCreateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  vatKey?: InputMaybe<Scalars['String']>;
};

export type RetailLeadCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  auxUserId?: InputMaybe<Scalars['UUID']>;
  contactType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstContactAt?: InputMaybe<Scalars['String']>;
  leadType?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['UUID']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars['String']>;
  rejectType?: InputMaybe<Scalars['String']>;
  requestedTime?: InputMaybe<Scalars['String']>;
  salesNote?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailLeadProjection = {
  __typename?: 'RetailLeadProjection';
  adId?: Maybe<Scalars['UUID']>;
  auxUserId?: Maybe<Scalars['UUID']>;
  contactType?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstContactAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  leadType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<PhoneNumber>;
  platform?: Maybe<Scalars['String']>;
  rejectType?: Maybe<Scalars['String']>;
  requestedTime?: Maybe<Scalars['String']>;
  salesNote?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailLeadProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  auxUserId?: InputMaybe<Scalars['UUID']>;
  contactType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstContactAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  leadType?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['UUID']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars['String']>;
  rejectType?: InputMaybe<Scalars['String']>;
  requestedTime?: InputMaybe<Scalars['String']>;
  salesNote?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailManualFullEvaluationProjectionInput = {
  amount?: InputMaybe<AmountInfoInput>;
  hasCreditCard: Scalars['Boolean'];
  riskProvider?: InputMaybe<Scalars['String']>;
  riskScore?: InputMaybe<Scalars['String']>;
};

export type RetailManualFullEvaluationProjectionV2Input = {
  currency?: InputMaybe<Scalars['String']>;
  hasCreditCard: Scalars['Boolean'];
  monthlyNetIncomePerMonth?: InputMaybe<Array<InputMaybe<MonthlyNetIncomeProjectionInput>>>;
  riskProvider?: InputMaybe<Scalars['String']>;
  riskScore?: InputMaybe<Scalars['String']>;
};

export type RetailOrderAccountDetailsCreateProjectionInput = {
  account?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  description?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type RetailOrderAccountDetailsProjection = {
  __typename?: 'RetailOrderAccountDetailsProjection';
  account?: Maybe<BankAccountDetailsProjection>;
  description?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type RetailOrderAutoCancellationDto = {
  __typename?: 'RetailOrderAutoCancellationDTO';
  applicability?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  plannedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type RetailOrderCarRegistrationDto = {
  __typename?: 'RetailOrderCarRegistrationDTO';
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailOrderDto = {
  __typename?: 'RetailOrderDTO';
  amountInWords?: Maybe<Scalars['String']>;
  autoCancellation?: Maybe<RetailOrderAutoCancellationDto>;
  auxUserId?: Maybe<Scalars['UUID']>;
  branchCheckupOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelRequest?: Maybe<CancelRequestDto>;
  cancelRequestSubreason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  canceledOn?: Maybe<Scalars['String']>;
  carRegistration?: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationStatus?: Maybe<CarRegistrationStatusDictionaryDto>;
  checkoutBranchId?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  contractSentOn?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['Boolean']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  conversionType?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  creditAmount?: Maybe<PriceDto>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  customerCountry?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  delivery?: Maybe<RetailOrderDeliveryDto>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryRoute?: Maybe<Array<Maybe<RetailDeliveryRouteItemDto>>>;
  depositAmount?: Maybe<PriceDto>;
  depositAmountMinorUnits?: Maybe<Scalars['Long']>;
  depositDueDate?: Maybe<Scalars['String']>;
  depositPaidOn?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  documentHandoverOn?: Maybe<Scalars['String']>;
  finalDocumentHandoverOn?: Maybe<Scalars['String']>;
  finalPaymentDueDate?: Maybe<Scalars['String']>;
  financing?: Maybe<OrderFinancingDto>;
  financingType?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isBranchCheckupSuccessful?: Maybe<Scalars['Boolean']>;
  isCarInBranch?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isOracle?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTradeIn?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<RetailOrderItemDto>>>;
  locale?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  overallDelayCalendarDays?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['UUID']>;
  paidCashAmount?: Maybe<PriceDto>;
  paidOn?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  plannedCarHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  plannedCarHandoverOn?: Maybe<Scalars['String']>;
  remainingAmount?: Maybe<PriceDto>;
  reservationEndOn?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceNet?: Maybe<PriceDto>;
  totalTaxAdvantage?: Maybe<PriceDto>;
  totalVat?: Maybe<PriceDto>;
  transportOrdered?: Maybe<Scalars['String']>;
  trialExpiredOn?: Maybe<Scalars['String']>;
  trialReturnOn?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vehicleDeliveredOn?: Maybe<Scalars['String']>;
  visibleForCustomerWhenCancelled?: Maybe<Scalars['Boolean']>;
  yellowPlatesRequired?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryDto = {
  __typename?: 'RetailOrderDeliveryDTO';
  branchPlannedCarHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  customerExpectedMaxETA?: Maybe<Scalars['String']>;
  customerExpectedMaxETACalculatedOn?: Maybe<Scalars['String']>;
  customerExpectedMaxETAChangeCount?: Maybe<Scalars['Int']>;
  delayReason?: Maybe<Scalars['String']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryDateType?: Maybe<Scalars['String']>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  initialCustomerExpectedMaxETA?: Maybe<Scalars['String']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryDtoInput = {
  branchPlannedCarHandoverLastDefinedOn?: InputMaybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  customerExpectedMaxETA?: InputMaybe<Scalars['String']>;
  customerExpectedMaxETACalculatedOn?: InputMaybe<Scalars['String']>;
  customerExpectedMaxETAChangeCount?: InputMaybe<Scalars['Int']>;
  delayReason?: InputMaybe<Scalars['String']>;
  deliveryAddressChangeApplied?: InputMaybe<Scalars['Boolean']>;
  deliveryDateType?: InputMaybe<Scalars['String']>;
  deliveryETA?: InputMaybe<RangeDto_IntegerInput>;
  deliveryOption?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  initialCustomerExpectedMaxETA?: InputMaybe<Scalars['String']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverLastDefinedOn?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
  tradeInCompatible?: InputMaybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryDetailsProjection = {
  __typename?: 'RetailOrderDeliveryDetailsProjection';
  activeRouteBasedETA?: Maybe<Scalars['String']>;
  branchDeliverySelectionCriteria?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  customerExpectedMaxETA?: Maybe<Scalars['String']>;
  customerExpectedMaxETAChangeCount?: Maybe<Scalars['Int']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryETAType?: Maybe<Scalars['String']>;
  deliveryLocationType?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  handoverDateSource?: Maybe<Scalars['String']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  initialCustomerExpectedMaxETA?: Maybe<Scalars['String']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  retailDeliveryOption?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryProjection = {
  __typename?: 'RetailOrderDeliveryProjection';
  branchDeliverySelectionCriteria?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryETA?: Maybe<DeliveryEta>;
  deliveryLocationType?: Maybe<Scalars['String']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  retailDeliveryOption?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryReschedulingReasonDto = {
  __typename?: 'RetailOrderDeliveryReschedulingReasonDTO';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum RetailOrderFinanceCancelReason {
  Chargeback = 'CHARGEBACK',
  CustomerPayedWithCash = 'CUSTOMER_PAYED_WITH_CASH',
  Expired = 'EXPIRED',
  FinanceApplicationRevoked = 'FINANCE_APPLICATION_REVOKED',
  NewFinanceApplication = 'NEW_FINANCE_APPLICATION',
  NoDocumentsProvided = 'NO_DOCUMENTS_PROVIDED',
  OrderRevoked = 'ORDER_REVOKED',
  Recreated = 'RECREATED',
  VehicleNoLongerAvailable = 'VEHICLE_NO_LONGER_AVAILABLE'
}

export enum RetailOrderFinanceRejectReason {
  AccountOverdrawn = 'ACCOUNT_OVERDRAWN',
  CreditScoreTooLow = 'CREDIT_SCORE_TOO_LOW',
  CustomerNameMismatch = 'CUSTOMER_NAME_MISMATCH',
  CustomerUnemployed = 'CUSTOMER_UNEMPLOYED',
  DuplicateIban = 'DUPLICATE_IBAN',
  ExperianNoResult = 'EXPERIAN_NO_RESULT',
  ExperianTechnicalProblem = 'EXPERIAN_TECHNICAL_PROBLEM',
  Fraud = 'FRAUD',
  FraudIban = 'FRAUD_IBAN',
  GamblingExpenses = 'GAMBLING_EXPENSES',
  HighDebt = 'HIGH_DEBT',
  HighFinancingAmount = 'HIGH_FINANCING_AMOUNT',
  IncomeMonth = 'INCOME_MONTH',
  LengthOfEmployment = 'LENGTH_OF_EMPLOYMENT',
  LowDeposit = 'LOW_DEPOSIT',
  LowIncome = 'LOW_INCOME',
  LowSalary = 'LOW_SALARY',
  NoEligiblePlan = 'NO_ELIGIBLE_PLAN',
  RejectedByBank = 'REJECTED_BY_BANK',
  SchufaCustomerIsNotKnown = 'SCHUFA_CUSTOMER_IS_NOT_KNOWN',
  SchufaElectronicReportNotPossible = 'SCHUFA_ELECTRONIC_REPORT_NOT_POSSIBLE',
  SchufaInvalidUserData = 'SCHUFA_INVALID_USER_DATA',
  SchufaManualProcessingNotWanted = 'SCHUFA_MANUAL_PROCESSING_NOT_WANTED',
  SchufaManualProcessingRequired = 'SCHUFA_MANUAL_PROCESSING_REQUIRED',
  SchufaNoInformationForKnownCustomer = 'SCHUFA_NO_INFORMATION_FOR_KNOWN_CUSTOMER',
  SchufaNoResult = 'SCHUFA_NO_RESULT',
  SchufaScoreErrors = 'SCHUFA_SCORE_ERRORS',
  SchufaTechnicalProblem = 'SCHUFA_TECHNICAL_PROBLEM',
  SchufaUnknownError = 'SCHUFA_UNKNOWN_ERROR',
  TemporaryContract = 'TEMPORARY_CONTRACT',
  Unemployed = 'UNEMPLOYED',
  VehicleTooOld = 'VEHICLE_TOO_OLD'
}

export enum RetailOrderFinancingFlowType {
  AhDigital = 'AH_DIGITAL',
  AhManual = 'AH_MANUAL',
  Manual = 'MANUAL'
}

export type RetailOrderHandoverProjection = {
  __typename?: 'RetailOrderHandoverProjection';
  allowedWithoutFullPayment?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  onDeliveryPaymentType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentShouldBeDoneOnDelivery?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderItemDto = {
  __typename?: 'RetailOrderItemDTO';
  cartId?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  pnlCostPriceGross?: Maybe<PriceDto>;
  pnlCostPriceNet?: Maybe<PriceDto>;
  pnlCostPriceVatAmount?: Maybe<PriceDto>;
  pnlCostVatRate?: Maybe<Scalars['Float']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  taxAdvantageAmountMinorUnits?: Maybe<Scalars['Long']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailOrderRefundsDistributeProjectionInput = {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type RetailOrderRefundsDistributionProjection = {
  __typename?: 'RetailOrderRefundsDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  claimDetails?: Maybe<ClaimDetailsProjection>;
  creditNoteOutboundPaymentRefs?: Maybe<Array<Maybe<CreditNoteOutboundPaymentRefDistributionProjection>>>;
  creditNotes?: Maybe<Array<Maybe<CreditNoteDistributionProjection>>>;
  inboundOutboundPaymentRefs?: Maybe<Array<Maybe<InboundOutboundPaymentRefDistributionProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
  refunds?: Maybe<Array<Maybe<RefundDistributionProjection>>>;
};

export type RetailOrderRefundsDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteOutboundPaymentRefs?: InputMaybe<Array<InputMaybe<CreditNoteOutboundPaymentRefDistributionProjectionInput>>>;
  creditNotes?: InputMaybe<Array<InputMaybe<CreditNoteDistributionProjectionInput>>>;
  inboundOutboundPaymentRefs?: InputMaybe<Array<InputMaybe<InboundOutboundPaymentRefDistributionProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  refunds?: InputMaybe<Array<InputMaybe<RefundDistributionProjectionInput>>>;
};

export type RetailOrderReturnAppointmentProjection = {
  __typename?: 'RetailOrderReturnAppointmentProjection';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderDeliveryId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  plannedCarReturnDate?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
};

export type RetailOrderStatisticsDto = {
  __typename?: 'RetailOrderStatisticsDTO';
  ordersCTR?: Maybe<Scalars['Float']>;
  ordersPerDay?: Maybe<Scalars['Float']>;
  ordersPerWeek?: Maybe<Scalars['Float']>;
  totalOrders?: Maybe<Scalars['Long']>;
  totalOrdersCanceled?: Maybe<Scalars['Long']>;
  totalOrdersConfirmed?: Maybe<Scalars['Long']>;
};

export type RetailOutboundPaymentProjection = {
  __typename?: 'RetailOutboundPaymentProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteRefs?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  inboundPaymentRefs?: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  oraclePaymentDetails?: Maybe<OutboundPaymentOracleDetailsProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentType?: Maybe<Scalars['String']>;
  purpose?: Maybe<RetailOutboundPaymentPurpose>;
  reason?: Maybe<RetailOutboundPaymentReasonProjection>;
  status?: Maybe<RetailOutboundPaymentStatus>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export enum RetailOutboundPaymentPurpose {
  Chargeback = 'CHARGEBACK',
  CreditNoteRefund = 'CREDIT_NOTE_REFUND',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  Overpayment = 'OVERPAYMENT',
  TradeInOverpayment = 'TRADE_IN_OVERPAYMENT'
}

export enum RetailOutboundPaymentReason {
  AdyenChargeback = 'ADYEN_CHARGEBACK',
  CompensationDelay = 'COMPENSATION_DELAY',
  CompensationGeneral = 'COMPENSATION_GENERAL',
  CustomerChangedBankAccount = 'CUSTOMER_CHANGED_BANK_ACCOUNT',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  LawyerRequest = 'LAWYER_REQUEST',
  Other = 'OTHER',
  TradeInWithoutBankDetails = 'TRADE_IN_WITHOUT_BANK_DETAILS'
}

export type RetailOutboundPaymentReasonProjection = {
  __typename?: 'RetailOutboundPaymentReasonProjection';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<RetailOutboundPaymentReason>;
};

export type RetailOutboundPaymentReasonProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RetailOutboundPaymentReason>;
};

export enum RetailOutboundPaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RetailOutboundPaymentUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentCreateProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalToken?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum RetailPaymentCreationSource {
  AutomatedBatchImport = 'AUTOMATED_BATCH_IMPORT',
  DeutscheBankApi = 'DEUTSCHE_BANK_API',
  ExternalPaymentSystem = 'EXTERNAL_PAYMENT_SYSTEM',
  ManuallyCreated = 'MANUALLY_CREATED',
  ManualBatchImporter = 'MANUAL_BATCH_IMPORTER'
}

export type RetailPaymentDataDto = {
  __typename?: 'RetailPaymentDataDTO';
  accountHolder?: Maybe<Scalars['String']>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankReferenceNumber?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  customerPaymentReference?: Maybe<Scalars['String']>;
  endToEndReference?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  orderNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
};

export type RetailPaymentImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentOracleDetailsWrapperProjection = {
  __typename?: 'RetailPaymentOracleDetailsWrapperProjection';
  accountingDate?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailPaymentProjection = {
  __typename?: 'RetailPaymentProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<RetailFinancePriceProjection>;
  countryCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<RetailPaymentCreationSource>;
  disputes?: Maybe<Array<Maybe<RetailDisputeProjection>>>;
  externalId?: Maybe<Scalars['String']>;
  externalPaymentSystem?: Maybe<ExternalPaymentSystem>;
  externalToken?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentDate?: Maybe<Scalars['String']>;
  remainingRefundAmount?: Maybe<RemainingRefundAmountProjection>;
  retailTransactionId?: Maybe<Scalars['UUID']>;
  retailTransactionStatus?: Maybe<Scalars['String']>;
  status?: Maybe<RetailPaymentStatus>;
  successDuplicateByAdyen?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailPaymentProjectionInput = {
  accountDetails?: InputMaybe<BankAccountDetailsProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['String']>;
  creationSource?: InputMaybe<RetailPaymentCreationSource>;
  disputes?: InputMaybe<Array<InputMaybe<RetailDisputeProjectionInput>>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalPaymentSystem?: InputMaybe<ExternalPaymentSystem>;
  externalToken?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  retailTransactionId?: InputMaybe<Scalars['UUID']>;
  retailTransactionStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RetailPaymentStatus>;
  successDuplicateByAdyen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['UUID']>;
  updatedOn?: InputMaybe<Scalars['String']>;
};

export enum RetailPaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RetailPaymentUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentWrapperProjection = {
  __typename?: 'RetailPaymentWrapperProjection';
  accountingDate?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentOracleDetails?: Maybe<RetailPaymentOracleDetailsWrapperProjection>;
  paymentType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailPermissionDto = {
  __typename?: 'RetailPermissionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailPricingCostDto = {
  __typename?: 'RetailPricingCostDTO';
  amountMinorUnits?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  note?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailPricingCostType?: Maybe<RetailPricingCostType>;
};

export enum RetailPricingCostType {
  EstimatedRepairMaintenance = 'ESTIMATED_REPAIR_MAINTENANCE',
  EstimatedRepairMechanical = 'ESTIMATED_REPAIR_MECHANICAL',
  EstimatedRepairOptical = 'ESTIMATED_REPAIR_OPTICAL',
  EstimatedRepairOther = 'ESTIMATED_REPAIR_OTHER'
}

export type RetailProductDto = {
  __typename?: 'RetailProductDTO';
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  depositApplicable?: Maybe<Scalars['Boolean']>;
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<PriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<PriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<PriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable?: Maybe<Scalars['Boolean']>;
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailProductProjection = {
  __typename?: 'RetailProductProjection';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<PriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<PriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<PriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailRefundCreateProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits: Scalars['Long'];
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type RetailRefundProjection = {
  __typename?: 'RetailRefundProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentId?: Maybe<Scalars['UUID']>;
  status?: Maybe<RetailRefundStatus>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailRefundRetryProjectionInput = {
  amountMinorUnits: Scalars['Long'];
};

export enum RetailRefundStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RetailRefundUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
};

export type RetailRefurbishmentStateChangeDtoInput = {
  cancelDescription?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  completedReason?: InputMaybe<Scalars['String']>;
  lastTransitionId?: InputMaybe<Scalars['UUID']>;
  sourceType?: InputMaybe<Scalars['String']>;
  stateTo?: InputMaybe<Scalars['String']>;
  transitionDate?: InputMaybe<Scalars['String']>;
};

export type RetailRoleDto = {
  __typename?: 'RetailRoleDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<RetailGroupDto>>>;
  id?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<RetailPermissionDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailRouteAddressProjection = {
  __typename?: 'RetailRouteAddressProjection';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailRouteItemProjection = {
  __typename?: 'RetailRouteItemProjection';
  city?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  eta?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  plannedCarHandoverOn?: Maybe<Scalars['String']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type RetailSubClaimCommentCreateProjectionInput = {
  sourceType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  visibleForCustomer?: InputMaybe<Scalars['Boolean']>;
};

export type RetailSubClaimCommentProjection = {
  __typename?: 'RetailSubClaimCommentProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  subClaimId?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
  visibleForCustomer?: Maybe<Scalars['Boolean']>;
};

export type RetailSubClaimCreateDocumentProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSizeKb?: InputMaybe<Scalars['Int']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  visibleForCustomer?: InputMaybe<Scalars['Boolean']>;
};

export type RetailSubClaimCreateProjectionInput = {
  areaId?: InputMaybe<Scalars['UUID']>;
  carPartId?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<RetailSubClaimCommentCreateProjectionInput>;
  damageId?: InputMaybe<Scalars['UUID']>;
  liablePartyType?: InputMaybe<Scalars['String']>;
};

export type RetailSubClaimDocumentProjection = {
  __typename?: 'RetailSubClaimDocumentProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  documentTypeId?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  fileSizeKb?: Maybe<Scalars['Int']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  subClaimId?: Maybe<Scalars['UUID']>;
  visibleForCustomer?: Maybe<Scalars['Boolean']>;
};

export type RetailSubClaimProjection = {
  __typename?: 'RetailSubClaimProjection';
  areaId?: Maybe<Scalars['UUID']>;
  carPartId?: Maybe<Scalars['UUID']>;
  claimId?: Maybe<Scalars['UUID']>;
  comments?: Maybe<Array<Maybe<RetailSubClaimCommentProjection>>>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  createdOnMillis?: Maybe<Scalars['Long']>;
  damageId?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<RetailSubClaimDocumentProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  liablePartyType?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailSubClaimUpdateProjectionInput = {
  areaId?: InputMaybe<Scalars['UUID']>;
  carPartId?: InputMaybe<Scalars['UUID']>;
  damageId?: InputMaybe<Scalars['UUID']>;
  liablePartyType?: InputMaybe<Scalars['String']>;
};

export type RetailSurveyScoreProjection = {
  __typename?: 'RetailSurveyScoreProjection';
  score?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
};

export type RetailTaxNoVaProjection = {
  __typename?: 'RetailTaxNoVAProjection';
  applicable: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  note?: Maybe<Scalars['String']>;
};

export type RetailTaxNoVaUpdateProjectionInput = {
  applicable?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
};

export type RetailTradeInCreateProjection = {
  __typename?: 'RetailTradeInCreateProjection';
  accountingDate?: Maybe<Scalars['String']>;
  carleadId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  onsitePrice?: Maybe<Scalars['Long']>;
  price?: Maybe<RetailFinancePriceProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  subModel?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTradeInCreateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  carleadId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  onsitePrice?: InputMaybe<Scalars['Long']>;
  price?: InputMaybe<RetailFinancePriceProjectionInput>;
  stockNumber?: InputMaybe<Scalars['String']>;
  subModel?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type RetailTradeInExtendedProjection = {
  __typename?: 'RetailTradeInExtendedProjection';
  accountingDate?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  attachable?: Maybe<Scalars['Boolean']>;
  carleadId?: Maybe<Scalars['String']>;
  confirmedFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  costGrossPNL?: Maybe<Scalars['Long']>;
  costPNLVatPercentage?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  onsitePriceMinorUnits?: Maybe<Scalars['Long']>;
  outstandingFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  partnerName?: Maybe<Scalars['String']>;
  price?: Maybe<PriceDto>;
  publishedScope?: Maybe<Scalars['String']>;
  sellPriceValidTill?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTradeInItemProjection = {
  __typename?: 'RetailTradeInItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  tradeIn?: Maybe<RetailTradeInProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailTradeInProjection = {
  __typename?: 'RetailTradeInProjection';
  accountingDate?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  carleadId?: Maybe<Scalars['String']>;
  confirmedFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  costGrossPNL?: Maybe<Scalars['Long']>;
  costPNLVatPercentage?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  onsitePriceMinorUnits?: Maybe<Scalars['Long']>;
  outstandingFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  partnerName?: Maybe<Scalars['String']>;
  price?: Maybe<PriceDto>;
  publishedScope?: Maybe<Scalars['String']>;
  sellPriceValidTill?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTrustpilotCustomerReviewProjection = {
  __typename?: 'RetailTrustpilotCustomerReviewProjection';
  stars: Scalars['Int'];
};

export type RetailTrustpilotInvitationProjection = {
  __typename?: 'RetailTrustpilotInvitationProjection';
  consumerEmail?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  preferredSendTime?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
};

export type RetailUnidentifiedPaymentProjection = {
  __typename?: 'RetailUnidentifiedPaymentProjection';
  accountingDate?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  paymentData?: Maybe<RetailPaymentDataDto>;
  type?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['String']>;
};

export type RetailUnidentifiedPaymentUpdateProjectionInput = {
  accountHolder?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  customerPaymentReference?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
};

export type RetailUserDto = {
  __typename?: 'RetailUserDTO';
  accessParameters?: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  effectivePermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<RetailGroupDto>>>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userRegistrationSource?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type RetailVehiclePreparationProjection = {
  __typename?: 'RetailVehiclePreparationProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  priceDetails?: Maybe<Array<Maybe<PriceDetailsDto>>>;
  publishedScope: Scalars['String'];
  quantity: Scalars['Int'];
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  updatedOn: Scalars['String'];
};

export type RetailWarrantyProjection = {
  __typename?: 'RetailWarrantyProjection';
  active: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  insuranceTax?: Maybe<Scalars['BigDecimal']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  level?: Maybe<WarrantyLevelType>;
  monthsCovered?: Maybe<Scalars['Int']>;
  partnerName?: Maybe<Scalars['String']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsDto>>>;
  publishedScope?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  refId?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  source?: Maybe<WarrantySourceType>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailYouSignSignatureRequestProjection = {
  __typename?: 'RetailYouSignSignatureRequestProjection';
  createdOn?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  retailExternalId?: Maybe<Scalars['UUID']>;
  signedOn?: Maybe<Scalars['String']>;
  signerId?: Maybe<Scalars['UUID']>;
  status: SignatureRequestState;
  type: DocumentSignInProcessType;
  updatedOn?: Maybe<Scalars['String']>;
  yousignRequestId?: Maybe<Scalars['UUID']>;
};

export enum RimMaterial {
  Alloy = 'ALLOY',
  AlloyOem = 'ALLOY_OEM',
  NoRims = 'NO_RIMS',
  Steel = 'STEEL',
  SteelCover = 'STEEL_COVER'
}

export type RimSize = {
  __typename?: 'RimSize';
  unit?: Maybe<Unit>;
  value?: Maybe<Scalars['Int']>;
};

export enum RimType {
  AluminiumSupplier = 'ALUMINIUM_SUPPLIER',
  Original = 'ORIGINAL',
  Steel = 'STEEL',
  SteelWithCover = 'STEEL_WITH_COVER'
}

export type RiskData = {
  __typename?: 'RiskData';
  arvatoScore?: Maybe<Scalars['Int']>;
  averageDaysOverdrawn?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  encashmentPayments?: Maybe<AmountInfo>;
  gamblingExpenses?: Maybe<AmountInfo>;
  hasCreditCard?: Maybe<Scalars['Boolean']>;
  monthlyNetIncome?: Maybe<AmountInfo>;
  unemployedBenefits?: Maybe<AmountInfo>;
};

export type RoleCreateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
};

export type RoleDto = {
  __typename?: 'RoleDTO';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<PermissionDto>>>;
};

export type RoleProjection = {
  __typename?: 'RoleProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<PermissionProjection>>>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RoleUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
};

export enum RouteStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Finished = 'FINISHED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export enum Salutation {
  Company = 'COMPANY',
  Mr = 'MR',
  Mrs = 'MRS'
}

export type SaveFinanceRiskEvaluationProjectionInput = {
  contractType?: InputMaybe<Scalars['String']>;
  employedUnderLocalWorkContract?: InputMaybe<Scalars['Boolean']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type SearchProjection2Input = {
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type SearchRetailDocumentProjection = {
  __typename?: 'SearchRetailDocumentProjection';
  attachedTo?: Maybe<Scalars['UUID']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  deletedBy?: Maybe<Scalars['UUID']>;
  deletedByUser?: Maybe<UserProjection>;
  deletedOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  signatureExternalId?: Maybe<Scalars['UUID']>;
  signatureProvider?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['Boolean']>;
  signedBy?: Maybe<Scalars['UUID']>;
  signedByUser?: Maybe<UserProjection>;
  signedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  verifiedBy?: Maybe<Scalars['UUID']>;
  verifiedByUser?: Maybe<UserProjection>;
  verifiedOn?: Maybe<Scalars['String']>;
};

export type SecondaryWheelsItemProjection = {
  __typename?: 'SecondaryWheelsItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  retailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export enum SecondaryWheelsLocation {
  InBranch = 'IN_BRANCH',
  InCar = 'IN_CAR',
  Other = 'OTHER',
  TwoInCarTwoInBranch = 'TWO_IN_CAR_TWO_IN_BRANCH'
}

export type SecondaryWheelsOptionsProjection = {
  __typename?: 'SecondaryWheelsOptionsProjection';
  profileDepth?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rimDamageTypes?: Maybe<Array<Maybe<DamageTypeProjection>>>;
  rimType?: Maybe<Array<Maybe<RimMaterial>>>;
  seasons?: Maybe<Array<Maybe<TireSeasonType>>>;
  tireDamageTypes?: Maybe<Array<Maybe<DamageTypeProjection>>>;
  wheelRadius?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SellerAddressCreateProjectionInput = {
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['Int']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SellerAddressDto = {
  __typename?: 'SellerAddressDTO';
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  displayPhone: Scalars['Boolean'];
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Location>;
  phone?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['UUID']>;
  street?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type SellerAddressProjection = {
  __typename?: 'SellerAddressProjection';
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Location>;
  sellerId?: Maybe<Scalars['UUID']>;
  street?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type SellerAddressProjectionInput = {
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['Int']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  location?: InputMaybe<LocationInput>;
  sellerId?: InputMaybe<Scalars['UUID']>;
  street?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SellerContactDto = {
  __typename?: 'SellerContactDTO';
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastname?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
};

export type SellerDto = {
  __typename?: 'SellerDTO';
  aboutMeDescription?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<SellerAddressDto>>>;
  billingAddress?: Maybe<SellerAddressDto>;
  billingContact?: Maybe<SellerContactDto>;
  companyAddress?: Maybe<SellerAddressDto>;
  companyContact?: Maybe<SellerContactDto>;
  companyName?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<SellerContactDto>>>;
  defaultAddress?: Maybe<SellerAddressDto>;
  defaultContact?: Maybe<SellerContactDto>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  firstName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<ImageDto>;
  id?: Maybe<Scalars['UUID']>;
  inventoryUpperBound?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<SellerAddressDto>;
  legalContact?: Maybe<SellerContactDto>;
  legalForm?: Maybe<Scalars['String']>;
  logoImage?: Maybe<ImageDto>;
  name?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['Map_String_OpeningHoursDTOScalar']>;
  pathTitle?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
  services?: Maybe<Scalars['String']>;
  type?: Maybe<SellerType>;
  typeCode?: Maybe<SellerType>;
  vehicleAddress?: Maybe<SellerAddressDto>;
  vehicleContact?: Maybe<SellerContactDto>;
  website?: Maybe<Scalars['String']>;
};

export enum SellerType {
  Aux = 'AUX',
  Merchant = 'MERCHANT',
  PrivateListing = 'PRIVATE_LISTING',
  PrivateSales = 'PRIVATE_SALES',
  Retail = 'RETAIL'
}

export type SeverityProjection = {
  __typename?: 'SeverityProjection';
  severity?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type ShippingProviderProjection = {
  __typename?: 'ShippingProviderProjection';
  address?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  typesOfDelivery: Array<Maybe<Scalars['String']>>;
};

export enum SignatureRequestState {
  Approval = 'APPROVAL',
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Ongoing = 'ONGOING',
  Rejected = 'REJECTED'
}

export type SortOrderProjectionInput = {
  direction?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
};

export type Source = {
  __typename?: 'Source';
  channel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawExtraInfo?: Maybe<Scalars['String']>;
  type?: Maybe<Type>;
};

export type Tuvdto = {
  __typename?: 'TUVDTO';
  color?: Maybe<Scalars['String']>;
  huCertificate?: Maybe<Scalars['String']>;
  initialRegistration?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  salesDescription?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type TuvdtoInput = {
  color?: InputMaybe<Scalars['String']>;
  huCertificate?: InputMaybe<Scalars['String']>;
  initialRegistration?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  salesDescription?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export enum TagType {
  Imperfection = 'IMPERFECTION'
}

export type TaskAssigneeProjection = {
  __typename?: 'TaskAssigneeProjection';
  countryAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  userName?: Maybe<Scalars['String']>;
};

export type TaskManagementBaseTaskProjection = {
  __typename?: 'TaskManagementBaseTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserProjection>;
  comment?: Maybe<Scalars['String']>;
  completedBy?: Maybe<UserProjection>;
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  stockNumber?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type TaskManagerAssigneeProjection = {
  __typename?: 'TaskManagerAssigneeProjection';
  countryAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['UUID']>;
  userName?: Maybe<Scalars['String']>;
};

export type TasksManagerSearchOptionsProjection = {
  __typename?: 'TasksManagerSearchOptionsProjection';
  financingStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderRetailCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskManagerAssignees?: Maybe<Array<Maybe<TaskManagerAssigneeProjection>>>;
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TasksSearchOptionsProjection = {
  __typename?: 'TasksSearchOptionsProjection';
  orderRetailCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  tasksAssignees?: Maybe<Array<Maybe<TaskAssigneeProjection>>>;
};

export enum TaxAdvantageSource {
  All = 'ALL',
  CarLead = 'CAR_LEAD',
  RetailAd = 'RETAIL_AD'
}

export type TemplateProjection = {
  __typename?: 'TemplateProjection';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum TireSeasonType {
  AllSeason = 'ALL_SEASON',
  Emergency = 'EMERGENCY',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export enum TireType {
  AllWeather = 'ALL_WEATHER',
  Emergency = 'EMERGENCY',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export type TransferImageDto = {
  __typename?: 'TransferImageDTO';
  cdnContainer?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  placeholder?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type TransferImageDtoInput = {
  cdnContainer?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<Scalars['Int']>;
  part?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['Int']>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
};

export type TransferImageProjectionInput = {
  images?: InputMaybe<Array<InputMaybe<TransferImageDtoInput>>>;
};

export enum Type {
  AutomaticImport = 'AUTOMATIC_IMPORT',
  ManualImport = 'MANUAL_IMPORT'
}

export enum Unit {
  Cm = 'CM',
  In = 'IN',
  Mm = 'MM'
}

export enum UnpublishedReason {
  ConsignedToPrivateSale = 'CONSIGNED_TO_PRIVATE_SALE',
  Expired = 'EXPIRED',
  InventoryInvalidated = 'INVENTORY_INVALIDATED',
  Manual = 'MANUAL',
  SoldToAuto1 = 'SOLD_TO_AUTO1'
}

export type UpdateDocumentPackageInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars['UUID']>>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  packageId: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  shippingProvider: Scalars['Int'];
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentPackagePartialInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  packageId: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  shippingProvider?: InputMaybe<Scalars['Int']>;
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentPackagePartialResult = DocumentPackageNotOpenProblem | UpdateDocumentPackageProjection;

export type UpdateDocumentPackageProjection = {
  __typename?: 'UpdateDocumentPackageProjection';
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
};

export type UpdateDocumentPackageResult = DocumentItemsInPackageProblem | DocumentPackageConflictProblem | DocumentPackageEmptyBranchNameProblem | DocumentPackageEmptyFirstNameProblem | DocumentPackageEmptyLastNameProblem | DocumentPackageNoHouseNumAddressProblem | DocumentPackageNotOpenProblem | DocumentPackageZeroItemsProblem | DocumentPackagesGeneralProblem | UpdateDocumentPackageProjection;

export type UpdateExternalFinanceRiskEvaluationProjectionInput = {
  contractType?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRetailFeatureProjectionInput = {
  condition?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTestOrderDownPaymentProjectionInput = {
  depositAmount?: InputMaybe<Scalars['Int']>;
};

export type UserCreateProjectionInput = {
  checkoutAdId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<Scalars['Int']>;
};

export type UserPasswordlessProjectionInput = {
  checkoutAdId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  registrationUserSource?: InputMaybe<RegistrationUserSource>;
  salutation?: InputMaybe<Scalars['Int']>;
};

export type UserProjection = {
  __typename?: 'UserProjection';
  accessParameters?: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  effectivePermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<UserPropertyProjection>>>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type UserPropertyProjection = {
  __typename?: 'UserPropertyProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserPropertyProjectionInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserUpdateProjectionInput = {
  billingAddressId?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryAddressId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type VatRateProjection = {
  __typename?: 'VatRateProjection';
  countryCode?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type VehicleInfoProjection = {
  __typename?: 'VehicleInfoProjection';
  adId?: Maybe<Scalars['UUID']>;
  model?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type VehiclePreparationDto = {
  __typename?: 'VehiclePreparationDTO';
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsDto>>>;
  publishedScope?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  refId?: Maybe<Scalars['String']>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type VehiclePreparationItemProjection = {
  __typename?: 'VehiclePreparationItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  vehiclePreparationDTO?: Maybe<VehiclePreparationDto>;
};

export type VehicleProjection = {
  __typename?: 'VehicleProjection';
  acType?: Maybe<Scalars['Int']>;
  accidentCar?: Maybe<Scalars['Boolean']>;
  bodyForm?: Maybe<Scalars['Int']>;
  bodyType?: Maybe<Scalars['Int']>;
  builtYear?: Maybe<Scalars['Int']>;
  carAttrAccidentBool?: Maybe<Scalars['Boolean']>;
  carPreownerCount?: Maybe<Scalars['Int']>;
  carType?: Maybe<CarType>;
  categorizedCustomEquipment?: Maybe<Array<Maybe<CategorizedCustomEquipment>>>;
  ccm?: Maybe<Scalars['Int']>;
  certificates?: Maybe<Scalars['Map_String_ObjectScalar']>;
  co2Value?: Maybe<Scalars['Int']>;
  color?: Maybe<ColorDto>;
  commonFuelConsumption?: Maybe<Scalars['BigDecimal']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  cylinderCount?: Maybe<Scalars['Int']>;
  damageBool?: Maybe<Scalars['Boolean']>;
  damageHistory?: Maybe<Scalars['Int']>;
  doorCount?: Maybe<Scalars['Int']>;
  emissionClass?: Maybe<Scalars['String']>;
  emissionStandard?: Maybe<Scalars['Int']>;
  emissionSticker?: Maybe<Scalars['String']>;
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  equipmentGroups?: Maybe<Array<Maybe<Scalars['Long']>>>;
  equipments?: Maybe<Array<Maybe<Scalars['Long']>>>;
  externalId?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Feature>>>;
  fuelConsumption?: Maybe<FuelConsumptionDto>;
  fuelType?: Maybe<Scalars['Int']>;
  fuelTypeTranslation?: Maybe<Scalars['String']>;
  gearType?: Maybe<Scalars['Int']>;
  handoverKeyCount?: Maybe<Scalars['Int']>;
  hasAbs?: Maybe<Scalars['Boolean']>;
  hasAllWheelDrive?: Maybe<Scalars['Boolean']>;
  hasEsp?: Maybe<Scalars['Boolean']>;
  hasHandsFreeSystem?: Maybe<Scalars['Boolean']>;
  hasPdc?: Maybe<Scalars['Boolean']>;
  hasSeatHeating?: Maybe<Scalars['Boolean']>;
  hasTowHook?: Maybe<Scalars['Boolean']>;
  horsePower?: Maybe<Scalars['Int']>;
  huCertificate?: Maybe<Scalars['Int']>;
  inspectionExpiryDate?: Maybe<Scalars['String']>;
  isNewVehicle?: Maybe<Scalars['Boolean']>;
  kw?: Maybe<Scalars['BigDecimal']>;
  lastServiceMileage?: Maybe<MileageDto>;
  lastServiceOn?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerCode?: Maybe<Scalars['String']>;
  mileageDTO?: Maybe<MileageDto>;
  model?: Maybe<Scalars['String']>;
  modelApi?: Maybe<ModelDto>;
  modelCode?: Maybe<Scalars['String']>;
  navigationSystem?: Maybe<Scalars['Int']>;
  originalEngine?: Maybe<Scalars['Boolean']>;
  radioSystem?: Maybe<Scalars['Int']>;
  recentlyInspected?: Maybe<Scalars['Boolean']>;
  recentlyServiced?: Maybe<Scalars['Boolean']>;
  registration?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Int']>;
  serviceBook?: Maybe<Scalars['Int']>;
  smoker?: Maybe<Scalars['Boolean']>;
  stockNumber?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  subTypeExtra?: Maybe<Scalars['String']>;
  taxDeductible?: Maybe<Scalars['Boolean']>;
  taxRating?: Maybe<Scalars['Int']>;
  technicalInspectionCertificateIssueDate?: Maybe<Scalars['String']>;
  technicalInspectionCertificateIssueMileage?: Maybe<MileageDto>;
  testDrive?: Maybe<Scalars['String']>;
  tuv?: Maybe<Tuvdto>;
  type?: Maybe<Scalars['Int']>;
  upholstery?: Maybe<Scalars['Int']>;
  upholsteryOriginal?: Maybe<Scalars['Boolean']>;
  vatType?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  wasInCommercialUse?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  wheelSets?: Maybe<WheelSetsDto>;
  xenonLight?: Maybe<Scalars['Int']>;
};

export type WarningLightsDto = {
  __typename?: 'WarningLightsDTO';
  absWarning?: Maybe<Scalars['Boolean']>;
  airbagWarning?: Maybe<Scalars['Boolean']>;
  batteryWarning?: Maybe<Scalars['Boolean']>;
  breakPadWarning?: Maybe<Scalars['Boolean']>;
  breakWarning?: Maybe<Scalars['Boolean']>;
  coolantTemperatureWarning?: Maybe<Scalars['Boolean']>;
  engineOilWarning?: Maybe<Scalars['Boolean']>;
  engineWarning?: Maybe<Scalars['Boolean']>;
  lowTirePressureWarning?: Maybe<Scalars['Boolean']>;
  tractionControlSystemWarning?: Maybe<Scalars['Boolean']>;
};

export type WarningLightsDtoInput = {
  absWarning?: InputMaybe<Scalars['Boolean']>;
  airbagWarning?: InputMaybe<Scalars['Boolean']>;
  batteryWarning?: InputMaybe<Scalars['Boolean']>;
  breakPadWarning?: InputMaybe<Scalars['Boolean']>;
  breakWarning?: InputMaybe<Scalars['Boolean']>;
  coolantTemperatureWarning?: InputMaybe<Scalars['Boolean']>;
  engineOilWarning?: InputMaybe<Scalars['Boolean']>;
  engineWarning?: InputMaybe<Scalars['Boolean']>;
  lowTirePressureWarning?: InputMaybe<Scalars['Boolean']>;
  tractionControlSystemWarning?: InputMaybe<Scalars['Boolean']>;
};

export type WarrantyItemProjection = {
  __typename?: 'WarrantyItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  warranty?: Maybe<RetailWarrantyProjection>;
};

export enum WarrantyLevelType {
  Basic = 'BASIC',
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Premium = 'PREMIUM',
  Silver = 'SILVER'
}

export enum WarrantySourceType {
  Autohero = 'AUTOHERO',
  Partner = 'PARTNER'
}

export type WheelDtoInput = {
  rimType?: InputMaybe<Scalars['String']>;
  tireType?: InputMaybe<Scalars['String']>;
};

export type WheelPositionProjection = {
  __typename?: 'WheelPositionProjection';
  position?: Maybe<Scalars['String']>;
  wheels?: Maybe<Array<Maybe<WheelProjection>>>;
};

export type WheelProjection = {
  __typename?: 'WheelProjection';
  dot?: Maybe<Scalars['String']>;
  loadIndex?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  rimSize?: Maybe<RimSize>;
  rimType?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  seasonLabel?: Maybe<Scalars['String']>;
  speedIndex?: Maybe<Scalars['String']>;
  wheelSize?: Maybe<WheelSize>;
};

export enum WheelSetDeleteReason {
  NotLicensedForTheCar = 'NOT_LICENSED_FOR_THE_CAR',
  WheelsCannotBeSold = 'WHEELS_CANNOT_BE_SOLD',
  WheelsDoNotExist = 'WHEELS_DO_NOT_EXIST'
}

export type WheelSetsDto = {
  __typename?: 'WheelSetsDTO';
  primary?: Maybe<Scalars['Map_String_List_WheelScalar']>;
  primaryTranslations?: Maybe<Array<Maybe<WheelPositionProjection>>>;
  secondary?: Maybe<Scalars['Map_String_List_WheelScalar']>;
  secondaryTranslations?: Maybe<Array<Maybe<WheelPositionProjection>>>;
};

export type WheelSetsDtoInput = {
  primary?: InputMaybe<Scalars['Map_String_List_WheelScalar']>;
  secondary?: InputMaybe<Scalars['Map_String_List_WheelScalar']>;
};

export type WheelSize = {
  __typename?: 'WheelSize';
  height?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  value?: Maybe<Scalars['Int']>;
};

export type WorkshopDto = {
  __typename?: 'WorkshopDTO';
  branchId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type DeleteDocumentInventoryItemResult = DeleteDocumentInventoryItemPayload | DocumentItemInPackageProblem;

export type RetailAdDeliveryOptionDetails = RetailAdDeliveryAddressDetailsProjection | RetailAdDeliveryBranchDetailsProjection | RetailAdDeliveryBranchesDetailsProjection;

export type TaskManagementProjection = AccountingDatePendingTaskProjection | BankDetailsPendingTaskProjection | CancellationRequestedTaskProjection | CancellationTaskProjection | CarReturnHandoverAppointmentTaskProjection | CarReturnedTaskProjection | CareCallTaskProjection | CashOutRefundApprovalPendingTaskProjection | ClaimPartialRefundPendingTaskProjection | ClaimProofPendingTaskProjection | ClaimProofSubmittedTaskProjection | CreditNoteNumberPendingTaskProjection | CreditNotePendingTaskProjection | DamageCompensationPendingTaskProjection | DocumentsReturnedDeregistrationTaskProjection | HappinessCallTaskManagementProjection | OrderReadyForVerificationTaskProjection | RefundPendingTaskProjection | RepeatHappinessCallTaskManagementProjection | TaskManagementBaseTaskProjection;

export type CreateGroupMutationVariables = Exact<{
  group: GroupCreateProjectionInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', group?: { __typename?: 'GroupProjection', id?: any | null } | null };

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  roleIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  group: GroupUpdateProjectionInput;
  assignUserIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  unassignUserIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  withAssignedUsers: Scalars['Boolean'];
  withUnassignedUsers: Scalars['Boolean'];
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', assignGroupRoles: boolean, addGroupUsers?: boolean, deleteGroupUsers?: boolean, group?: { __typename?: 'GroupProjection', id?: any | null, name?: string | null, description?: string | null, createdOn?: string | null, createdBy?: { __typename?: 'RetailUserDTO', firstName?: string | null, lastName?: string | null } | null, roles?: Array<{ __typename?: 'RoleProjection', id?: any | null } | null> | null } | null };

export type SearchGroupsQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchGroupsQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_GroupProjection', page: number, pageSize: number, totalEntityCount?: any | null, totalPageCount: number, entities?: Array<{ __typename?: 'GroupProjection', id?: any | null, name?: string | null, description?: string | null, createdOn?: string | null, createdBy?: { __typename?: 'RetailUserDTO', firstName?: string | null, lastName?: string | null } | null } | null> | null } | null };

export type SearchGroupsOptionsQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchGroupsOptionsQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_GroupProjection', entities?: Array<{ __typename?: 'GroupProjection', id?: any | null, name?: string | null } | null> | null } | null };

export type SearchGroupDataQueryVariables = Exact<{
  groupId: Scalars['UUID'];
}>;


export type SearchGroupDataQuery = { __typename?: 'Query', group?: { __typename?: 'GroupProjection', id?: any | null, name?: string | null, description?: string | null, createdOn?: string | null, createdBy?: { __typename?: 'RetailUserDTO', firstName?: string | null, lastName?: string | null } | null, roles?: Array<{ __typename?: 'RoleProjection', id?: any | null } | null> | null, users?: Array<{ __typename?: 'UserProjection', id?: any | null } | null> | null } | null };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['UUID'];
  role: RoleUpdateProjectionInput;
  groupIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', assignRoleGroups: boolean, role?: { __typename?: 'RoleProjection', id?: any | null, name?: string | null, description?: string | null, createdOn?: string | null, page?: string | null, endpoint?: string | null, groups?: Array<{ __typename?: 'GroupProjection', id?: any | null } | null> | null } | null };

export type SearchRolesQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchRolesQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_RoleProjection', page: number, pageSize: number, totalEntityCount?: any | null, totalPageCount: number, entities?: Array<{ __typename?: 'RoleProjection', id?: any | null, name?: string | null, description?: string | null, endpoint?: string | null, createdOn?: string | null, groups?: Array<{ __typename?: 'GroupProjection', id?: any | null, name?: string | null } | null> | null } | null> | null } | null };

export type SearchRolesOptionsQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchRolesOptionsQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_RoleProjection', entities?: Array<{ __typename?: 'RoleProjection', id?: any | null, name?: string | null } | null> | null } | null };

export type GetRoleInfoQueryVariables = Exact<{
  roleId: Scalars['UUID'];
  groupsSearch: SearchProjection2Input;
}>;


export type GetRoleInfoQuery = { __typename?: 'Query', role?: { __typename?: 'RoleProjection', id?: any | null, name?: string | null, description?: string | null, createdOn?: string | null, page?: string | null, endpoint?: string | null, groups?: Array<{ __typename?: 'GroupProjection', id?: any | null } | null> | null } | null, groups?: { __typename?: 'PageDTO_GroupProjection', entities?: Array<{ __typename?: 'GroupProjection', id?: any | null, name?: string | null } | null> | null } | null, users?: { __typename?: 'ListContainerDTO_AuxUserDTO', entities?: Array<{ __typename?: 'AuxUserDTO', id?: any | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null> | null } | null };

export type CreateUserMutationVariables = Exact<{
  user: UserCreateProjectionInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', user?: { __typename?: 'AuxUserDTO', id?: any | null } | null };

export type PostCreateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryAccess: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  withGroupAccess: Scalars['Boolean'];
  groupAccess: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  addGroupIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  properties: Array<InputMaybe<UserPropertyProjectionInput>> | InputMaybe<UserPropertyProjectionInput>;
  withProperties: Scalars['Boolean'];
}>;


export type PostCreateUserMutation = { __typename?: 'Mutation', assignUserCountryAccessParams: boolean, assignUserGroupAccessParams?: boolean, addUserGroups: boolean, addUserProperties?: boolean };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryAccess: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  withGroupAccess: Scalars['Boolean'];
  groupAccess: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  withDeleteGroup: Scalars['Boolean'];
  deleteGroupIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  withAddGroup: Scalars['Boolean'];
  addGroupIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  user: UserUpdateProjectionInput;
  properties: Array<InputMaybe<UserPropertyProjectionInput>> | InputMaybe<UserPropertyProjectionInput>;
  deletePropertyNames: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  withDeleteProperties: Scalars['Boolean'];
  withProperties: Scalars['Boolean'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', assignUserCountryAccessParams: boolean, assignUserGroupAccessParams?: boolean, deleteUserGroups?: boolean, addUserGroups?: boolean, addUserProperties?: boolean, deleteUserProperties?: boolean, user?: { __typename?: 'UserProjection', id?: any | null, email?: string | null, firstName?: string | null, lastName?: string | null, locale?: string | null, country?: string | null, loginTime?: string | null, createdOn?: string | null, enabled: boolean, accessParameters?: Array<{ __typename?: 'RetailAccessParamDTO', key?: string | null, value?: string | null } | null> | null, groups?: Array<{ __typename?: 'GroupProjection', id?: any | null } | null> | null, createdBy?: { __typename?: 'UserProjection', firstName?: string | null, lastName?: string | null } | null, properties?: Array<{ __typename?: 'UserPropertyProjection', name?: string | null, value?: string | null } | null> | null } | null };

export type ResetUserPasswordMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ResetUserPasswordMutation = { __typename?: 'Mutation', resetUserPassword: boolean };

export type AssignTestDataAccessParamMutationVariables = Exact<{
  id: Scalars['UUID'];
  enabled: Scalars['Boolean'];
}>;


export type AssignTestDataAccessParamMutation = { __typename?: 'Mutation', assignTestDataAccessParam: boolean };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', me?: { __typename?: 'RetailUserDTO', id?: any | null, effectivePermissions?: Array<string | null> | null, accessParameters?: Array<{ __typename?: 'RetailAccessParamDTO', key?: string | null, value?: string | null } | null> | null } | null };

export type SearchUsersQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchUsersQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_RetailUserDTO', page: number, pageSize: number, totalEntityCount?: any | null, totalPageCount: number, entities?: Array<{ __typename?: 'RetailUserDTO', id?: any | null, email?: string | null, country?: string | null, firstName?: string | null, lastName?: string | null, loginTime?: string | null, userType?: number | null, enabled: boolean, createdOn?: string | null, accessParameters?: Array<{ __typename?: 'RetailAccessParamDTO', key?: string | null, value?: string | null } | null> | null } | null> | null } | null };

export type SearchUserDataQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type SearchUserDataQuery = { __typename?: 'Query', user?: { __typename?: 'UserProjection', id?: any | null, email?: string | null, firstName?: string | null, lastName?: string | null, locale?: string | null, country?: string | null, loginTime?: string | null, createdOn?: string | null, enabled: boolean, accessParameters?: Array<{ __typename?: 'RetailAccessParamDTO', key?: string | null, value?: string | null } | null> | null, groups?: Array<{ __typename?: 'GroupProjection', id?: any | null } | null> | null, createdBy?: { __typename?: 'UserProjection', firstName?: string | null, lastName?: string | null } | null, properties?: Array<{ __typename?: 'UserPropertyProjection', name?: string | null, value?: string | null } | null> | null } | null };


export const CreateGroupDocument = gql`
    mutation CreateGroup($group: GroupCreateProjectionInput!) {
  group: createGroup(group: $group) {
    id
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($id: UUID!, $roleIds: [UUID]!, $group: GroupUpdateProjectionInput!, $assignUserIds: [UUID]!, $unassignUserIds: [UUID]!, $withAssignedUsers: Boolean!, $withUnassignedUsers: Boolean!) {
  assignGroupRoles(id: $id, roleIds: $roleIds)
  addGroupUsers(id: $id, userIds: $assignUserIds) @include(if: $withAssignedUsers)
  deleteGroupUsers(id: $id, userIds: $unassignUserIds) @include(if: $withUnassignedUsers)
  group: updateGroup(id: $id, group: $group) {
    id
    name
    description
    createdOn
    createdBy {
      firstName
      lastName
    }
    roles {
      id
    }
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleIds: // value for 'roleIds'
 *      group: // value for 'group'
 *      assignUserIds: // value for 'assignUserIds'
 *      unassignUserIds: // value for 'unassignUserIds'
 *      withAssignedUsers: // value for 'withAssignedUsers'
 *      withUnassignedUsers: // value for 'withUnassignedUsers'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const SearchGroupsDocument = gql`
    query SearchGroups($search: SearchProjection2Input!) {
  data: searchGroups(search: $search) {
    entities {
      id
      name
      description
      createdOn
      createdBy {
        firstName
        lastName
      }
    }
    page
    pageSize
    totalEntityCount
    totalPageCount
  }
}
    `;

/**
 * __useSearchGroupsQuery__
 *
 * To run a query within a React component, call `useSearchGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchGroupsQuery(baseOptions: Apollo.QueryHookOptions<SearchGroupsQuery, SearchGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchGroupsQuery, SearchGroupsQueryVariables>(SearchGroupsDocument, options);
      }
export function useSearchGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGroupsQuery, SearchGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchGroupsQuery, SearchGroupsQueryVariables>(SearchGroupsDocument, options);
        }
export type SearchGroupsQueryHookResult = ReturnType<typeof useSearchGroupsQuery>;
export type SearchGroupsLazyQueryHookResult = ReturnType<typeof useSearchGroupsLazyQuery>;
export type SearchGroupsQueryResult = Apollo.QueryResult<SearchGroupsQuery, SearchGroupsQueryVariables>;
export const SearchGroupsOptionsDocument = gql`
    query SearchGroupsOptions($search: SearchProjection2Input!) {
  data: searchGroups(search: $search) {
    entities {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchGroupsOptionsQuery__
 *
 * To run a query within a React component, call `useSearchGroupsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGroupsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGroupsOptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchGroupsOptionsQuery(baseOptions: Apollo.QueryHookOptions<SearchGroupsOptionsQuery, SearchGroupsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchGroupsOptionsQuery, SearchGroupsOptionsQueryVariables>(SearchGroupsOptionsDocument, options);
      }
export function useSearchGroupsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGroupsOptionsQuery, SearchGroupsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchGroupsOptionsQuery, SearchGroupsOptionsQueryVariables>(SearchGroupsOptionsDocument, options);
        }
export type SearchGroupsOptionsQueryHookResult = ReturnType<typeof useSearchGroupsOptionsQuery>;
export type SearchGroupsOptionsLazyQueryHookResult = ReturnType<typeof useSearchGroupsOptionsLazyQuery>;
export type SearchGroupsOptionsQueryResult = Apollo.QueryResult<SearchGroupsOptionsQuery, SearchGroupsOptionsQueryVariables>;
export const SearchGroupDataDocument = gql`
    query SearchGroupData($groupId: UUID!) {
  group(id: $groupId) {
    id
    name
    description
    createdOn
    createdBy {
      firstName
      lastName
    }
    roles {
      id
    }
    users {
      id
    }
  }
}
    `;

/**
 * __useSearchGroupDataQuery__
 *
 * To run a query within a React component, call `useSearchGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGroupDataQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSearchGroupDataQuery(baseOptions: Apollo.QueryHookOptions<SearchGroupDataQuery, SearchGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchGroupDataQuery, SearchGroupDataQueryVariables>(SearchGroupDataDocument, options);
      }
export function useSearchGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGroupDataQuery, SearchGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchGroupDataQuery, SearchGroupDataQueryVariables>(SearchGroupDataDocument, options);
        }
export type SearchGroupDataQueryHookResult = ReturnType<typeof useSearchGroupDataQuery>;
export type SearchGroupDataLazyQueryHookResult = ReturnType<typeof useSearchGroupDataLazyQuery>;
export type SearchGroupDataQueryResult = Apollo.QueryResult<SearchGroupDataQuery, SearchGroupDataQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: UUID!, $role: RoleUpdateProjectionInput!, $groupIds: [UUID]!) {
  assignRoleGroups(id: $id, groupIds: $groupIds)
  role: updateRole(id: $id, role: $role) {
    id
    name
    description
    createdOn
    page
    endpoint
    groups {
      id
    }
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const SearchRolesDocument = gql`
    query SearchRoles($search: SearchProjection2Input!) {
  data: searchRoles(search: $search) {
    entities {
      id
      name
      description
      endpoint
      createdOn
      groups {
        id
        name
      }
    }
    page
    pageSize
    totalEntityCount
    totalPageCount
  }
}
    `;

/**
 * __useSearchRolesQuery__
 *
 * To run a query within a React component, call `useSearchRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRolesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchRolesQuery(baseOptions: Apollo.QueryHookOptions<SearchRolesQuery, SearchRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRolesQuery, SearchRolesQueryVariables>(SearchRolesDocument, options);
      }
export function useSearchRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRolesQuery, SearchRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRolesQuery, SearchRolesQueryVariables>(SearchRolesDocument, options);
        }
export type SearchRolesQueryHookResult = ReturnType<typeof useSearchRolesQuery>;
export type SearchRolesLazyQueryHookResult = ReturnType<typeof useSearchRolesLazyQuery>;
export type SearchRolesQueryResult = Apollo.QueryResult<SearchRolesQuery, SearchRolesQueryVariables>;
export const SearchRolesOptionsDocument = gql`
    query SearchRolesOptions($search: SearchProjection2Input!) {
  data: searchRoles(search: $search) {
    entities {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchRolesOptionsQuery__
 *
 * To run a query within a React component, call `useSearchRolesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRolesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRolesOptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchRolesOptionsQuery(baseOptions: Apollo.QueryHookOptions<SearchRolesOptionsQuery, SearchRolesOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRolesOptionsQuery, SearchRolesOptionsQueryVariables>(SearchRolesOptionsDocument, options);
      }
export function useSearchRolesOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRolesOptionsQuery, SearchRolesOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRolesOptionsQuery, SearchRolesOptionsQueryVariables>(SearchRolesOptionsDocument, options);
        }
export type SearchRolesOptionsQueryHookResult = ReturnType<typeof useSearchRolesOptionsQuery>;
export type SearchRolesOptionsLazyQueryHookResult = ReturnType<typeof useSearchRolesOptionsLazyQuery>;
export type SearchRolesOptionsQueryResult = Apollo.QueryResult<SearchRolesOptionsQuery, SearchRolesOptionsQueryVariables>;
export const GetRoleInfoDocument = gql`
    query GetRoleInfo($roleId: UUID!, $groupsSearch: SearchProjection2Input!) {
  role(id: $roleId) {
    id
    name
    description
    createdOn
    page
    endpoint
    groups {
      id
    }
  }
  groups: searchGroups(search: $groupsSearch) {
    entities {
      id
      name
    }
  }
  users: roleUsers(id: $roleId) {
    entities {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetRoleInfoQuery__
 *
 * To run a query within a React component, call `useGetRoleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleInfoQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      groupsSearch: // value for 'groupsSearch'
 *   },
 * });
 */
export function useGetRoleInfoQuery(baseOptions: Apollo.QueryHookOptions<GetRoleInfoQuery, GetRoleInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleInfoQuery, GetRoleInfoQueryVariables>(GetRoleInfoDocument, options);
      }
export function useGetRoleInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleInfoQuery, GetRoleInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleInfoQuery, GetRoleInfoQueryVariables>(GetRoleInfoDocument, options);
        }
export type GetRoleInfoQueryHookResult = ReturnType<typeof useGetRoleInfoQuery>;
export type GetRoleInfoLazyQueryHookResult = ReturnType<typeof useGetRoleInfoLazyQuery>;
export type GetRoleInfoQueryResult = Apollo.QueryResult<GetRoleInfoQuery, GetRoleInfoQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserCreateProjectionInput!) {
  user: createUser(user: $user) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const PostCreateUserDocument = gql`
    mutation PostCreateUser($id: UUID!, $countryAccess: [String]!, $withGroupAccess: Boolean!, $groupAccess: [String]!, $addGroupIds: [UUID]!, $properties: [UserPropertyProjectionInput]!, $withProperties: Boolean!) {
  assignUserCountryAccessParams(id: $id, countries: $countryAccess)
  assignUserGroupAccessParams(id: $id, groupIds: $groupAccess) @include(if: $withGroupAccess)
  addUserGroups(id: $id, groupIds: $addGroupIds)
  addUserProperties(id: $id, properties: $properties) @include(if: $withProperties)
}
    `;
export type PostCreateUserMutationFn = Apollo.MutationFunction<PostCreateUserMutation, PostCreateUserMutationVariables>;

/**
 * __usePostCreateUserMutation__
 *
 * To run a mutation, you first call `usePostCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCreateUserMutation, { data, loading, error }] = usePostCreateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryAccess: // value for 'countryAccess'
 *      withGroupAccess: // value for 'withGroupAccess'
 *      groupAccess: // value for 'groupAccess'
 *      addGroupIds: // value for 'addGroupIds'
 *      properties: // value for 'properties'
 *      withProperties: // value for 'withProperties'
 *   },
 * });
 */
export function usePostCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<PostCreateUserMutation, PostCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCreateUserMutation, PostCreateUserMutationVariables>(PostCreateUserDocument, options);
      }
export type PostCreateUserMutationHookResult = ReturnType<typeof usePostCreateUserMutation>;
export type PostCreateUserMutationResult = Apollo.MutationResult<PostCreateUserMutation>;
export type PostCreateUserMutationOptions = Apollo.BaseMutationOptions<PostCreateUserMutation, PostCreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: UUID!, $countryAccess: [String]!, $withGroupAccess: Boolean!, $groupAccess: [String]!, $withDeleteGroup: Boolean!, $deleteGroupIds: [UUID]!, $withAddGroup: Boolean!, $addGroupIds: [UUID]!, $user: UserUpdateProjectionInput!, $properties: [UserPropertyProjectionInput]!, $deletePropertyNames: [String]!, $withDeleteProperties: Boolean!, $withProperties: Boolean!) {
  assignUserCountryAccessParams(id: $id, countries: $countryAccess)
  assignUserGroupAccessParams(id: $id, groupIds: $groupAccess) @include(if: $withGroupAccess)
  deleteUserGroups(id: $id, groupIds: $deleteGroupIds) @include(if: $withDeleteGroup)
  addUserGroups(id: $id, groupIds: $addGroupIds) @include(if: $withAddGroup)
  addUserProperties(id: $id, properties: $properties) @include(if: $withProperties)
  deleteUserProperties(id: $id, propertyNames: $deletePropertyNames) @include(if: $withDeleteProperties)
  user: updateUser(id: $id, user: $user) {
    id
    email
    firstName
    lastName
    locale
    country
    loginTime
    createdOn
    enabled
    accessParameters {
      key
      value
    }
    groups {
      id
    }
    createdBy {
      firstName
      lastName
    }
    properties {
      name
      value
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryAccess: // value for 'countryAccess'
 *      withGroupAccess: // value for 'withGroupAccess'
 *      groupAccess: // value for 'groupAccess'
 *      withDeleteGroup: // value for 'withDeleteGroup'
 *      deleteGroupIds: // value for 'deleteGroupIds'
 *      withAddGroup: // value for 'withAddGroup'
 *      addGroupIds: // value for 'addGroupIds'
 *      user: // value for 'user'
 *      properties: // value for 'properties'
 *      deletePropertyNames: // value for 'deletePropertyNames'
 *      withDeleteProperties: // value for 'withDeleteProperties'
 *      withProperties: // value for 'withProperties'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($id: UUID!) {
  resetUserPassword(id: $id)
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const AssignTestDataAccessParamDocument = gql`
    mutation AssignTestDataAccessParam($id: UUID!, $enabled: Boolean!) {
  assignTestDataAccessParam(id: $id, enabled: $enabled)
}
    `;
export type AssignTestDataAccessParamMutationFn = Apollo.MutationFunction<AssignTestDataAccessParamMutation, AssignTestDataAccessParamMutationVariables>;

/**
 * __useAssignTestDataAccessParamMutation__
 *
 * To run a mutation, you first call `useAssignTestDataAccessParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTestDataAccessParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTestDataAccessParamMutation, { data, loading, error }] = useAssignTestDataAccessParamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useAssignTestDataAccessParamMutation(baseOptions?: Apollo.MutationHookOptions<AssignTestDataAccessParamMutation, AssignTestDataAccessParamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTestDataAccessParamMutation, AssignTestDataAccessParamMutationVariables>(AssignTestDataAccessParamDocument, options);
      }
export type AssignTestDataAccessParamMutationHookResult = ReturnType<typeof useAssignTestDataAccessParamMutation>;
export type AssignTestDataAccessParamMutationResult = Apollo.MutationResult<AssignTestDataAccessParamMutation>;
export type AssignTestDataAccessParamMutationOptions = Apollo.BaseMutationOptions<AssignTestDataAccessParamMutation, AssignTestDataAccessParamMutationVariables>;
export const UserDocument = gql`
    query User {
  me {
    id
    effectivePermissions
    accessParameters {
      key
      value
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($search: SearchProjection2Input!) {
  data: searchUsers(search: $search) {
    entities {
      id
      email
      country
      firstName
      lastName
      loginTime
      userType
      enabled
      createdOn
      accessParameters {
        key
        value
      }
    }
    page
    pageSize
    totalEntityCount
    totalPageCount
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const SearchUserDataDocument = gql`
    query SearchUserData($userId: UUID!) {
  user(id: $userId) {
    id
    email
    firstName
    lastName
    locale
    country
    loginTime
    createdOn
    enabled
    accessParameters {
      key
      value
    }
    groups {
      id
    }
    createdBy {
      firstName
      lastName
    }
    properties {
      name
      value
    }
  }
}
    `;

/**
 * __useSearchUserDataQuery__
 *
 * To run a query within a React component, call `useSearchUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSearchUserDataQuery(baseOptions: Apollo.QueryHookOptions<SearchUserDataQuery, SearchUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUserDataQuery, SearchUserDataQueryVariables>(SearchUserDataDocument, options);
      }
export function useSearchUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserDataQuery, SearchUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUserDataQuery, SearchUserDataQueryVariables>(SearchUserDataDocument, options);
        }
export type SearchUserDataQueryHookResult = ReturnType<typeof useSearchUserDataQuery>;
export type SearchUserDataLazyQueryHookResult = ReturnType<typeof useSearchUserDataLazyQuery>;
export type SearchUserDataQueryResult = Apollo.QueryResult<SearchUserDataQuery, SearchUserDataQueryVariables>;