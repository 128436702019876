import { EditOutlined } from '@ant-design/icons';
import { PermissionChecker } from '@retail/backoffice-ui/src/Permission/PermissionChecker';
import { LinkButton } from '@retail/backoffice-urls';
import { useParams } from 'react-router-dom';
import { ADMIN_GROUP } from 'routes';

import { GroupProjection } from '~/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import cn from './styles.less';

interface Props {
  id: GroupProjection['id'];
}

export function Actions({ id }: Props) {
  const { language } = useParams();

  return (
    <PermissionChecker
      domain={DOMAINS.GROUP_USER_MANAGEMENT}
      allow={PERMISSIONS.QUERY_GROUP}
    >
      <LinkButton
        type="primary"
        data-qa-selector-name="editGroup"
        className={cn.editLinkButton}
        to={ADMIN_GROUP.LINK({ language, id })}
      >
        <EditOutlined />
      </LinkButton>
    </PermissionChecker>
  );
}
