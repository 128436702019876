import { map } from 'lodash/fp';
import { useMemo } from 'react';

import { useSearchRolesOptionsQuery } from '~/apollo/gql-types';

import { GROUPS_FORM_BASE_OPTIONS, rolesSearchParams } from '../utils';

export const useRoleOptions = () => {
  const {
    data,
    loading: isLoading,
    called
  } = useSearchRolesOptionsQuery({
    ...GROUPS_FORM_BASE_OPTIONS,
    variables: rolesSearchParams
  });

  return useMemo(() => {
    const roles = data?.data?.entities;

    const options = map(({ id, name }) => ({
      value: id,
      label: name
    }))(roles);

    return {
      options,
      isLoaded: !isLoading && called
    };
  }, [data, called, isLoading]);
};
