import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputControlled } from '~/components/Form/InputControlled';
import {
  AntOptionProps,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { options as countryAccessOptions } from '~/helpers/fieldOptions/admin/users/countryAccess/options';
import { options as statusOptions } from '~/helpers/fieldOptions/admin/users/status/options';

import { MIN_SEARCH_LENGTH } from '../utils';

import cn from './style.less';

export interface UsersOverviewModel {
  text?: string;
  countryAccess?: string[];
  enabled?: boolean;
}

interface Props extends UsersOverviewModel {
  onSubmit: Dispatch<UsersOverviewModel>;
  isLoading: boolean;
}

export const SearchForm = ({
  text,
  countryAccess,
  enabled,
  isLoading,
  onSubmit
}: Props) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<UsersOverviewModel>({
    defaultValues: {
      text,
      countryAccess,
      enabled
    }
  });

  return (
    <Card title={t('bo.admin.usersOverview.searchForm.title')}>
      <Form
        disabled={isLoading}
        onFinish={handleSubmit(onSubmit)}
        data-qa-selector-name="searchForm"
        className={cn.form}
      >
        <InputControlled
          type="string"
          allowClear
          labelCol={{ span: 24 }}
          className={cn.text}
          required
          qaSelector="searchForm-text"
          disabled={isLoading}
          label={t('bo.admin.usersOverview.searchForm.text.label')}
          placeholder={t('bo.admin.usersOverview.searchForm.text.placeholder')}
          controllerProps={{
            name: 'text',
            control,
            rules: {
              minLength: {
                value: MIN_SEARCH_LENGTH,
                message: `Min length ${MIN_SEARCH_LENGTH}`
              }
            }
          }}
        />

        <SelectControlled
          allowClear
          options={countryAccessOptions as Array<AntOptionProps>}
          qaSelector="searchForm-countryAccess"
          mode="multiple"
          className={cn.countryAccess}
          label={t('bo.admin.usersOverview.searchForm.countryAccess.label')}
          placeholder={t(
            'bo.admin.usersOverview.searchForm.countryAccess.placeholder'
          )}
          loading={isLoading}
          controllerProps={{
            control,
            name: 'countryAccess'
          }}
        />

        <SelectControlled
          allowClear
          options={statusOptions}
          qaSelector="searchForm-enabled"
          loading={isLoading}
          className={cn.enabled}
          label={t('bo.admin.usersOverview.searchForm.status.label')}
          placeholder={t(
            'bo.admin.usersOverview.searchForm.status.placeholder'
          )}
          controllerProps={{
            control,
            name: 'enabled'
          }}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={isLoading}
          data-qa-selector-name="searchForm-searchButton"
          className={cn.submit}
          icon={<SearchOutlined />}
        />
      </Form>
    </Card>
  );
};
