// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P1ld52cLXPuv0Co6kyVT {\n  display: flex;\n  align-items: flex-start;\n}\n.P1ld52cLXPuv0Co6kyVT .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.P1ld52cLXPuv0Co6kyVT .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n  margin-right: 1.5rem;\n}\n.P1ld52cLXPuv0Co6kyVT .ceJIs6DNEHakKEI11nN3 {\n  width: 400px;\n}\n.P1ld52cLXPuv0Co6kyVT .wL7HugW8p5AKtpVIy8Cx {\n  width: 300px;\n}\n.P1ld52cLXPuv0Co6kyVT .rOA4k4LB7Zf1xeu4MnhA {\n  width: 300px;\n}\n.P1ld52cLXPuv0Co6kyVT .Ipi2nH0GL4SXHQDDFFpx {\n  display: inline-block;\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UsersOverview/SearchForm/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAHA;EAMM,4BAAA;AAAN;AANA;EAUM,qBAAA;EACA,gBAAA;EACA,oBAAA;AADN;AAXA;EAiBI,YAAA;AAHJ;AAdA;EAoBI,YAAA;AAHJ;AAjBA;EAuBI,YAAA;AAHJ;AApBA;EA2BI,qBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".form {\n  display: flex;\n  align-items: flex-start;\n\n  :global {\n    .antd-4-21-7-form-item-label {\n      padding-bottom: 0 !important;\n    }\n\n    .antd-4-21-7-form-item {\n      display: inline-block;\n      margin-bottom: 0;\n      margin-right: 1.5rem;\n    }\n  }\n\n  .text {\n    width: 400px;\n  }\n  .countryAccess {\n    width: 300px;\n  }\n  .enabled {\n    width: 300px;\n  }\n\n  .submit {\n    display: inline-block;\n    margin-top: 32px;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "P1ld52cLXPuv0Co6kyVT",
	"text": "ceJIs6DNEHakKEI11nN3",
	"countryAccess": "wL7HugW8p5AKtpVIy8Cx",
	"enabled": "rOA4k4LB7Zf1xeu4MnhA",
	"submit": "Ipi2nH0GL4SXHQDDFFpx"
};
export default ___CSS_LOADER_EXPORT___;
