import { FilterType, createFilter } from '@retail/gql-utils';

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 20;
export const DEFAULT_PAGE_OPTIONS = ['10', '20'];

export const editPermissions = [];

export const viewPermissions = [];

export const defaultCountry = 'de';

export const UNASSIGNED = 'unassigned';

export const EMPTY_VALUE_PLACEHOLDER = '-';

export const EMPTY_UUID = '111111111-1111-1111-1111-11111111111';

export const registeredFromBackofficeFilter = createFilter(
  'registeredFrom',
  FilterType.IN,
  ['BACKOFFICE', 'ADMIN']
);
