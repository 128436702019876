import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import { ReactNode } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { FormItem } from './FormItem';

type Props<T> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  qaSelector?: string;
} & CheckboxProps;

export function CheckboxControlled<T extends FieldValues>({
  label,
  controllerProps,
  required,
  disabled,
  qaSelector,
  ...restInputProps
}: Props<T>) {
  const { field } = useController(controllerProps);

  return (
    <FormItem
      required={required}
      disabled={disabled}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <Checkbox
        {...field}
        {...restInputProps}
        checked={field.value}
        data-qa-selector-name={qaSelector}
      >
        {label}
      </Checkbox>
    </FormItem>
  );
}
