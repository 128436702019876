import { Card } from 'antd';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import Column from './Column';
import { useAssignUsersProps } from './hooks/useAssignUsersProps';
import cn from './styles.less';

const AssignUsers = () => {
  const { t } = useTranslation();

  const {
    state,
    loadAssignedUsers,
    assignedLoaderRef,
    loadUnassignedUsers,
    unassignedLoaderRef,
    onDragEnd
  } = useAssignUsersProps();
  return (
    <Card title={t('bo.admin.groupsForm.assignUsers.title')}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={cn.innerWrap}>
          <Droppable
            droppableId="group-users"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                className={cn.columnWrap}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Column
                  column={state.columns.assigned}
                  onLoad={loadAssignedUsers}
                  loaderRef={assignedLoaderRef}
                  qaSelector="assignedUsers"
                />
                <Column
                  column={state.columns.unassigned}
                  onLoad={loadUnassignedUsers}
                  loaderRef={unassignedLoaderRef}
                  qaSelector="unassignedUsers"
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </Card>
  );
};

export default React.memo(AssignUsers);
