import { filter } from 'lodash/fp';

export const ASSIGNED = 'assigned';
export const UNASSIGNED = 'unassigned';

const toggleLoadingByColumn = (colName, state, loading) => ({
  ...state,
  columns: {
    ...state.columns,
    [colName]: {
      ...state.columns[colName],
      loading: loading !== undefined ? loading : !state.columns[colName].loading
    }
  }
});

const setItemsByColumn = (
  colName,
  state,
  { entities, page, totalPageCount }
) => ({
  ...state,
  columns: {
    ...state.columns,
    [colName]: {
      ...state.columns[colName],
      items: entities,
      page,
      hasNextPage: page < totalPageCount - 1,
      loading: false
    }
  }
});

const pushItemsByColumn = (
  colName,
  state,
  { entities, page, totalPageCount }
) =>
  setItemsByColumn(colName, state, {
    page,
    totalPageCount,
    entities: [...state.columns[colName].items].concat(entities)
  });

export function createMethods(state) {
  return {
    reset(initialState) {
      return initialState;
    },
    replace(newState) {
      return newState;
    },
    setAssignedUsersLoading(loading) {
      return toggleLoadingByColumn(ASSIGNED, state, loading);
    },
    setAssignedUsers(payload) {
      return setItemsByColumn(ASSIGNED, state, payload);
    },
    pushAssignedUsers(payload) {
      return pushItemsByColumn(ASSIGNED, state, payload);
    },
    setUnassignedUsersLoading(loading) {
      return toggleLoadingByColumn(UNASSIGNED, state, loading);
    },
    setUnassignedUsers(payload) {
      return setItemsByColumn(UNASSIGNED, state, payload);
    },
    pushUnassignedUsers(payload) {
      return pushItemsByColumn(UNASSIGNED, state, payload);
    }
  };
}

export const filterUsersBySearchText = (searchText, values) =>
  filter(
    ({ email, firstName, lastName }) =>
      `${firstName}${lastName}${email}`
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) !== -1
  )(values);
