import { ACTIVE, DISABLED } from './values';

export const options = [
  {
    value: ACTIVE,
    label: 'Active'
  },
  {
    value: DISABLED,
    label: 'Disabled'
  }
];
