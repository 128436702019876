import { ButtonProps } from 'antd';
import {
  LinkProps as IRouterLinkProps,
  Link as RouterLink
} from 'react-router-dom';

import cn from './styles.less';

export type ILinkProps = IRouterLinkProps;
export type ILinkButtonProps = ILinkProps & Pick<ButtonProps, 'type'>;

export const Link = (props: ILinkProps) => (
  <RouterLink className={cn.link} {...props} />
);
