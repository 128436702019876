// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qsB_CsYu22XT2PX5hyZz .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.qsB_CsYu22XT2PX5hyZz .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n  margin-right: 1.5rem;\n}\n.qsB_CsYu22XT2PX5hyZz .xWU7EkgGWPp5hIDDMrJw {\n  width: 400px;\n}\n.qsB_CsYu22XT2PX5hyZz .YkofQ5Xj6tbljIaAYtga {\n  display: inline-block;\n  vertical-align: bottom;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GroupsOverview/SearchForm/style.less"],"names":[],"mappings":"AAAA;EAGM,4BAAA;AADN;AAFA;EAOM,qBAAA;EACA,gBAAA;EACA,oBAAA;AAFN;AAPA;EAcI,YAAA;AAJJ;AAVA;EAkBI,qBAAA;EACA,sBAAA;AALJ","sourcesContent":[".form {\n  :global {\n    .antd-4-21-7-form-item-label {\n      padding-bottom: 0 !important;\n    }\n\n    .antd-4-21-7-form-item {\n      display: inline-block;\n      margin-bottom: 0;\n      margin-right: 1.5rem;\n    }\n  }\n\n  .name {\n    width: 400px;\n  }\n\n  .submit {\n    display: inline-block;\n    vertical-align: bottom;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "qsB_CsYu22XT2PX5hyZz",
	"name": "xWU7EkgGWPp5hIDDMrJw",
	"submit": "YkofQ5Xj6tbljIaAYtga"
};
export default ___CSS_LOADER_EXPORT___;
