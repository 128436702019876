import { Card, Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADMIN_GROUP } from 'routes';

import {
  AntOptionProps,
  SelectControlled
} from '~/components/Form/SelectControlled';
import { Tag } from '~/components/Form/Tag';

interface Props {
  options: Array<AntOptionProps>;
  loading: boolean;
}

export function AssignGroups({ options, loading }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { language } = useParams();

  return (
    <Card title={t('bo.admin.usersForm.assignGroups.title')} loading={loading}>
      <Row gutter={24}>
        <Col sm={24}>
          <SelectControlled
            required
            allowClear
            allowSelectAll
            options={options}
            mode="multiple"
            qaSelector="user-groupIds"
            tagRender={(props) =>
              Tag({
                ...props,
                url: ADMIN_GROUP.LINK({ language, id: props.value })
              })
            }
            controllerProps={{
              control,
              name: 'groupIds'
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
