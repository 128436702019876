import { formatDateTime } from 'helpers/date';
import getCountryAccessLabel from 'helpers/fieldOptions/admin/users/countryAccess/getLabel';
import { formatUser } from 'helpers/user';
import { filter, flow, get, join, map } from 'lodash/fp';
import { useMemo } from 'react';

import { RetailUserDto } from '~/apollo/gql-types';
import { AUX_USER_ACCESS_PARAM } from '~/constants/auxUser/accessParams';

export const useFormattedOverviewData = (
  entities: Array<Partial<RetailUserDto>>
) =>
  useMemo(
    () =>
      map((entity: Partial<RetailUserDto>) => ({
        ...entity,
        countryAccess: flow(
          filter({ key: AUX_USER_ACCESS_PARAM.COUNTRY }),
          map(get(['value'])),
          map(getCountryAccessLabel),
          join(', ')
        )(entity.accessParameters),
        name: formatUser(entity),
        loginTime: entity.loginTime && formatDateTime(entity.loginTime),
        createdOn: formatDateTime(entity.createdOn)
      }))(entities),
    [entities]
  );
