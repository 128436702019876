import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_USER } from 'routes';
import { UserProjectionModel } from 'types/UserProjectionModel';

import {
  useAssignTestDataAccessParamMutation,
  useCreateUserMutation,
  usePostCreateUserMutation
} from '~/apollo/gql-types';
import { USER_TEST_DATA_ACCESS } from '~/constants/auxUser/properties';
import { AUX_USER_REGISTERED_FROM_BACK_OFFICE } from '~/constants/auxUser/registeredFrom';
import { AUX_USER_TYPE_BACK_OFFICE } from '~/constants/auxUser/userType';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { formToData, USER_FORM_BASE_OPTIONS } from '../UserForm/utils';

export function useFormCreate() {
  const { language } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [assignTestDataAccessParam] = useAssignTestDataAccessParamMutation(
    USER_FORM_BASE_OPTIONS
  );
  const [createUserMutation] = useCreateUserMutation(USER_FORM_BASE_OPTIONS);
  const [postCreateUserMutation] = usePostCreateUserMutation(
    USER_FORM_BASE_OPTIONS
  );

  const { isAllowed: hasGroupAccessPerms } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ASSIGN_USER_GROUP_ACCESS_PARAMS,
    domain: DOMAINS.USER_MANAGEMENT
  });

  const create = useCallback(
    async (params: UserProjectionModel) => {
      const { [USER_TEST_DATA_ACCESS]: userTestDataEnabled, ...formValues } =
        params;
      const { user, ...rest } = formToData(
        {
          userType: AUX_USER_TYPE_BACK_OFFICE,
          registeredFrom: AUX_USER_REGISTERED_FROM_BACK_OFFICE
        },
        {},
        hasGroupAccessPerms
      )(formValues);

      const { data: resultData } = await createUserMutation({
        variables: { user }
      });

      const {
        user: { id: newUserId }
      } = resultData;

      await assignTestDataAccessParam({
        variables: { enabled: userTestDataEnabled, id: newUserId }
      });

      await postCreateUserMutation({
        variables: { ...rest, id: newUserId },
        refetchQueries: ['SearchUserData']
      });

      await notification.success({
        message: t('bo.admin.usersForm.notifications.create.success')
      });
      navigate(ADMIN_USER.LINK({ language, id: newUserId }));
    },
    [
      assignTestDataAccessParam,
      createUserMutation,
      hasGroupAccessPerms,
      language,
      navigate,
      postCreateUserMutation,
      t
    ]
  );

  return create;
}
