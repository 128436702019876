import { LinkButton } from '@retail/backoffice-urls';
import { Form, Space } from 'antd';
import { BaseSyntheticEvent, Dispatch, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADMIN_USERS } from 'routes';

import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';

import { AssignCountryAccess } from './AssignCountryAccess';
import { AssignGroups } from './AssignGroups';
import { AssignGroupsAccess } from './AssignGroupsAccess';
import { AssignTestDataAccess } from './AssignTestDataAccess';
import { UserDetails } from './UserDetails';
import { VoIP } from './VoIP';
import cn from './styles.less';
import { useGroupsOptions } from './useGroupsOptions';

interface Props {
  loading: boolean;
  footer: ReactNode;
  title: ReactNode;
  helmetTitle?: string;
  disabled?: boolean;
  onFinish: Dispatch<BaseSyntheticEvent>;
}

export function UserForm({
  title,
  helmetTitle,
  loading,
  disabled,
  footer,
  onFinish
}: Props) {
  const { t } = useTranslation();
  const { language } = useParams();
  const {
    groupOptions,
    groupAccessOptions,
    loading: optionsLoading
  } = useGroupsOptions();

  const isLoading = loading || optionsLoading;

  return (
    <Layout className={cn.pageContainer}>
      <LayoutHeader
        title={title}
        helmetTitle={helmetTitle}
        className={cn.titleContainer}
      >
        <LinkButton type="link" to={ADMIN_USERS.LINK({ language })}>
          {t('bo.admin.buttons.goToList')}
        </LinkButton>
      </LayoutHeader>
      <LayoutBody>
        <Form disabled={disabled} onFinish={onFinish}>
          <Space direction="vertical" size="large">
            <UserDetails loading={isLoading} />
            <AssignGroups options={groupOptions} loading={isLoading} />
            <AssignGroupsAccess
              options={groupAccessOptions}
              loading={isLoading}
            />
            <AssignCountryAccess loading={isLoading} />
            <AssignTestDataAccess loading={isLoading} />
            <VoIP />
            {footer}
          </Space>
        </Form>
      </LayoutBody>
    </Layout>
  );
}
