import { useCallback } from 'react';
import formatDate from 'date-fns/format';

import { downloadFile } from '~/helpers/downloadFile';

import dataToCSVBody from '../utils/dataToCSVBody';

import { useSearchUsers } from './useSearchUsers';

export const useDownloadAssignedUsers = () => {
  const { searchAssignedUsers } = useSearchUsers();

  return useCallback(
    async (groupName) => {
      const timestamp = formatDate(new Date(), 'yyyy_MM_dd');
      const { entities } = await searchAssignedUsers({});

      downloadFile(
        `Assigned Users_${groupName}_${timestamp}`,
        'csv',
        dataToCSVBody(entities, groupName)
      );
    },
    [searchAssignedUsers]
  );
};
