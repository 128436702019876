// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WppHMNvLoCBNH9diaqSx {\n  margin-top: 2px !important;\n  margin-bottom: 2px !important;\n  margin-right: 4px !important;\n  border: 1px solid #f0f0f0 !important;\n  background-color: #f5f5f5 !important;\n  font-size: 14px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Form/Tag/styles.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,6BAAA;EACA,4BAAA;EACA,oCAAA;EACA,oCAAA;EAEA,0BAAA;AAAF","sourcesContent":[".tag {\n  margin-top: 2px !important;\n  margin-bottom: 2px !important;\n  margin-right: 4px !important;\n  border: 1px solid #f0f0f0 !important;\n  background-color: #f5f5f5 !important;\n\n  font-size: 14px !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "WppHMNvLoCBNH9diaqSx"
};
export default ___CSS_LOADER_EXPORT___;
