// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lk2f7dIZrtrtp7jNE2il {\n  color: inherit !important;\n  text-align: left !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RoleItem/RoleUsers/styles.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,2BAAA;AACF","sourcesContent":[".linkButton {\n  color: inherit !important;\n  text-align: left !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkButton": "lk2f7dIZrtrtp7jNE2il"
};
export default ___CSS_LOADER_EXPORT___;
